import React, { useState } from "react";

type Props = {
  text: string;
  query: string;
  //de hele, reeds bestaande query string moet ook nog mee
  setValue: React.Dispatch<React.SetStateAction<any>>;
};

function QuickFilterBox({ text, query, setValue }: Props) {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  //eerst kijken of de query toegevoegd moet worden of niet
  //functie om toe te voegen moet nog geschreven worden
  function removeURLParameter(url: string, parameter: string) {
    if (typeof URLSearchParams !== "undefined") {
      const params = new URLSearchParams(url);
      params.delete(parameter);
    } else {
      console.log(`Your browser does not support URLSearchParams`);
    }
  }

  return (
    <label
      htmlFor={"quick-filter-option-" + text}
      className={
        "quick-filter-option " +
        (isChecked ? "quick-filter-option-checked" : "")
      }
    >
      {text}
      <input
        type="checkbox"
        name="quick-filter-option"
        id={"quick-filter-option-" + text}
        checked={isChecked}
        onChange={() => {
          setIsChecked(!isChecked);
          removeURLParameter("param1=1&param2=2&param3=3", query);
        }}
      />
    </label>
  );
}

export default QuickFilterBox;
