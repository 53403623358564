import { useEffect, useMemo, useState } from "react";
import { AllTextObject } from "../@types/language";
import Header from "../components/organisms/Header";
import { Title } from "../components/atoms/StyledComponents";
import QuickFilters from "../components/molecules/QuickFilters";
import { DashboardPages } from "../@types/configurator";
import AdminSearchBar from "../components/atoms/AdminSearchBar";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import GridDetails from "../components/molecules/GridDetails";
import { OfferteData } from "../@types/user";
import "../styles/dataGrid.css";
import { ColDef } from "ag-grid-enterprise";
import { ArrowLeft, CaretLeft } from "@phosphor-icons/react";
import { AG_GRID_LOCALE_NL } from "@ag-grid-community/locale";
import { testRowData } from "../data/dummyData";
import { getIsPaidIcon } from "../utils/dashboard";
import ReminderButton from "../components/atoms/ReminderButton";
import ReactCountryFlag from "react-country-flag";
import OffertesMenu from "../components/molecules/OffertesMenu";

type Props = { allText: AllTextObject };

function AdminOfferteScreen({ allText }: Props) {
  const [offerteData, setOfferteData] = useState<OfferteData[]>(testRowData);

  useEffect(() => {
    //TODO DAAN: get all offerte data from database here
    //Het json array dat ontvangen moet worden ziet eruit zoals het OfferteData type
  }, []);

  return (
    <>
      <Header allText={allText} />
      <main className="dashboard-page">
        <a href="/dashboard" className="dashboard-goback-arrow">
          <ArrowLeft color="var(--primary-700)" size={32} weight="bold" />
        </a>
        <Title color="var(--primary-700)">Offertes</Title>
        <div className="dashboard-page-header">
          <QuickFilters type={DashboardPages.offertes} />
          {/* <AdminSearchBar placeholder="Zoek op offertenr, naam, stad,..." /> */}
        </div>
        <OffertesMenu offerteData={offerteData} />
      </main>
    </>
  );
}

export default AdminOfferteScreen;
