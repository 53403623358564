import { ProductData } from "../../@types/product";
import { SuperUser } from "../../@types/user";

//DONE
export const saveUserToDatabase = (user: SuperUser) => {
  const request =
    'newUser={"firstname":' +
    `"${user.firstname}", "lastname":"${user.lastname}", "company":"${user.company}","password":"${user.password}","email":"${user.email}","role":"${user.role}"}`;
  const cleanRequest = request.replaceAll("&", "");

  var xhr = new XMLHttpRequest();
  xhr.open("POST", "addUser.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      console.log(xhr.responseText);
    }
  };
  xhr.send(cleanRequest);
};

//TODO: moeten nog nakijken hoe we images opslaan
export const saveProductToDatabase = (product: ProductData) => {
  const request =
    'ProductData={"id":' +
    `"${product.id}", "name":"${product.name}", "description":"${product.description}","category":"${product.category}","price":"${product.price}","discountReseller1":"${product.discountReseller1}","discountReseller2":"${product.discountReseller2}","pricePerUnit":"${product.pricePerUnit}","isRecommanded":"${product.isRecommanded}","recommandedText":"${product.recommandedText}","image":"${product.image}","dimensionsDependant":"${product.dimensionsDependant}","volumeDependant":"${product.volumeDependant}"}`;
  const cleanRequest = request.replaceAll("&", "");
  console.log(cleanRequest);
  var xhr = new XMLHttpRequest();
  xhr.open("POST", "setProductData.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      console.log(xhr.responseText);
    }
  };
  xhr.send(cleanRequest);
};

//DONE
export const getProductDataFromDatabase = (): ProductData[] => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", "getProductData.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      try {
        let jsonResponse: ProductData[] = JSON.parse(xhr.responseText);
        console.log(jsonResponse);
        return jsonResponse;
      } catch (error) {
        console.log(error);
      }
    }
    return [];
  };
  xhr.send();
  return [];
};

//TODO
// export const getSuperUsersFromDatabase = (): SuperUser[] => {
//   var xhr = new XMLHttpRequest();
//   xhr.open("GET", "TODO.php", true);
//   xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

//   xhr.onreadystatechange = function () {
//     if (xhr.readyState === 4 && xhr.status === 200) {
//       try {
//         let jsonResponse: ProductData[] = JSON.parse(xhr.responseText);
//         console.log(jsonResponse);
//         return jsonResponse;
//       } catch (error) {
//         console.log(error);
//       }
//     }
//     return [];
//   };
//   xhr.send();
//   return [];
// };

//TODO: testen
export const getFilterWidthInfoFromDatabase = () => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", "filterWidthInfo.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      try {
        let jsonResponse = JSON.parse(xhr.responseText);
        console.log(jsonResponse);
        return jsonResponse;
      } catch (error) {
        console.log(error);
      }
    }
  };
  xhr.send();
};
//TODO: testen
export const getGlassThicknessesFromDatabase = () => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", "glassThiknesses.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      try {
        let jsonResponse = JSON.parse(xhr.responseText);
        console.log(jsonResponse);
        return jsonResponse;
      } catch (error) {
        console.log(error);
      }
    }
  };
  xhr.send();
};
//TODO: testen
export const getPricesFromDatabase = () => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", "prices.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      try {
        let jsonResponse = JSON.parse(xhr.responseText);
        console.log(jsonResponse);
        return jsonResponse;
      } catch (error) {
        console.log(error);
      }
    }
  };
  xhr.send();
};
//TODO: testen
export const getWidthStripsFromDatabase = () => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", "widthStrips.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      try {
        let jsonResponse = JSON.parse(xhr.responseText);
        console.log(jsonResponse);
        return jsonResponse;
      } catch (error) {
        console.log(error);
      }
    }
  };
  xhr.send();
};
