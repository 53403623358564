import React, { useState } from "react";
import { SuperUser, UserRole } from "../../@types/user";
import { Text } from "../atoms/StyledComponents";
import TextArea from "./TextArea";
import TextInput from "./TextInput";
import RadioSelectionMenu from "./RadioSelectionMenu";
import { userRoleOptions } from "../../data/user";
import PrimaryButton from "../atoms/PrimaryButton";
import SecondaryButton from "../atoms/SecondaryButton";
import { saveUserToDatabase } from "../../services/api/api";

type Props = {
  setShowAddUserForm: React.Dispatch<React.SetStateAction<boolean>>;
};

function AddUser({ setShowAddUserForm }: Props) {
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [role, setRole] = useState<UserRole>(UserRole.owner);

  function handleSaveUser(e: React.FormEvent<HTMLInputElement>) {
    e.preventDefault();
    const user: SuperUser = {
      firstname: firstname,
      lastname: lastname,
      company: company,
      password: password,
      email: email,
      role: role,
    };

    saveUserToDatabase(user);
    setShowAddUserForm(false);
  }

  return (
    <form className="add-product-modal-container">
      <Text weight={700}>Voeg nieuwe gebruiker toe</Text>
      <div className="add-product-form-container">
        <div className="add-product-column">
          <TextInput
            title="voornaam"
            value={firstname}
            setValue={setFirstname}
            column
            color="var(--neutral-100)"
          />
          <TextInput
            title="achternaam"
            value={lastname}
            setValue={setLastname}
            column
            color="var(--neutral-100)"
          />
        </div>
        <div className="add-product-column">
          <TextInput
            title="email"
            value={email}
            setValue={setEmail}
            column
            color="var(--neutral-100)"
          />
          <TextInput
            title="wachtwoord"
            value={password}
            setValue={setPassword}
            column
            color="var(--neutral-100)"
          />
        </div>
        <div className="add-product-column">
          <TextInput
            title="bedrijf"
            value={company}
            setValue={setCompany}
            column
            color="var(--neutral-100)"
          />
          <RadioSelectionMenu
            title="rol"
            value={role}
            setValue={setRole}
            options={userRoleOptions}
            column
            color="var(--neutral-100)"
          />
          <div className="add-product-buttons-container">
            <SecondaryButton title="annuleren" action={() => {}} />
            <PrimaryButton
              disabled={
                !firstname ||
                !lastname ||
                !email ||
                !company ||
                !password ||
                !role
              }
              title="opslaan"
              action={(e: React.FormEvent<HTMLInputElement>) =>
                handleSaveUser(e)
              }
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddUser;
