import React from "react";
import { ImageOption } from "../../@types/configurator";
import { DecorTexture } from "../../@types/textures";
import { ArrowsCounterClockwise } from "@phosphor-icons/react";
import { FilterLocation } from "../../@types/aquarium";

type Props = {
  name: string;
  option: ImageOption;
  value: DecorTexture | FilterLocation | null;
  setValue: React.Dispatch<React.SetStateAction<any>>;
};

function ImageSelectionButton({ name, option, value, setValue }: Props) {
  return (
    <div
      className={"imageoption-container"}
      onClick={() => setValue(option.id)}
    >
      <input
        type="radio"
        name={name}
        checked={value === option.id ? true : false}
        id={name + "-" + option.id}
        readOnly
        className="imageoption-input"
      />
      <figure className="imageoption-figure">
        <img className="imageoption-img" src={option.img} alt={option.title} />
      </figure>
      <label className="imageoption-label" htmlFor={name + "-" + option.id}>
        {option.title}
        {option.id !== DecorTexture.placeholder && (
          <ArrowsCounterClockwise size={24} weight="bold" />
        )}
      </label>
    </div>
  );
}

export default ImageSelectionButton;
