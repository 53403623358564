import React from "react";
import "../../styles/dashboard.css";

type Props = {};

function AdminMenu({}: Props) {
  const getWelcomeMessage = () => {
    const date = new Date();
    const time = date.getHours();
    if (time >= 6 && time < 12) {
      return "Goedemorgen";
    } else if (time === 12) {
      return "Goedemiddag";
    } else if (time > 12 && time < 18) {
      return "Goedenamiddag";
    } else if (time >= 18 && time <= 22) {
      return "Goedenavond";
    } else if (time > 22 || time < 6) {
      return "Ga eens slapen";
    } else {
      return "Goededag"
    }
  };

  return (
    <section className="admin-menu-container">
      <div className="admin-menu-header">
        <div>{getWelcomeMessage()} Raf</div>
        <h2>Admin Dashboard</h2>
      </div>
      <div className="admin-menu-lists-container">
        <ul className="admin-menu-list">
          <li className="admin-menu-list-item">
            <a href="/dashboard/offertes">Offertes</a>
          </li>
          <li className="admin-menu-list-item">
            <a href="/dashboard/products">Producten & voorraad</a>
            <ul className="admin-menu-list">
              <li className="admin-menu-list-item">
                <a href="">Filters</a>
              </li>
              <li className="admin-menu-list-item">
                <a href="">Texturen</a>
              </li>
              <li className="admin-menu-list-item">
                <a href="">Lichtboxen</a>
              </li>
            </ul>
          </li>
          <li className="admin-menu-list-item">
            <a href="/dashboard/offertes">Offertes</a>
          </li>
        </ul>
        <ul className="admin-menu-list">
          <li className="admin-menu-list-item">
            <a href="/dashboard/translations">Vertalingen</a>
          </li>
          <li className="admin-menu-list-item">
            <a href="/dashboard/users">Gebruikers</a>
          </li>
          <li className="admin-menu-list-item">
            <a href="/dashboard/offertes">Offertes</a>
          </li>
          <li className="admin-menu-list-item">
            <a href="/dashboard/offertes">Offertes</a>
          </li>
          <li className="admin-menu-list-item">
            <a href="/dashboard/offertes">Offertes</a>
          </li>
        </ul>
        <ul className="admin-menu-list">
          <li className="admin-menu-list-item">
            <a href="/dashboard/offertes">Offertes</a>
          </li>
          <li className="admin-menu-list-item">
            <a href="/dashboard/offertes">Langeeee titel</a>
          </li>
          <li className="admin-menu-list-item">
            <a href="/dashboard/offertes">Offertes</a>
          </li>
          <li className="admin-menu-list-item">
            <a href="/dashboard/offertes">Offertes</a>
          </li>
          <li className="admin-menu-list-item">
            <a href="/dashboard/offertes">Offertes</a>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default AdminMenu;
