import { useRef } from "react";
import AquariumDrawing, {
  AquariumChangeRef,
} from "../components/Aquariumdrawing";
import ControlCenter from "../components/organisms/ControlCenter";
import "../styles/configurator.css";
import Header from "../components/organisms/Header";
import { AllTextObject } from "../@types/language";
import { useDispatch } from "react-redux";
import { resetAquarium } from "../redux/aquariumSlice";
import TexturesModal from "../components/molecules/TexturesModal";
import FilterLocationModal from "../components/molecules/FilterLocationModal";
import OverviewScreen from "./OverviewScreen";
import { checkNewAquariumDrawing } from "../redux/store";
import ProductInfoModal from "../components/molecules/ProductInfoModal";
import HelpModal from "../components/molecules/HelpModal";

type Props = { allText: AllTextObject };

function ConfiguratorScreen({ allText }: Props) {
  const aquariumChangeRef = useRef<AquariumChangeRef>(null);

  return (
    <>
      <Header allText={allText} />
      <main className="configurator-container">
        <HelpModal allText={allText} />
        <OverviewScreen allText={allText} />
        <ProductInfoModal />
        <TexturesModal allText={allText} />
        <FilterLocationModal allText={allText} />
        <AquariumDrawing
          allText={allText}
          ref={aquariumChangeRef}
          showButtons={true}
        />
        <ControlCenter allText={allText} />
      </main>
      {/* <TestControls aquarium={defaultAquarium} /> */}
    </>
  );
}

export default ConfiguratorScreen;
