import styled from "styled-components";

export const Title = styled.h1<{ color?: string }>`
  color: ${(props) => props.color || "var(--neutral-100)"};
  margin: 0;
  font-size: var(--font-lg);
  font-weight: 700;
  line-height: 1;
  padding-bottom: var(--spacing-sm);
`;

export const SubTitle = styled.h2<{ color?: string; padding?: string }>`
  color: ${(props) => props.color || "var(--neutral-100)"};
  margin: 0;
  font-size: var(--font-md);
  font-weight: 600;
  line-height: 1;
  padding-bottom: ${(props) => props.padding || 0};
`;

export const GradientSubTitle = styled.h2<{
  color?: string;
  padding?: string;
  size?: string;
}>`
  background: ${(props) => props.color || "var(--primary-gradient-500)"};
  margin: 0;
  font-size: ${(props) => props.size || "var(--font-md)"};
  font-weight: 700;
  line-height: 1.3;
  padding-bottom: ${(props) => props.padding || 0};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const StepItemTitle = styled.h3<{ color?: string; padding?: string }>`
  color: ${(props) => props.color || "var(--primary-700)"};
  margin: 0;
  font-size: var(--font-sm);
  font-weight: 700;
  line-height: 1;
  padding-bottom: ${(props) => props.padding || 0};
  inline-size: min-conten;
`;
export const StepItemExtraTitle = styled.p<{
  color?: string;
  padding?: string;
}>`
  color: ${(props) => props.color || "var(--primary-300)"};
  margin: 0;
  font-size: var(--font-xs);
  font-weight: 600;
  line-height: 1;
  padding-bottom: ${(props) => props.padding || 0};
  align-self: center;
`;

export const BigText = styled.p<{ color?: string }>`
  color: ${(props) => props.color || "var(--neutral-100)"};
  margin: 0;
  font-size: var(--font-sm);
  font-weight: 500;
`;

export const Text = styled.p<{ color?: string; weight?: number }>`
  color: ${(props) => props.color || "var(--neutral-100)"};
  margin: 0;
  font-size: var(--font-xs);
  font-weight: ${(props) => (props.weight ? props.weight : 300)};
`;

export const SmallerText = styled.p<{ color?: string; weight?: number }>`
  color: ${(props) => props.color || "var(--neutral-100)"};
  margin: 0;
  font-size: var(--font-2xs);
  font-weight: ${(props) => (props.weight ? props.weight : 300)};
`;

export const SmallText = styled.p<{ color?: string }>`
  color: ${(props) => props.color || "var(--neutral-100)"};
  margin: 0;
  font-size: var(--font-3xs);
  font-weight: 300;
`;
