import * as THREE from "three";
import { makeCylinder, makeGeometryMaterials, makePlank } from "./helper";
import { DecorTexture } from "../../@types/textures";
import { ClosetDimensions } from "../../@types/configurator";

const scale = 25;
const PI = 3.14159265359;

const closetThickness = 1.8;
const widthDistribution = [70, 131, 181, 241];
export const upperBottomPlankStripDepth = 4; //depth of front plank of the upper closet that is on the aquarium
export const outerPlankOverlap = 4; //overlap between planks and aquarium, so the planks are this much above or below the bottom or ceiling of the aquarium

const legsLowerRadius = 2;
const legsUpperRadius = 1;
const legsHeight = 1;

let currLowerClosetHeight: number;
let currUpperClosetHeight = 0;
let filterInside = false;
let seeThroughAqua = false;

let currWidth: number;
let currHeight: number;
let currDepth: number;
let currClosetHeight: number;
let currWaterproofPlateHeight = 0;
let currTexture: THREE.Texture[] = [];

export function makeMarineConformCloset(
  scene: THREE.Scene,
  width: number,
  height: number,
  closetHeight: number,
  depth: number,
  waterproofPlateHeight: number,
  closetPlanks: THREE.Mesh[][], texture: String
) {
  closetPlanks[2] = [];
  let amountComp = getAmountCompartiments(width);
  //back
  closetPlanks[2].push(
    makePlank(
      scene,
      0,
      -height / scale / 2 -
        closetHeight / scale / 2 -
        waterproofPlateHeight / scale,
      -depth / scale / 2 + closetThickness / scale / 2,
      width / scale - (2 * closetThickness) / scale,
      closetHeight / scale - (2 * closetThickness) / scale,
      closetThickness / scale,
      0x3d3d3d
    )
  );
  //left
  closetPlanks[2].push(
    makePlank(
      scene,
      -width / scale / 2 + closetThickness / scale / 2,
      -height / scale / 2 -
        closetHeight / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      closetThickness / scale,
      closetHeight / scale - (2 * closetThickness) / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //right
  closetPlanks[2].push(
    makePlank(
      scene,
      width / scale / 2 - closetThickness / scale / 2,
      -height / scale / 2 -
        closetHeight / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      closetThickness / scale,
      closetHeight / scale - (2 * closetThickness) / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //bottom
  closetPlanks[2].push(
    makePlank(
      scene,
      0,
      -height / scale / 2 -
        closetHeight / scale +
        closetThickness / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      width / scale,
      closetThickness / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //ceiling
  closetPlanks[2].push(
    makePlank(
      scene,
      0,
      -height / scale / 2 -
        closetThickness / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      width / scale,
      closetThickness / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //back outer plank
  closetPlanks[2].push(
    makePlank(
      scene,
      0,
      -height / scale / 2 -
        closetHeight / scale / 2 +
        closetThickness / scale / 2 +
        outerPlankOverlap / scale / 2 -
        waterproofPlateHeight / scale,
      -depth / scale / 2 - closetThickness / scale / 2,
      width / scale + (2 * closetThickness) / scale,
      closetHeight / scale + outerPlankOverlap / scale,
      closetThickness / scale,
      0x3d3d3d
    )
  );
  //left outer plank
  closetPlanks[2].push(
    makePlank(
      scene,
      -width / scale / 2 - closetThickness / scale / 2,
      -height / scale / 2 -
        closetHeight / scale / 2 +
        closetThickness / scale / 2 +
        outerPlankOverlap / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      closetThickness / scale,
      closetHeight / scale + outerPlankOverlap / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //right outer plank
  closetPlanks[2].push(
    makePlank(
      scene,
      width / scale / 2 + closetThickness / scale / 2,
      -height / scale / 2 -
        closetHeight / scale / 2 +
        closetThickness / scale / 2 +
        outerPlankOverlap / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      closetThickness / scale,
      closetHeight / scale + outerPlankOverlap / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  if (!seeThroughAqua) {
    scene.remove(closetPlanks[2][5]);
  }

  let compWidth = width / amountComp;
  //compartiment planks
  closetPlanks.push([]);
  for (let i = 1; i < amountComp; i++) {
    //vertical plank
    closetPlanks[3].push(
      makePlank(
        scene,
        -width / scale / 2 + (i * compWidth) / scale,
        -height / scale / 2 -
          closetHeight / scale / 2 -
          waterproofPlateHeight / scale,
        closetThickness / scale / 2,
        closetThickness / scale,
        closetHeight / scale - (2 * closetThickness) / scale,
        depth / scale - closetThickness / scale,
        0x3d3d3d
      )
    );
  }
  closetPlanks.push([]);
  for (let i = 0; i < amountComp; i++) {
    //horizontal plank
    closetPlanks[4].push(
      makePlank(
        scene,
        -width / scale / 2 + compWidth / scale / 2 + (i * compWidth) / scale,
        -height / scale / 2 -
          closetHeight / scale / 2 -
          waterproofPlateHeight / scale,
        closetThickness / scale / 2,
        compWidth / scale - closetThickness / scale,
        closetThickness / scale,
        depth / scale - closetThickness / scale,
        0x3d3d3d
      )
    );
  }
  makeFilterMarineCompartiment(scene, filterInside, closetPlanks);
  //legs
  closetPlanks.push([]);
  let legCompWidth = compWidth - (2 * legsLowerRadius) / amountComp;
  for (let i = 0; i < amountComp + 1; i++) {
    closetPlanks[5].push(
      makeCylinder(
        scene,
        -width / scale / 2 +
          legsLowerRadius / scale +
          (i * legCompWidth) / scale,
        -height / scale / 2 -
          closetHeight / scale -
          legsHeight / scale / 2 -
          waterproofPlateHeight / scale,
        depth / scale / 2 - legsLowerRadius / scale,
        legsUpperRadius / scale,
        legsHeight / scale,
        0x000000,
        legsLowerRadius / scale
      )
    );
    closetPlanks[5].push(
      makeCylinder(
        scene,
        -width / scale / 2 +
          legsLowerRadius / scale +
          (i * legCompWidth) / scale,
        -height / scale / 2 -
          closetHeight / scale -
          legsHeight / scale / 2 -
          waterproofPlateHeight / scale,
        -depth / scale / 2 + legsLowerRadius / scale,
        legsUpperRadius / scale,
        legsHeight / scale,
        0x000000,
        legsLowerRadius / scale
      )
    );
  }
  currWidth = width;
  currHeight = height;
  currLowerClosetHeight = closetHeight;
  currDepth = depth;
  currUpperClosetHeight = 0;
  currWaterproofPlateHeight = waterproofPlateHeight;
  changeMarineConformTexture(texture, closetPlanks);
}

export function getAmountCompartiments(width: number) {
  for (let i = widthDistribution.length - 1; i >= 0; i--) {
    if (width > widthDistribution[i]) {
      return i + 2;
    }
  }
  return 1;
}

export function getMarineLowerClosetHeight() {
  return currLowerClosetHeight;
}

export function changeMarineWidth(
  scene: THREE.Scene,
  newWidth: number,
  closetPlanks: THREE.Mesh[][]
) {
  closetPlanks[2][0].geometry.scale(
    (newWidth / scale - (2 * closetThickness) / scale) /
      (currWidth / scale - (2 * closetThickness) / scale),
    1,
    1
  );
  closetPlanks[2][1].position.x -= (newWidth - currWidth) / scale / 2;
  closetPlanks[2][2].position.x += (newWidth - currWidth) / scale / 2;
  closetPlanks[2][3].geometry.scale(newWidth / currWidth, 1, 1);
  closetPlanks[2][4].geometry.scale(newWidth / currWidth, 1, 1);
  closetPlanks[2][5].geometry.scale(
    (newWidth / scale + (2 * closetThickness) / scale) /
      (currWidth / scale + (2 * closetThickness) / scale),
    1,
    1
  );
  closetPlanks[2][6].position.x -= (newWidth - currWidth) / scale / 2;
  closetPlanks[2][7].position.x += (newWidth - currWidth) / scale / 2;

  let amountComp = getAmountCompartiments(newWidth);
  let compWidth = newWidth / amountComp;
  for (let i = 0; i < getAmountCompartiments(currWidth) - 1; i++) {
    scene.remove(closetPlanks[3][i]);
  }
  for (let i = 1; i < amountComp; i++) {
    //vertical plank
    closetPlanks[3][i - 1] = makePlank(
      scene,
      -newWidth / scale / 2 + (i * compWidth) / scale,
      -currHeight / scale / 2 -
        currLowerClosetHeight / scale / 2 -
        currWaterproofPlateHeight / scale,
      closetThickness / scale / 2,
      closetThickness / scale,
      currLowerClosetHeight / scale - (2 * closetThickness) / scale,
      currDepth / scale - closetThickness / scale,
      0x3d3d3d
    );
  }

  for (let i = 0; i < getAmountCompartiments(currWidth); i++) {
    scene.remove(closetPlanks[4][i]);
  }
  for (let i = 0; i < amountComp; i++) {
    //horizontal plank
    closetPlanks[4][i] = makePlank(
      scene,
      -newWidth / scale / 2 + compWidth / scale / 2 + (i * compWidth) / scale,
      -currHeight / scale / 2 -
        currLowerClosetHeight / scale / 2 -
        currWaterproofPlateHeight / scale,
      closetThickness / scale / 2,
      compWidth / scale - closetThickness / scale,
      closetThickness / scale,
      currDepth / scale - closetThickness / scale,
      0x3d3d3d
    );
  }
  makeFilterMarineCompartiment(scene, filterInside, closetPlanks);

  let legCompWidth = compWidth - (2 * legsLowerRadius) / amountComp;
  for (let i = 0; i < getAmountCompartiments(currWidth) + 1; i++) {
    scene.remove(closetPlanks[5][2 * i]);
    scene.remove(closetPlanks[5][2 * i + 1]);
  }
  for (let i = 0; i < amountComp + 1; i++) {
    closetPlanks[5][2 * i] = makeCylinder(
      scene,
      -newWidth / scale / 2 +
        legsLowerRadius / scale +
        (i * legCompWidth) / scale,
      -currHeight / scale / 2 -
        currLowerClosetHeight / scale -
        legsHeight / scale / 2 -
        currWaterproofPlateHeight / scale,
      currDepth / scale / 2 - legsLowerRadius / scale,
      legsUpperRadius / scale,
      legsHeight / scale,
      0x000000,
      legsLowerRadius / scale
    );
    closetPlanks[5][2 * i + 1] = makeCylinder(
      scene,
      -newWidth / scale / 2 +
        legsLowerRadius / scale +
        (i * legCompWidth) / scale,
      -currHeight / scale / 2 -
        currLowerClosetHeight / scale -
        legsHeight / scale / 2 -
        currWaterproofPlateHeight / scale,
      -currDepth / scale / 2 + legsLowerRadius / scale,
      legsUpperRadius / scale,
      legsHeight / scale,
      0x000000,
      legsLowerRadius / scale
    );
  }

  if (closetPlanks.length > 6 && closetPlanks[6].length > 0) {
    closetPlanks[6][0].geometry.scale(
      (newWidth / scale - (2 * closetThickness) / scale) /
        (currWidth / scale - (2 * closetThickness) / scale),
      1,
      1
    );
    closetPlanks[6][1].position.x -= (newWidth - currWidth) / scale / 2;
    closetPlanks[6][2].position.x += (newWidth - currWidth) / scale / 2;
    closetPlanks[6][3].geometry.scale(
      (newWidth / scale - (2 * closetThickness) / scale) /
        (currWidth / scale - (2 * closetThickness) / scale),
      1,
      1
    );
    closetPlanks[6][4].geometry.scale(newWidth / currWidth, 1, 1);
    closetPlanks[6][5].geometry.scale(
      (newWidth / scale + (2 * closetThickness) / scale) /
        (currWidth / scale + (2 * closetThickness) / scale),
      1,
      1
    );
    closetPlanks[6][6].position.x -= (newWidth - currWidth) / scale / 2;
    closetPlanks[6][7].position.x += (newWidth - currWidth) / scale / 2;

    let amountComp = getAmountCompartiments(newWidth);
    if (currUpperClosetHeight >= 75) {
      let compWidth = newWidth / amountComp;
      //compartiment planks
      for (let i = closetPlanks[7].length - 1; i >= 0; i--) {
        scene.remove(closetPlanks[7][i]);
        closetPlanks[7].splice(i, 1);
      }

      for (let i = 1; i < amountComp; i++) {
        //vertical plank
        closetPlanks[7].push(
          makePlank(
            scene,
            -newWidth / scale / 2 + (i * compWidth) / scale,
            currHeight / scale / 2 +
              (3 * currUpperClosetHeight) / scale / 4 -
              closetThickness / scale / 2,
            closetThickness / scale / 2,
            closetThickness / scale,
            currUpperClosetHeight / scale / 2 - (2 * closetThickness) / scale,
            currDepth / scale - closetThickness / scale,
            0x3d3d3d
          )
        );
        let lowerDoorHangPlank = makePlank(
          scene,
          -newWidth / scale / 2 + (i * compWidth) / scale,
          currHeight / scale / 2 + currUpperClosetHeight / scale / 4,
          currDepth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
          closetThickness / scale,
          currUpperClosetHeight / scale / 2 - (2 * closetThickness) / scale,
          upperBottomPlankStripDepth / scale,
          0x3d3d3d
        );
        closetPlanks[7].push(lowerDoorHangPlank);
        if (i % 2 === 1) {
          scene.remove(lowerDoorHangPlank);
        }
      }
      scene.remove(closetPlanks[8][0]);
      closetPlanks[8][0] = makePlank(
        scene,
        0,
        currHeight / scale / 2 + currUpperClosetHeight / scale / 2,
        closetThickness / scale / 2,
        newWidth / scale - (2 * closetThickness) / scale,
        closetThickness / scale,
        currDepth / scale - closetThickness / scale,
        0x3d3d3d
      );
    } else {
      let compWidth = newWidth / amountComp;
      //compartiment planks
      for (let i = closetPlanks[7].length - 1; i >= 0; i--) {
        scene.remove(closetPlanks[7][i]);
        closetPlanks[7].splice(i, 1);
      }

      for (let i = 2; i < amountComp; i += 2) {
        //vertical plank
        closetPlanks[7].push(
          makePlank(
            scene,
            -newWidth / scale / 2 + (i * compWidth) / scale,
            currHeight / scale / 2 + currUpperClosetHeight / scale / 2,
            currDepth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
            closetThickness / scale,
            currUpperClosetHeight / scale - (2 * closetThickness) / scale,
            upperBottomPlankStripDepth / scale,
            0x3d3d3d
          )
        );
      }
    }
  }
  currWidth = newWidth;
  changeMarineConformTexture("", closetPlanks);
}

export function changeMarineHeight(
  newHeight: number,
  closetPlanks: THREE.Mesh[][]
) {
  for (let i = 2; i < 6; i++) {
    for (let j = 0; j < closetPlanks[i].length; j++) {
      closetPlanks[i][j].position.y -= (newHeight - currHeight) / scale / 2;
    }
  }

  if (closetPlanks.length > 6 && closetPlanks[6].length > 0) {
    for (let i = 6; i < 9; i++) {
      if (i < 7 || currUpperClosetHeight > 75)
        for (let j = 0; j < closetPlanks[i].length; j++) {
          closetPlanks[i][j].position.y += (newHeight - currHeight) / scale / 2;
        }
    }
  }
  currHeight = newHeight;
}

export function changeMarineDepth(
  newDepth: number,
  closetPlanks: THREE.Mesh[][]
) {
  if (currDepth === newDepth) {
    return;
  }
  closetPlanks[2][0].position.z -= (newDepth - currDepth) / scale / 2;
  closetPlanks[2][1].geometry.scale(1, 1, newDepth / currDepth);
  closetPlanks[2][2].geometry.scale(1, 1, newDepth / currDepth);
  closetPlanks[2][3].geometry.scale(1, 1, newDepth / currDepth);
  closetPlanks[2][4].geometry.scale(1, 1, newDepth / currDepth);
  closetPlanks[2][5].position.z -= (newDepth - currDepth) / scale / 2;
  closetPlanks[2][6].geometry.scale(1, 1, newDepth / currDepth);
  closetPlanks[2][7].geometry.scale(1, 1, newDepth / currDepth);

  for (let i = 3; i < 5; i++) {
    for (let j = 0; j < closetPlanks[i].length; j++) {
      closetPlanks[i][j].geometry.scale(
        1,
        1,
        (newDepth / scale - closetThickness / scale) /
          (currDepth / scale - closetThickness / scale)
      );
    }
  }

  for (let i = 0; i < getAmountCompartiments(currWidth) + 1; i++) {
    closetPlanks[5][2 * i].position.z += (newDepth - currDepth) / scale / 2;
    closetPlanks[5][2 * i + 1].position.z -= (newDepth - currDepth) / scale / 2;
  }

  if (closetPlanks.length > 6 && closetPlanks[6].length > 0) {
    closetPlanks[6][0].position.z -= (newDepth - currDepth) / scale / 2;
    closetPlanks[6][1].geometry.scale(1, 1, newDepth / currDepth);
    closetPlanks[6][2].geometry.scale(1, 1, newDepth / currDepth);
    closetPlanks[6][3].position.z += (newDepth - currDepth) / scale / 2;
    closetPlanks[6][4].geometry.scale(1, 1, newDepth / currDepth);
    closetPlanks[6][5].position.z -= (newDepth - currDepth) / scale / 2;
    closetPlanks[6][6].geometry.scale(1, 1, newDepth / currDepth);
    closetPlanks[6][7].geometry.scale(1, 1, newDepth / currDepth);

    if (currUpperClosetHeight >= 75) {
      for (let j = 0; j < closetPlanks[7].length; j += 2) {
        closetPlanks[7][j].geometry.scale(
          1,
          1,
          (newDepth / scale - closetThickness / scale) /
            (currDepth / scale - closetThickness / scale)
        );
        closetPlanks[7][j + 1].position.z += (newDepth - currDepth) / scale / 2;
      }
      for (let j = 0; j < closetPlanks[8].length; j++) {
        closetPlanks[8][j].geometry.scale(
          1,
          1,
          (newDepth / scale - closetThickness / scale) /
            (currDepth / scale - closetThickness / scale)
        );
      }
    }
  }
  currDepth = newDepth;
  changeMarineConformTexture("", closetPlanks);
}

export function changeMarineLowerClosetHeight(
  newClosetHeight: number,
  closetPlanks: THREE.Mesh[][]
) {
  closetPlanks[2][0].position.y -=
    (newClosetHeight - currLowerClosetHeight) / scale / 2;
  closetPlanks[2][1].position.y -=
    (newClosetHeight - currLowerClosetHeight) / scale / 2;
  closetPlanks[2][2].position.y -=
    (newClosetHeight - currLowerClosetHeight) / scale / 2;
  closetPlanks[2][3].position.y -=
    (newClosetHeight - currLowerClosetHeight) / scale;
  closetPlanks[2][0].geometry.scale(
    1,
    (newClosetHeight / scale - (2 * closetThickness) / scale) /
      (currLowerClosetHeight / scale - (2 * closetThickness) / scale),
    1
  );
  closetPlanks[2][1].geometry.scale(
    1,
    (newClosetHeight / scale - (2 * closetThickness) / scale) /
      (currLowerClosetHeight / scale - (2 * closetThickness) / scale),
    1
  );
  closetPlanks[2][2].geometry.scale(
    1,
    (newClosetHeight / scale - (2 * closetThickness) / scale) /
      (currLowerClosetHeight / scale - (2 * closetThickness) / scale),
    1
  );
  closetPlanks[2][5].position.y -=
    (newClosetHeight - currLowerClosetHeight) / scale / 2;
  closetPlanks[2][6].position.y -=
    (newClosetHeight - currLowerClosetHeight) / scale / 2;
  closetPlanks[2][7].position.y -=
    (newClosetHeight - currLowerClosetHeight) / scale / 2;
  closetPlanks[2][5].geometry.scale(
    1,
    (newClosetHeight / scale + outerPlankOverlap / scale) /
      (currLowerClosetHeight / scale + outerPlankOverlap / scale),
    1
  );
  closetPlanks[2][6].geometry.scale(
    1,
    (newClosetHeight / scale + outerPlankOverlap / scale) /
      (currLowerClosetHeight / scale + outerPlankOverlap / scale),
    1
  );
  closetPlanks[2][7].geometry.scale(
    1,
    (newClosetHeight / scale + outerPlankOverlap / scale) /
      (currLowerClosetHeight / scale + outerPlankOverlap / scale),
    1
  );

  let amountComp = getAmountCompartiments(currWidth);
  for (let i = 0; i < amountComp - 1; i++) {
    closetPlanks[3][i].geometry.scale(
      1,
      (newClosetHeight / scale - (2 * closetThickness) / scale) /
        (currLowerClosetHeight / scale - (2 * closetThickness) / scale),
      1
    );
    closetPlanks[3][i].position.y -=
      (newClosetHeight - currLowerClosetHeight) / scale / 2;
  }

  for (let i = 0; i < amountComp; i++) {
    closetPlanks[4][i].position.y -=
      (newClosetHeight - currLowerClosetHeight) / scale / 2;
  }

  for (let i = 0; i < amountComp + 1; i++) {
    closetPlanks[5][2 * i].position.y -=
      (newClosetHeight - currLowerClosetHeight) / scale;
    closetPlanks[5][2 * i + 1].position.y -=
      (newClosetHeight - currLowerClosetHeight) / scale;
  }

  currLowerClosetHeight = newClosetHeight;
  changeMarineConformTexture("", closetPlanks);
}

export function changeMarineUpperClosetHeight(
  scene: THREE.Scene,
  newClosetHeight: number,
  closetPlanks: THREE.Mesh[][]
) {
  if (newClosetHeight === 0 && closetPlanks.length > 6) {
    for (let j = 6; j < 9; j++) {
      for (let i = closetPlanks[j].length - 1; i >= 0; i--) {
        scene.remove(closetPlanks[j][i]);
        closetPlanks[j].splice(i, 1);
      }
    }
    currUpperClosetHeight = newClosetHeight;
    return;
  }

  if (currUpperClosetHeight === 0 || closetPlanks.length < 6) {
    if (newClosetHeight > 0)
      makeUpperMarineCloset(
        scene,
        currWidth,
        currHeight,
        newClosetHeight,
        currDepth,
        closetPlanks
      );
    currUpperClosetHeight = newClosetHeight;
    changeMarineConformTexture("", closetPlanks);
    return;
  }
  closetPlanks[6][0].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale / 2;
  closetPlanks[6][1].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale / 2;
  closetPlanks[6][2].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale / 2;
  closetPlanks[6][4].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale;
  closetPlanks[6][0].geometry.scale(
    1,
    (newClosetHeight / scale - (2 * closetThickness) / scale) /
      (currUpperClosetHeight / scale - (2 * closetThickness) / scale),
    1
  );
  closetPlanks[6][1].geometry.scale(
    1,
    (newClosetHeight / scale - (2 * closetThickness) / scale) /
      (currUpperClosetHeight / scale - (2 * closetThickness) / scale),
    1
  );
  closetPlanks[6][2].geometry.scale(
    1,
    (newClosetHeight / scale - (2 * closetThickness) / scale) /
      (currUpperClosetHeight / scale - (2 * closetThickness) / scale),
    1
  );
  closetPlanks[6][5].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale / 2;
  closetPlanks[6][6].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale / 2;
  closetPlanks[6][7].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale / 2;
  closetPlanks[6][5].geometry.scale(
    1,
    (newClosetHeight / scale + outerPlankOverlap / scale) /
      (currUpperClosetHeight / scale + outerPlankOverlap / scale),
    1
  );
  closetPlanks[6][6].geometry.scale(
    1,
    (newClosetHeight / scale + outerPlankOverlap / scale) /
      (currUpperClosetHeight / scale + outerPlankOverlap / scale),
    1
  );
  closetPlanks[6][7].geometry.scale(
    1,
    (newClosetHeight / scale + outerPlankOverlap / scale) /
      (currUpperClosetHeight / scale + outerPlankOverlap / scale),
    1
  );

  let amountComp = getAmountCompartiments(currWidth);

  if (closetPlanks[7].length < amountComp && newClosetHeight >= 75) {
    let compWidth = currWidth / amountComp;

    for (let i = closetPlanks[7].length - 1; i >= 0; i--) {
      scene.remove(closetPlanks[7][i]);
      closetPlanks[7].splice(i, 1);
    }

    //compartiment planks
    for (let i = 1; i < amountComp; i++) {
      //vertical plank
      closetPlanks[7].push(
        makePlank(
          scene,
          -currWidth / scale / 2 + (i * compWidth) / scale,
          currHeight / scale / 2 +
            (3 * newClosetHeight) / scale / 4 -
            closetThickness / scale / 2,
          closetThickness / scale / 2,
          closetThickness / scale,
          newClosetHeight / scale / 2 - (2 * closetThickness) / scale,
          currDepth / scale - closetThickness / scale,
          0x3d3d3d
        )
      );
      let lowerDoorHangPlank = makePlank(
        scene,
        -currWidth / scale / 2 + (i * compWidth) / scale,
        currHeight / scale / 2 + newClosetHeight / scale / 4,
        currDepth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
        closetThickness / scale,
        newClosetHeight / scale / 2 - (2 * closetThickness) / scale,
        upperBottomPlankStripDepth / scale,
        0x3d3d3d
      );
      closetPlanks[7].push(lowerDoorHangPlank);
      if (i % 2 === 1) {
        scene.remove(lowerDoorHangPlank);
      }
    }
    if (closetPlanks[8].length === 0)
      closetPlanks[8][0] = makePlank(
        scene,
        0,
        currHeight / scale / 2 + newClosetHeight / scale / 2,
        closetThickness / scale / 2,
        currWidth / scale - (2 * closetThickness) / scale,
        closetThickness / scale,
        currDepth / scale - closetThickness / scale,
        0x3d3d3d
      );
    else
      closetPlanks[8][0].position.y +=
        (newClosetHeight - currUpperClosetHeight) / scale / 2;
  } else if (newClosetHeight >= 75) {
    for (let i = 0; i < amountComp - 1; i++) {
      closetPlanks[7][2 * i].geometry.scale(
        1,
        (newClosetHeight / scale / 2 - (2 * closetThickness) / scale) /
          (currUpperClosetHeight / scale / 2 - (2 * closetThickness) / scale),
        1
      );
      closetPlanks[7][2 * i].position.y +=
        (newClosetHeight - currUpperClosetHeight) / scale / 2;
      closetPlanks[7][2 * i + 1].geometry.scale(
        1,
        (newClosetHeight / scale / 2 - (2 * closetThickness) / scale) /
          (currUpperClosetHeight / scale / 2 - (2 * closetThickness) / scale),
        1
      );
      closetPlanks[7][2 * i + 1].position.y +=
        (newClosetHeight - currUpperClosetHeight) / scale / 4;
    }
    closetPlanks[8][0].position.y +=
      (newClosetHeight - currUpperClosetHeight) / scale / 2;
  } else {
    for (let i = closetPlanks[7].length - 1; i >= 0; i--) {
      scene.remove(closetPlanks[7][i]);
      closetPlanks[7].splice(i, 1);
    }

    let compWidth = currWidth / amountComp;
    for (let i = 2; i < amountComp; i += 2) {
      //vertical plank
      closetPlanks[7].push(
        makePlank(
          scene,
          -currWidth / scale / 2 + (i * compWidth) / scale,
          currHeight / scale / 2 + newClosetHeight / scale / 2,
          currDepth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
          closetThickness / scale,
          newClosetHeight / scale - (2 * closetThickness) / scale,
          upperBottomPlankStripDepth / scale,
          0x3d3d3d
        )
      );
    }
    if (closetPlanks[8].length > 0) {
      scene.remove(closetPlanks[8][0]);
      closetPlanks[8].splice(0, 1);
    }
  }
  currUpperClosetHeight = newClosetHeight;
  changeMarineConformTexture("", closetPlanks);
}

export function makeUpperMarineCloset(
  scene: THREE.Scene,
  width: number,
  height: number,
  closetHeight: number,
  depth: number,
  closetPlanks: THREE.Mesh[][]
) {
  closetPlanks[6] = [];
  let amountComp = getAmountCompartiments(width);
  //back
  closetPlanks[6].push(
    makePlank(
      scene,
      0,
      height / scale / 2 +
        closetHeight / scale / 2 -
        closetThickness / scale / 2,
      -depth / scale / 2 + closetThickness / scale / 2,
      width / scale - (2 * closetThickness) / scale,
      closetHeight / scale - closetThickness / scale,
      closetThickness / scale,
      0x3d3d3d
    )
  );
  //left
  closetPlanks[6].push(
    makePlank(
      scene,
      -width / scale / 2 + closetThickness / scale / 2,
      height / scale / 2 +
        closetHeight / scale / 2 -
        closetThickness / scale / 2,
      0,
      closetThickness / scale,
      closetHeight / scale - closetThickness / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //right
  closetPlanks[6].push(
    makePlank(
      scene,
      width / scale / 2 - closetThickness / scale / 2,
      height / scale / 2 +
        closetHeight / scale / 2 -
        closetThickness / scale / 2,
      0,
      closetThickness / scale,
      closetHeight / scale - closetThickness / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //bottomstrip in front
  closetPlanks[6].push(
    makePlank(
      scene,
      0,
      height / scale / 2 + closetThickness / scale / 2,
      depth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
      width / scale - (2 * closetThickness) / scale,
      closetThickness / scale,
      upperBottomPlankStripDepth / scale,
      0x3d3d3d
    )
  );
  //ceiling
  closetPlanks[6].push(
    makePlank(
      scene,
      0,
      height / scale / 2 + closetHeight / scale - closetThickness / scale / 2,
      0,
      width / scale,
      closetThickness / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //back outer plank
  closetPlanks[6].push(
    makePlank(
      scene,
      0,
      height / scale / 2 +
        closetHeight / scale / 2 -
        closetThickness / scale / 2 -
        outerPlankOverlap / scale / 2,
      -depth / scale / 2 - closetThickness / scale / 2,
      width / scale + (2 * closetThickness) / scale,
      closetHeight / scale + outerPlankOverlap / scale,
      closetThickness / scale,
      0x3d3d3d
    )
  );
  //left outer plank
  closetPlanks[6].push(
    makePlank(
      scene,
      -width / scale / 2 - closetThickness / scale / 2,
      height / scale / 2 +
        closetHeight / scale / 2 -
        closetThickness / scale / 2 -
        outerPlankOverlap / scale / 2,
      0,
      closetThickness / scale,
      closetHeight / scale + outerPlankOverlap / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //right outer plank
  closetPlanks[6].push(
    makePlank(
      scene,
      width / scale / 2 + closetThickness / scale / 2,
      height / scale / 2 +
        closetHeight / scale / 2 -
        closetThickness / scale / 2 -
        outerPlankOverlap / scale / 2,
      0,
      closetThickness / scale,
      closetHeight / scale + outerPlankOverlap / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  if (!seeThroughAqua) {
    scene.remove(closetPlanks[6][5]);
  }

  closetPlanks.push([]);
  closetPlanks.push([]);

  if (closetHeight >= 75) {
    let compWidth = width / amountComp;
    //compartiment planks
    for (let i = 1; i < amountComp; i++) {
      //vertical plank
      closetPlanks[7].push(
        makePlank(
          scene,
          -width / scale / 2 + (i * compWidth) / scale,
          height / scale / 2 +
            (3 * closetHeight) / scale / 4 -
            closetThickness / scale / 2,
          closetThickness / scale / 2,
          closetThickness / scale,
          closetHeight / scale / 2 - (2 * closetThickness) / scale,
          depth / scale - closetThickness / scale,
          0x3d3d3d
        )
      );
      let lowerDoorHangPlank = makePlank(
        scene,
        -width / scale / 2 + (i * compWidth) / scale,
        height / scale / 2 + closetHeight / scale / 4,
        depth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
        closetThickness / scale,
        closetHeight / scale / 2 - (2 * closetThickness) / scale,
        upperBottomPlankStripDepth / scale,
        0x3d3d3d
      );
      closetPlanks[7].push(lowerDoorHangPlank);
      if (i % 2 === 1) {
        scene.remove(lowerDoorHangPlank);
      }
    }
    closetPlanks[8].push(
      makePlank(
        scene,
        0,
        height / scale / 2 + closetHeight / scale / 2,
        closetThickness / scale / 2,
        width / scale - (2 * closetThickness) / scale,
        closetThickness / scale,
        depth / scale - closetThickness / scale,
        0x3d3d3d
      )
    );
  } else {
    let compWidth = width / amountComp;
    //compartiment planks
    for (let i = 2; i < amountComp; i += 2) {
      //vertical plank
      closetPlanks[7].push(
        makePlank(
          scene,
          -width / scale / 2 + (i * compWidth) / scale,
          height / scale / 2 + closetHeight / scale / 2,
          currDepth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
          closetThickness / scale,
          closetHeight / scale - (2 * closetThickness) / scale,
          upperBottomPlankStripDepth / scale,
          0x3d3d3d
        )
      );
    }
  }
  currWidth = width;
  currHeight = height;
  currUpperClosetHeight = closetHeight;
  currDepth = depth;
  changeMarineConformTexture("", closetPlanks);
}

//removes the 3 planks that make up the 2 left compartiments of the lower closet
export function makeFilterMarineCompartiment(
  scene: THREE.Scene,
  filter: boolean,
  closetPlanks: THREE.Mesh[][]
) {
  let amountComp = getAmountCompartiments(currWidth);
  if (amountComp < 3) {
    return false;
  }
  if (filter) {
    scene.remove(closetPlanks[3][0]);
    scene.remove(closetPlanks[4][0]);
    scene.remove(closetPlanks[4][1]);
  } else {
    scene.add(closetPlanks[3][0]);
    scene.add(closetPlanks[4][0]);
    scene.add(closetPlanks[4][1]);
  }
  filterInside = filter;
  return true;
}

export function makeMarineAquariumSeethrough(
  scene: THREE.Scene,
  seeThrough: boolean,
  closetPlanks: THREE.Mesh[][]
) {
  if (seeThrough) {
    scene.add(closetPlanks[2][5]);
    if (currUpperClosetHeight > 0) {
      scene.add(closetPlanks[6][5]);
    }
  } else {
    scene.remove(closetPlanks[2][5]);
    if (currUpperClosetHeight > 0) {
      scene.remove(closetPlanks[6][5]);
    }
  }
  seeThroughAqua = seeThrough;
}

export function marineConformChangeWaterProofPlateHeight(
  newWaterProofPlateHeight: number,
  closetPlanks: THREE.Mesh[][]
) {
  for (let j = 2; j <= 5; j++) {
    for (let i = 0; i < closetPlanks[j].length; i++) {
      closetPlanks[j][i].position.y +=
        (currWaterproofPlateHeight - newWaterProofPlateHeight) / scale;
    }
  }

  currWaterproofPlateHeight = newWaterProofPlateHeight;
}

export function changeMarineConformTexture(
  texture: String,
  closetPlanks: THREE.Mesh[][]
) {
  if (texture !== "")
    currTexture = [
      new THREE.TextureLoader().load(String(texture)),
      new THREE.TextureLoader().load(String(texture)),
      new THREE.TextureLoader().load(String(texture)),
      new THREE.TextureLoader().load(String(texture)),
    ];

  if (currTexture.length === 4) {
    closetPlanks[2][5].material = makeGeometryMaterials(
      currTexture[0],
      currWidth / scale + (2 * closetThickness) / scale,
      closetThickness / scale,
      currLowerClosetHeight / scale + outerPlankOverlap / scale
    );
    closetPlanks[2][6].material = makeGeometryMaterials(
      currTexture[1],
      currDepth / scale,
      closetThickness / scale,
      currLowerClosetHeight / scale + outerPlankOverlap / scale
    );
    closetPlanks[2][7].material = makeGeometryMaterials(
      currTexture[1],
      currDepth / scale,
      closetThickness / scale,
      currLowerClosetHeight / scale + outerPlankOverlap / scale
    );
    if (currUpperClosetHeight > 0) {
      closetPlanks[6][5].material = makeGeometryMaterials(
        currTexture[2],
        currWidth / scale + (2 * closetThickness) / scale,
        closetThickness / scale,
        currUpperClosetHeight / scale + outerPlankOverlap / scale
      );
      closetPlanks[6][6].material = makeGeometryMaterials(
        currTexture[3],
        currDepth / scale,
        closetThickness / scale,
        currUpperClosetHeight / scale + outerPlankOverlap / scale
      );
      closetPlanks[6][7].material = makeGeometryMaterials(
        currTexture[3],
        currDepth / scale,
        closetThickness / scale,
        currUpperClosetHeight / scale + outerPlankOverlap / scale
      );
    }
  }
}

export function totalMarineDimensions(): ClosetDimensions {
  return {
    width: currWidth + 2 * closetThickness,
    depth: currDepth + 2 * closetThickness,
    height: currLowerClosetHeight,
  };
}
