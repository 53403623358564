import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { makePlank } from "./helper";
import rimlessThickness from "./jsonFiles/rimless.json";
import lightcoverThickness from "./jsonFiles/lightcover.json";
import fullglassThickness from "./jsonFiles/fullglass.json";
import turtleThickness from "./jsonFiles/turtle.json";
import {
  changeTankDepth,
  changeTankHeight,
  changeTankWidth,
  rotateDoors,
} from "./tank";
import { defaultAquarium } from "../../utils/defaultAquarium";
import { AquariumDimensions } from "../../@types/aquarium";
import { turnDoors } from "./doors";

const glaswidth = 0.05;
const scale = 25;
const PI = 3.14159265359;

let Scene: THREE.Scene;

let currWidth: number = defaultAquarium.aquariumDimensions.width;
let currHeight: number = defaultAquarium.aquariumDimensions.height;
let currDepth: number = defaultAquarium.aquariumDimensions.depth;

//draws the lights, 1 in the middle and another on a random place, also makes ambient light
export function makeLights(scene: THREE.Scene) {
  const Alight = new THREE.AmbientLight(0xffffff, 0.4);
  const Plight1 = new THREE.PointLight(0xffffff, 1, 0, 0);
  const Plight2 = new THREE.PointLight(0xffffff, 0.5, 0, 0);
  const Plight3 = new THREE.PointLight(0xffffff, 1, 0, 0);
  const Plight4 = new THREE.PointLight(0xffffff, 1, 0, 0);
  Plight1.position.x = 3;
  Plight1.position.y = 3;
  Plight1.position.z = 4;
  Plight3.position.x = -3;
  Plight3.position.y = 3;
  Plight3.position.z = -4;
  Plight4.position.y = 3;
  Plight4.position.z = 5;
  Plight4.castShadow = true;

  scene.add(Alight);
  scene.add(Plight1);
  scene.add(Plight2);
  scene.add(Plight3);
  scene.add(Plight4);
}
let instantOpen = false;
let moveDoors = false;
let maxAngle = (PI * 80) / 180;
let currAngle = 0;
let turn = -1; // which side the door has to turn
// animate loop, if there is something that has to move like doors and also takes care of orbitcontrols and makes sure the changing of options
// of the aquarium are viewed
export function animate(
  renderer: THREE.WebGLRenderer,
  scene: THREE.Scene,
  camera: THREE.PerspectiveCamera,
  controls: any
) {
  controls.update();
  if (moveDoors) {
    if (instantOpen) {
      rotateDoors(maxAngle - currAngle);
      currAngle = maxAngle
      instantOpen = false;
    }
    rotateDoors(0.005 * turn);
    currAngle += 0.005 * turn;
    if (currAngle >= maxAngle) {
      moveDoors = false;
      rotateDoors(maxAngle - currAngle);
      currAngle = maxAngle;
    } else if (currAngle <= 0) {
      moveDoors = false;
      rotateDoors(-currAngle);
      currAngle = 0;
    }
  }

  renderer.render(scene, camera);
}

export function openCloseDoors(instantOpen: boolean) {
  instantOpen = instantOpen;
  turn = turn * -1;
  moveDoors = true;
}

//if height of aquarium changes
export function changeHeight(aquariumDimensions: AquariumDimensions) {
  // if (aquariumDimensions.height < 31 || aquariumDimensions.height > 100) {
  //   return false;
  // }
  currHeight = aquariumDimensions.height;
  return changeTankHeight(aquariumDimensions.height);
}

//if width of aquarium changes
//TODO laat gebruiker weten dat de breedte niet kleiner mag zijn dan de diepte
export function changeWidth(aquariumDimensions: AquariumDimensions) {
  // if (
  //   aquariumDimensions.width < aquariumDimensions.depth ||
  //   aquariumDimensions.width <= 40 ||
  //   aquariumDimensions.width > 300
  // ) {
  //   return false;
  // }
  currWidth = aquariumDimensions.width;
  return changeTankWidth(aquariumDimensions.width);
}

//if depth of aquarium changes
//TODO laat gebruiker weten dat de diepte niet meer mag zijn dan de breedte
export function changeDepth(aquariumDimensions: AquariumDimensions) {
  // if (
  //   aquariumDimensions.depth > aquariumDimensions.width ||
  //   aquariumDimensions.depth <= 40 ||
  //   aquariumDimensions.depth > 300
  // ) {
  //   return false;
  // }
  currDepth = aquariumDimensions.depth;
  return changeTankDepth(aquariumDimensions.depth);
}
