import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { Steps } from "../../../@types/configurator";
import { Cards } from "@phosphor-icons/react";
import ConfiguratorStep from "../../templates/ConfiguratorStep";
import StepItem from "../../templates/StepItem";
import PaintColorMenu from "../../molecules/PaintColorMenu";
import { SideInfo, WindowPaintOption } from "../../../@types/aquarium";
import {
  setAquariumSides,
  setWindowPaintColor as setWindowPaintColorRedux,
} from "../../../redux/aquariumSlice";
import { AllTextObject } from "../../../@types/language";

type Props = {
  allText: AllTextObject;
  setOpenStep: React.Dispatch<React.SetStateAction<Steps | null>>;
};

function WindowsStep({ allText, setOpenStep }: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const dispatch = useDispatch();
  const [windowPaintColor, setWindowPaintColor] = useState<WindowPaintOption>(
    aquarium.windowPaintColor
  );

  const [sideLeft, setSideLeft] = useState<SideInfo>(
    aquarium.aquariumSides.left
  );
  const [sideRight, setSideRight] = useState<SideInfo>(
    aquarium.aquariumSides.right
  );
  const [sideFront, setSideFront] = useState<SideInfo>(
    aquarium.aquariumSides.front
  );
  const [sideBack, setSideBack] = useState<SideInfo>(
    aquarium.aquariumSides.back
  );

  useEffect(() => {
    dispatch(setWindowPaintColorRedux(windowPaintColor));
  }, [windowPaintColor]);

  useEffect(() => {
    setWindowPaintColor(aquarium.windowPaintColor);
    setSideLeft(aquarium.aquariumSides.left);
    setSideRight(aquarium.aquariumSides.right);
    setSideFront(aquarium.aquariumSides.front);
    setSideBack(aquarium.aquariumSides.back);
  }, [aquarium]);

  useEffect(() => {
    dispatch(
      setAquariumSides({
        left: sideLeft,
        right: sideRight,
        front: sideFront,
        back: sideBack,
      })
    );
  }, [sideLeft, sideRight, sideFront, sideBack]);

  return (
    <ConfiguratorStep
      allText={allText}
      title={allText.tid_gen_05}
      step={3}
      stepId={Steps.windowPaint}
      symbol={
        <Cards
          className="configurator-step-header-symbol"
          size={80}
          color="var(--primary-300)"
        />
      }
      setOpenStep={setOpenStep}
    >
      <>
        <StepItem
          title={allText.tid_st3_01}
          helptext={allText.tid_st3_02}
          errortext={allText.tid_st3_03}
          hasIssue={false}
        >
          <>
            <h4 className="window-paint-menu-title">{allText.tid_st3_04}</h4>
            <PaintColorMenu
              allText={allText}
              checked={windowPaintColor}
              setValue={setWindowPaintColor}
            />
            <h4 className="window-paint-menu-title">{allText.tid_st3_05}</h4>
            <div className="window-paint-side-options-container">
              <label
                htmlFor="window-paint-side-option-left"
                className={
                  "window-paint-side-option " +
                  (sideLeft.colorPainted
                    ? "window-paint-side-option-active"
                    : "")
                }
              >
                {allText.tid_gen_17}
                <input
                  checked={sideLeft.colorPainted}
                  type="checkbox"
                  id="window-paint-side-option-left"
                  onChange={() =>
                    setSideLeft({
                      ...sideLeft,
                      colorPainted: !sideLeft.colorPainted,
                    })
                  }
                />
                <span className="checkmark"></span>
              </label>
              <label
                htmlFor="window-paint-side-option-right"
                className={
                  "window-paint-side-option " +
                  (sideRight.colorPainted
                    ? "window-paint-side-option-active"
                    : "")
                }
              >
                {allText.tid_gen_18}
                <input
                  checked={sideRight.colorPainted}
                  type="checkbox"
                  id="window-paint-side-option-right"
                  onChange={() =>
                    setSideRight({
                      ...sideRight,
                      colorPainted: !sideRight.colorPainted,
                    })
                  }
                />
                <span className="checkmark"></span>
              </label>
              <label
                htmlFor="window-paint-side-option-back"
                className={
                  "window-paint-side-option " +
                  (sideBack.colorPainted
                    ? "window-paint-side-option-active"
                    : "")
                }
              >
                {allText.tid_gen_24}
                <input
                  checked={sideBack.colorPainted}
                  type="checkbox"
                  id="window-paint-side-option-back"
                  onChange={() =>
                    setSideBack({
                      ...sideBack,
                      colorPainted: !sideBack.colorPainted,
                    })
                  }
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </>
        </StepItem>
        <StepItem
          title={allText.tid_st3_06}
          helptext={allText.tid_st3_07}
          errortext={allText.tid_st3_08}
          hasIssue={false}
        >
          <div className="window-paint-side-options-container">
            {!sideLeft.colorPainted && (
              <label
                htmlFor="window-optiwhite-side-option-left"
                className={
                  "window-paint-side-option " +
                  (sideLeft.optiWhite ? "window-paint-side-option-active" : "")
                }
              >
                {allText.tid_gen_17}
                <input
                  checked={sideLeft.optiWhite}
                  type="checkbox"
                  id="window-optiwhite-side-option-left"
                  onChange={() =>
                    setSideLeft({
                      ...sideLeft,
                      optiWhite: !sideLeft.optiWhite,
                    })
                  }
                />
                <span className="checkmark"></span>
              </label>
            )}
            {!sideRight.colorPainted && (
              <label
                htmlFor="window-optiwhite-side-option-right"
                className={
                  "window-paint-side-option " +
                  (sideRight.optiWhite ? "window-paint-side-option-active" : "")
                }
              >
                {allText.tid_gen_18}
                <input
                  checked={sideRight.optiWhite}
                  type="checkbox"
                  id="window-optiwhite-side-option-right"
                  onChange={() =>
                    setSideRight({
                      ...sideRight,
                      optiWhite: !sideRight.optiWhite,
                    })
                  }
                />
                <span className="checkmark"></span>
              </label>
            )}
            {!sideFront.colorPainted && (
              <label
                htmlFor="window-optiwhite-side-option-front"
                className={
                  "window-paint-side-option " +
                  (sideFront.optiWhite ? "window-paint-side-option-active" : "")
                }
              >
                {allText.tid_gen_25}
                <input
                  checked={sideFront.optiWhite}
                  type="checkbox"
                  id="window-optiwhite-side-option-front"
                  onChange={() =>
                    setSideFront({
                      ...sideFront,
                      optiWhite: !sideFront.optiWhite,
                    })
                  }
                />
                <span className="checkmark"></span>
              </label>
            )}
            {!sideBack.colorPainted && (
              <label
                htmlFor="window-optiwhite-side-option-back"
                className={
                  "window-paint-side-option " +
                  (sideBack.optiWhite ? "window-paint-side-option-active" : "")
                }
              >
                {allText.tid_gen_24}
                <input
                  checked={sideBack.optiWhite}
                  type="checkbox"
                  id="window-optiwhite-side-option-back"
                  onChange={() =>
                    setSideBack({
                      ...sideBack,
                      optiWhite: !sideBack.optiWhite,
                    })
                  }
                />
                <span className="checkmark"></span>
              </label>
            )}
          </div>
        </StepItem>
      </>
    </ConfiguratorStep>
  );
}

export default WindowsStep;
