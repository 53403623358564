import { InputLanguageObject } from "../@types/language";
let activeLanguage = "dutch";

function resolveField(data: any, field: string): any {
  if (data && field) {
    let fields: string[] = field.split(".");
    let value = data;
    for (let i = 0, len = fields.length; i < len; ++i) {
      if (value == null) {
        return null;
      } else {
        const pos: any = fields[i].match(/\d+/g)?.[0];
        if (pos != null) {
          const property: any = fields[i].match(/^\w+/g);
          value = value[property][pos];
        } else {
          value = value[fields[i]];
        }
      }
    }
    return value;
  } else {
    return null;
  }
}

export const getAllTextForLanguage = (allText: InputLanguageObject[], selectedLanguage?: string, ) => {
  let exportedTextList: any = {};
  if (selectedLanguage) {
    activeLanguage = selectedLanguage;
  }
  const textList = allText.map((item) => {
    const text = resolveField(item, activeLanguage);
    exportedTextList = {
      [item.id]: text,
      ...exportedTextList,
    };
  });

  return exportedTextList;
};
