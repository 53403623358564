import React from "react";
import { WaterType } from "../../@types/aquarium";
import { Text } from "../atoms/StyledComponents";
import { AllTextObject } from "../../@types/language";

type Props = {
  allText: AllTextObject;
  waterType: WaterType | null;
  setWaterType: React.Dispatch<React.SetStateAction<WaterType | null>>;
};

function BiotoopButton({ allText, waterType, setWaterType }: Props) {
  return (
    <div className="watertype-selection-container">
      <div
        className={
          "watertype-button-container " +
          (waterType === WaterType.fresh || waterType == null
            ? "watertype-button-container-active"
            : "")
        }
        onClick={() => setWaterType(WaterType.fresh)}
      >
        <input
          id="biotoop-option-freshwater"
          name="biotoop-option"
          type="radio"
          checked={waterType === WaterType.fresh}
          style={{ display: "none" }}
          readOnly
        />
        <Text
          color={
            waterType === WaterType.fresh || waterType == null
              ? "var(--neutral-900)"
              : "var(--primary-500)"
          }
        >
          {allText.tid_gen_12}
        </Text>
      </div>

      <div
        className={
          "watertype-button-container " +
          (waterType === WaterType.salty || waterType == null
            ? "watertype-button-container-active"
            : "")
        }
        onClick={() => setWaterType(WaterType.salty)}
      >
        <input
          id="biotoop-option-seawater"
          name="biotoop-option"
          type="radio"
          checked={waterType === WaterType.salty}
          style={{ display: "none" }}
          readOnly
        />
        <Text
          color={
            waterType === WaterType.salty || waterType == null
              ? "var(--neutral-900)"
              : "var(--primary-500)"
          }
        >
          {allText.tid_gen_13}
        </Text>
      </div>
    </div>
  );
}

export default BiotoopButton;
