import { Dispatch } from "react";
import { UnknownAction } from "redux";
import {
  Aquarium,
  AquariumType,
  ElectraRuimte,
  FilterOptions,
  FurnitureType,
  Side,
  WaterType,
} from "../@types/aquarium";
import {
  setAquariumSides,
  setAquariumTexture,
  setAquariumType,
  setBackWall34,
  setBetonPlex,
  setBioCoverPanels,
  setBoreholeLeft,
  setBoreholeRight,
  setCoverPanels,
  setCoverPanelsColor,
  setDoorType,
  setElectraRuimte,
  setExternalFilterDryroom,
  setExtraAquaswitch,
  setFilterLocation,
  setFullGlassCoverPanels,
  setFurnitureDimensions,
  setGrindList,
  setLightCover,
  setOsmoseCompartiment,
  setPvcPipeworkPackage,
  setRemovableSideLeft,
  setRemovableSideRight,
  setSeeThrough,
  setTechnicsAutoRefillSystem,
  setTechnicsBoosterPump,
  setTechnicsCirculationPump,
  setTechnicsCo2System,
  setTechnicsFilter,
  setTechnicsHeating,
  setTechnicsInterior,
  setTechnicsLed,
  setTechnicsProteinSkimmer,
  setTurtleIslandLocation,
  setWaterproofPlate,
} from "../redux/aquariumSlice";
import {
  changeAquariumType,
  changeCoverPanels,
  changeGrindListVisbility,
  changeLightCover,
  getAquariumWeight,
  paintSide,
  setElectraSpace,
  setRearWall,
} from "../components/aquariumComponents/tank";
import { defaultAquarium } from "./defaultAquarium";
import { ProductCategory, ProductData } from "../@types/product";
import * as THREE from "three";

export function saveAsImage(
  renderer: THREE.WebGLRenderer,
  strDownloadMime: string
) {
  var imgData, imgNode;

  try {
    var strMime = "image/jpeg";
    imgData = renderer.domElement.toDataURL("image/jpeg", 1.0);
    return imgData;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export function isInStringEnum(value: any, enumeration: any): boolean {
  return Object.values(enumeration).includes(value);
}

export const autoAdjustAquarium = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  updateAquariumTypeOptions(aquarium, dispatch);
  updateWindowOptions(aquarium, dispatch);
  updateFilterOptions(aquarium, dispatch);
  updateTopCovers(aquarium, dispatch);
  updateFurnitureOptions(aquarium, dispatch);
  updateTechnicsOptions(aquarium, dispatch);
};

const updateAquariumTypeOptions = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  switch (aquarium.aquariumType) {
    //Hier worden alle eigenschappen van een aquarium die niet relevant zijn aan het geselecteerde aquariumtype uit gezet
    case AquariumType.rimless:
      //TODO: check als grindlijst ook hier een effect op heeft
      if (
        !aquarium.grindlist &&
        (aquarium.furniture.furnitureType === null ||
          aquarium.furniture.furnitureType === FurnitureType.frameOnly)
      ) {
        dispatch(setAquariumTexture(null));
      }
      dispatch(setCoverPanels(false));
      dispatch(setFullGlassCoverPanels(null));
      dispatch(setTurtleIslandLocation(null));
      dispatch(setLightCover(false));
      break;
    case AquariumType.fullGlass:
      //TODO: check als grindlijst ook hier een effect op heeft
      if (
        !aquarium.grindlist &&
        (aquarium.furniture.furnitureType === null ||
          aquarium.furniture.furnitureType === FurnitureType.frameOnly)
      ) {
        dispatch(setAquariumTexture(null));
      }
      dispatch(setCoverPanels(false));
      dispatch(setTurtleIslandLocation(null));
      dispatch(setLightCover(false));
      break;
    case AquariumType.lightCover:
      dispatch(setTurtleIslandLocation(null));
      dispatch(setFullGlassCoverPanels(null));
      dispatch(setLightCover(false));
      break;
    case AquariumType.turtle:
      if (
        (aquarium.furniture.furnitureType === null ||
          aquarium.furniture.furnitureType === FurnitureType.frameOnly) &&
        !aquarium.lightCover &&
        !aquarium.grindlist
      ) {
        dispatch(setAquariumTexture(null));
      }
      dispatch(setFullGlassCoverPanels(null));
      break;

    default:
      break;
  }
};

const updateWindowOptions = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  const currentObj = aquarium.aquariumSides;
  let newObj = currentObj;
  if (currentObj.back.colorPainted && currentObj.back.optiWhite) {
    newObj = {
      ...newObj,
      back: {
        ...currentObj.back,
        optiWhite: false,
      },
    };
  }
  if (currentObj.left.colorPainted && currentObj.left.optiWhite) {
    newObj = {
      ...newObj,
      left: {
        ...currentObj.left,
        optiWhite: false,
      },
    };
  }
  if (currentObj.right.colorPainted && currentObj.right.optiWhite) {
    newObj = {
      ...newObj,
      right: {
        ...currentObj.right,
        optiWhite: false,
      },
    };
  }
  if (
    aquarium.aquariumSides.back.colorPainted &&
    aquarium.furniture.seeThrough
  ) {
    newObj = {
      ...newObj,
      back: {
        ...currentObj.back,
        colorPainted: false,
      },
    };
    paintSide(Side.back, false);
  }
  dispatch(setAquariumSides(newObj));
};

const updateFilterOptions = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  switch (aquarium.filter.filterType) {
    case FilterOptions.none:
      dispatch(setExternalFilterDryroom(false));
      dispatch(setFilterLocation(null));
      dispatch(setBioCoverPanels(false));
      dispatch(setOsmoseCompartiment(false));
      dispatch(setBoreholeLeft(false));
      dispatch(setBoreholeRight(false));
      dispatch(setPvcPipeworkPackage(null));
      break;
    case FilterOptions.bio:
      dispatch(setExternalFilterDryroom(false));
      dispatch(setOsmoseCompartiment(false));
      dispatch(setBoreholeLeft(false));
      dispatch(setBoreholeRight(false));
      break;
    case FilterOptions.biological:
      dispatch(setExternalFilterDryroom(false));
      dispatch(setBioCoverPanels(false));
      dispatch(setOsmoseCompartiment(false));
      dispatch(setBoreholeLeft(false));
      dispatch(setBoreholeRight(false));
      dispatch(setPvcPipeworkPackage(null));
      break;
    case FilterOptions.external:
      if (!aquarium.filter.externalDryroom) {
        dispatch(setFilterLocation(null));
      }
      dispatch(setBioCoverPanels(false));
      dispatch(setOsmoseCompartiment(false));
      dispatch(setPvcPipeworkPackage(null));
      break;
    case FilterOptions.sump:
      dispatch(setExternalFilterDryroom(false));
      dispatch(setBioCoverPanels(false));
      dispatch(setBoreholeLeft(false));
      dispatch(setBoreholeRight(false));
      break;

    default:
      break;
  }
};

const updateTopCovers = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  if (aquarium.lightCover && aquarium.furniture.dimensions.topHeight) {
    dispatch(setLightCover(false));
    dispatch(setCoverPanels(false));
    changeLightCover(false);
    changeCoverPanels(false);
  }
  if (
    aquarium.aquariumType === AquariumType.lightCover &&
    aquarium.furniture.dimensions.topHeight
  ) {
    dispatch(setAquariumType(AquariumType.fullGlass));
    dispatch(setCoverPanels(false));
    changeCoverPanels(false);
    changeAquariumType(AquariumType.fullGlass);
  }
};

const updateFurnitureOptions = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  switch (aquarium.furniture.furnitureType) {
    case FurnitureType.none:
      dispatch(setFurnitureDimensions(defaultAquarium.furniture.dimensions));
      dispatch(setBetonPlex(false));
      dispatch(setSeeThrough(false));
      dispatch(setRemovableSideLeft(false));
      dispatch(setRemovableSideRight(false));
      dispatch(setDoorType(null));
      dispatch(setExtraAquaswitch(false));
      break;
    case FurnitureType.frameOnly:
      dispatch(setSeeThrough(false));
      dispatch(setRemovableSideLeft(false));
      dispatch(setRemovableSideRight(false));
      dispatch(setDoorType(null));
      dispatch(setExtraAquaswitch(false));
      break;
    case FurnitureType.decor:
      dispatch(setDoorType(null));
      break;
    case FurnitureType.aluminium:
      break;
    case FurnitureType.marine:
      dispatch(setDoorType(null));
      dispatch(setRemovableSideLeft(false));
      dispatch(setRemovableSideRight(false));
      dispatch(setExtraAquaswitch(false));
      break;
    default:
      break;
  }

  if (
    aquarium.furniture.furnitureType === null ||
    aquarium.furniture.furnitureType === FurnitureType.none ||
    aquarium.furniture.furnitureType === FurnitureType.frameOnly
  ) {
    const toggleDoorsButton = document.getElementById("toggle-doors-button");
    if (toggleDoorsButton) toggleDoorsButton.style.display = "none";
  } else {
    const toggleDoorsButton = document.getElementById("toggle-doors-button");
    if (toggleDoorsButton) toggleDoorsButton.style.display = "flex";
  }
  if (
    aquarium.furniture.furnitureType === FurnitureType.marine ||
    aquarium.furniture.furnitureType === FurnitureType.none ||
    aquarium.furniture.furnitureType === FurnitureType.frameOnly
  ) {
    dispatch(setBackWall34(false));
    setRearWall(false);
  }

  if (
    (aquarium.aquariumDimensions.width < 100 &&
      (aquarium.filter.filterType === FilterOptions.bio ||
        aquarium.filter.filterType === FilterOptions.sump)) ||
    (aquarium.aquariumDimensions.width < 100 &&
      (aquarium.filter.filterType === FilterOptions.none ||
        aquarium.filter.filterType === null ||
        aquarium.filter.filterType === FilterOptions.external ||
        aquarium.filter.filterType === FilterOptions.biological))
  ) {
    dispatch(setElectraRuimte(null));
    setElectraSpace(ElectraRuimte.none);
  } else if (
    aquarium.furniture.electraRuimte === null ||
    aquarium.furniture.electraRuimte === ElectraRuimte.none
  ) {
    setElectraSpace(ElectraRuimte.none);
  } else {
    setElectraSpace(aquarium.furniture.electraRuimte);
  }
  if (
    aquarium.grindlist &&
    (aquarium.furniture.furnitureType === FurnitureType.aluminium ||
      aquarium.furniture.furnitureType === FurnitureType.decor ||
      aquarium.furniture.furnitureType === FurnitureType.marine)
  ) {
    dispatch(setGrindList(false));
    changeGrindListVisbility(false);
  }
  if (getAquariumWeight() >= 80) {
    dispatch(setWaterproofPlate(true));
  }
};

const updateTechnicsOptions = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  const technics = aquarium.technics;
  if (aquarium.waterType === WaterType.fresh) {
    if (
      aquarium.aquariumType === AquariumType.turtle &&
      technics.boosterPump.length > 0
    ) {
      dispatch(setTechnicsBoosterPump([]));
    } else {
      dispatch(
        setTechnicsBoosterPump(
          filterTechnicsProducts(
            technics.boosterPump,
            ProductCategory.boosterPumpsFreshwater
          )
        )
      );
    }

    if (aquarium.aquariumType === AquariumType.turtle) {
      dispatch(
        setTechnicsLed(
          filterTechnicsProducts(technics.led, ProductCategory.LEDTurtles)
        )
      );
    } else {
      dispatch(
        setTechnicsLed(
          filterTechnicsProducts(technics.led, ProductCategory.LEDFreshwater)
        )
      );
    }

    if (
      aquarium.aquariumType === AquariumType.turtle &&
      technics.heating.length > 0
    ) {
      dispatch(setTechnicsHeating([]));
    } else {
      dispatch(
        setTechnicsHeating(
          filterTechnicsProducts(
            technics.heating,
            ProductCategory.heatingFreshwater
          )
        )
      );
    }

    if (
      aquarium.aquariumType === AquariumType.turtle &&
      technics.filter.length > 0
    ) {
      dispatch(setTechnicsFilter([]));
    } else {
      dispatch(
        setTechnicsFilter(
          filterTechnicsProducts(
            technics.filter,
            ProductCategory.filtersFreshwater
          )
        )
      );
    }

    if (technics.circulationPump.length > 0) {
      dispatch(setTechnicsCirculationPump([]));
    }
    if (technics.proteinSkimmer.length > 0) {
      dispatch(setTechnicsProteinSkimmer([]));
    }
    if (technics.automaticRefillSystem.length > 0) {
      dispatch(setTechnicsAutoRefillSystem([]));
    }

    if (
      aquarium.aquariumType === AquariumType.turtle &&
      technics.co2System.length > 0
    ) {
      dispatch(setTechnicsCo2System([]));
    } else {
      dispatch(
        setTechnicsCo2System(
          filterTechnicsProducts(
            technics.co2System,
            ProductCategory.co2SystemFreshwater
          )
        )
      );
    }

    if (aquarium.aquariumType === AquariumType.turtle) {
      dispatch(
        setTechnicsInterior(
          filterTechnicsProducts(
            technics.interior,
            ProductCategory.interiorTurtles
          )
        )
      );
    } else {
      dispatch(
        setTechnicsInterior(
          filterTechnicsProducts(
            technics.interior,
            ProductCategory.interiorFreshwater
          )
        )
      );
    }
  }

  //SALT WATER PRODUCTS

  if (aquarium.waterType === WaterType.salty) {
    dispatch(
      setTechnicsBoosterPump(
        filterTechnicsProducts(
          technics.boosterPump,
          ProductCategory.boosterPumpsSaltwater
        )
      )
    );
    dispatch(
      setTechnicsLed(
        filterTechnicsProducts(technics.led, ProductCategory.LEDSaltwater)
      )
    );
    dispatch(
      setTechnicsHeating(
        filterTechnicsProducts(
          technics.heating,
          ProductCategory.heatingSaltwater
        )
      )
    );
    dispatch(
      setTechnicsCirculationPump(
        filterTechnicsProducts(
          technics.circulationPump,
          ProductCategory.circulationPumpsSaltwater
        )
      )
    );
    dispatch(
      setTechnicsProteinSkimmer(
        filterTechnicsProducts(
          technics.proteinSkimmer,
          ProductCategory.proteinSkimmersSaltwater
        )
      )
    );
    dispatch(
      setTechnicsAutoRefillSystem(
        filterTechnicsProducts(
          technics.automaticRefillSystem,
          ProductCategory.automaticRefillSystemsSaltwater
        )
      )
    );
    if (technics.co2System.length > 0) {
      dispatch(setTechnicsCo2System([]));
    }
    dispatch(
      setTechnicsInterior(
        filterTechnicsProducts(
          technics.interior,
          ProductCategory.interiorSaltwater
        )
      )
    );
  }
};

const filterTechnicsProducts = (
  productList: ProductData[],
  productCategory: ProductCategory
) => {
  return productList.filter((item) => item.category.includes(productCategory));
};

export const constructParamsURL = (aquarium: Aquarium) => {
  let paramsURL = `/configurator?length=${aquarium.aquariumDimensions.width}&width=${aquarium.aquariumDimensions.depth}&height=${aquarium.aquariumDimensions.height}`;
  //DIMENSINOS STEP
  if (aquarium.sharpenGlass !== defaultAquarium.sharpenGlass)
    paramsURL = paramsURL.concat(`&sharpenGlass=${aquarium.sharpenGlass}`);

  //AQUARIUM TYPE STEP
  if (aquarium.waterType !== defaultAquarium.waterType)
    paramsURL = paramsURL.concat(`&waterType=${aquarium.waterType}`);
  if (aquarium.aquariumType !== defaultAquarium.aquariumType)
    paramsURL = paramsURL.concat(`&aquariumType=${aquarium.aquariumType}`);
  if (aquarium.aquariumTexture !== defaultAquarium.aquariumTexture)
    paramsURL = paramsURL.concat(
      `&aquariumTexture=${aquarium.aquariumTexture}`
    );
  if (aquarium.coverPanels !== defaultAquarium.coverPanels)
    paramsURL = paramsURL.concat(`&coverPanels=${aquarium.coverPanels}`);
  if (aquarium.coverPanelsColor !== defaultAquarium.coverPanelsColor)
    paramsURL = paramsURL.concat(
      `&coverPanelsColor=${aquarium.coverPanelsColor}`
    );
  if (aquarium.fullGlassCoverPanels !== defaultAquarium.fullGlassCoverPanels)
    paramsURL = paramsURL.concat(
      `&fullGlassCoverPanels=${aquarium.fullGlassCoverPanels}`
    );
  if (aquarium.turtleIsland !== defaultAquarium.turtleIsland)
    paramsURL = paramsURL.concat(`&turtleIsland=${aquarium.turtleIsland}`);
  if (aquarium.lightCover !== defaultAquarium.lightCover)
    paramsURL = paramsURL.concat(`&lightCover=${aquarium.lightCover}`);
  if (aquarium.grindlist !== defaultAquarium.grindlist)
    paramsURL = paramsURL.concat(`&grindlist=${aquarium.grindlist}`);

  //WINDOW STEP
  if (aquarium.windowPaintColor !== defaultAquarium.windowPaintColor)
    paramsURL = paramsURL.concat(
      `&windowPaintColor=${aquarium.windowPaintColor}`
    );
  if (
    aquarium.aquariumSides.back.colorPainted !==
    defaultAquarium.aquariumSides.back.colorPainted
  )
    paramsURL = paramsURL.concat(
      `&sideBackPainted=${aquarium.aquariumSides.back.colorPainted}`
    );
  if (
    aquarium.aquariumSides.back.optiWhite !==
    defaultAquarium.aquariumSides.back.optiWhite
  )
    paramsURL = paramsURL.concat(
      `&sideBackOptiWhite=${aquarium.aquariumSides.back.optiWhite}`
    );

  if (
    aquarium.aquariumSides.front.optiWhite !==
    defaultAquarium.aquariumSides.front.optiWhite
  )
    paramsURL = paramsURL.concat(
      `&sideFrontOptiWhite=${aquarium.aquariumSides.front.optiWhite}`
    );

  if (
    aquarium.aquariumSides.left.colorPainted !==
    defaultAquarium.aquariumSides.left.colorPainted
  )
    paramsURL = paramsURL.concat(
      `&sideLeftPainted=${aquarium.aquariumSides.left.colorPainted}`
    );
  if (
    aquarium.aquariumSides.left.optiWhite !==
    defaultAquarium.aquariumSides.left.optiWhite
  )
    paramsURL = paramsURL.concat(
      `&sideLeftOptiWhite=${aquarium.aquariumSides.left.optiWhite}`
    );

  if (
    aquarium.aquariumSides.right.colorPainted !==
    defaultAquarium.aquariumSides.right.colorPainted
  )
    paramsURL = paramsURL.concat(
      `&sideRightPainted=${aquarium.aquariumSides.right.colorPainted}`
    );
  if (
    aquarium.aquariumSides.right.optiWhite !==
    defaultAquarium.aquariumSides.right.optiWhite
  )
    paramsURL = paramsURL.concat(
      `&sideRightOptiWhite=${aquarium.aquariumSides.right.optiWhite}`
    );
  if (aquarium.extra.siliconeColor !== defaultAquarium.extra.siliconeColor)
    paramsURL = paramsURL.concat(
      `&siliconeColor=${aquarium.extra.siliconeColor}`
    );

  //FILTER STEP
  if (aquarium.filter.filterType !== defaultAquarium.filter.filterType)
    paramsURL = paramsURL.concat(`&filterType=${aquarium.filter.filterType}`);
  if (
    aquarium.filter.externalDryroom !== defaultAquarium.filter.externalDryroom
  )
    paramsURL = paramsURL.concat(
      `&externalDryroom=${aquarium.filter.externalDryroom}`
    );
  if (aquarium.filter.filterLocation !== defaultAquarium.filter.filterLocation)
    paramsURL = paramsURL.concat(
      `&filterLocation=${aquarium.filter.filterLocation}`
    );
  if (aquarium.filter.bioCoverPanels !== defaultAquarium.filter.bioCoverPanels)
    paramsURL = paramsURL.concat(
      `&bioCoverPanels=${aquarium.filter.bioCoverPanels}`
    );
  if (
    aquarium.filter.osmoseCompartiment !==
    defaultAquarium.filter.osmoseCompartiment
  )
    paramsURL = paramsURL.concat(
      `&osmoseCompartiment=${aquarium.filter.osmoseCompartiment}`
    );
  if (aquarium.filter.boreholeLeft !== defaultAquarium.filter.boreholeLeft)
    paramsURL = paramsURL.concat(
      `&boreholeLeft=${aquarium.filter.boreholeLeft}`
    );
  if (aquarium.filter.boreholeRight !== defaultAquarium.filter.boreholeRight)
    paramsURL = paramsURL.concat(
      `&boreholeRight=${aquarium.filter.boreholeRight}`
    );

  //FURNITURE STEP
  if (
    aquarium.furniture.furnitureType !== defaultAquarium.furniture.furnitureType
  )
    paramsURL = paramsURL.concat(
      `&furnitureType=${aquarium.furniture.furnitureType}`
    );
  if (
    aquarium.furniture.dimensions.baseHeight !==
    defaultAquarium.furniture.dimensions.baseHeight
  )
    paramsURL = paramsURL.concat(
      `&baseHeight=${aquarium.furniture.dimensions.baseHeight}`
    );
  if (
    aquarium.furniture.dimensions.topHeight !==
    defaultAquarium.furniture.dimensions.topHeight
  )
    paramsURL = paramsURL.concat(
      `&topHeight=${aquarium.furniture.dimensions.topHeight}`
    );
  if (aquarium.furniture.betonPlex !== defaultAquarium.furniture.betonPlex)
    paramsURL = paramsURL.concat(`&betonPlex=${aquarium.furniture.betonPlex}`);
  if (aquarium.furniture.seeThrough !== defaultAquarium.furniture.seeThrough)
    paramsURL = paramsURL.concat(
      `&seeThrough=${aquarium.furniture.seeThrough}`
    );
  if (aquarium.furniture.backWall34 !== defaultAquarium.furniture.backWall34)
    paramsURL = paramsURL.concat(
      `&backWall34=${aquarium.furniture.backWall34}`
    );
  if (
    aquarium.furniture.electraRuimte !== defaultAquarium.furniture.electraRuimte
  )
    paramsURL = paramsURL.concat(
      `&electraRuimte=${aquarium.furniture.electraRuimte}`
    );
  if (
    aquarium.furniture.removableSideLeft !==
    defaultAquarium.furniture.removableSideLeft
  )
    paramsURL = paramsURL.concat(
      `&removableSideLeft=${aquarium.furniture.removableSideLeft}`
    );
  if (
    aquarium.furniture.removableSideRight !==
    defaultAquarium.furniture.removableSideRight
  )
    paramsURL = paramsURL.concat(
      `&removableSideRight=${aquarium.furniture.removableSideRight}`
    );
  if (aquarium.furniture.doorType !== defaultAquarium.furniture.doorType)
    paramsURL = paramsURL.concat(`&doorType=${aquarium.furniture.doorType}`);
  if (aquarium.furniture.aquaswitch !== defaultAquarium.furniture.aquaswitch)
    paramsURL = paramsURL.concat(
      `&aquaswitch=${aquarium.furniture.aquaswitch}`
    );
  if (aquarium.extra.waterproofPlate !== defaultAquarium.extra.waterproofPlate)
    paramsURL = paramsURL.concat(
      `&waterproofPlate=${aquarium.extra.waterproofPlate}`
    );
  if (
    aquarium.extra.pvcPipeworkPackage !==
    defaultAquarium.extra.pvcPipeworkPackage
  )
    paramsURL = paramsURL.concat(
      `&pvcPipeworkPackage=${aquarium.extra.pvcPipeworkPackage}`
    );

  // TECHNICS STEP
  if (aquarium.technics.boosterPump !== defaultAquarium.technics.boosterPump) {
    aquarium.technics.boosterPump.forEach((item) => {
      paramsURL = paramsURL.concat(`&boosterPump=${item.id}`);
    });
  }
  if (aquarium.technics.led !== defaultAquarium.technics.led) {
    aquarium.technics.led.forEach((item) => {
      paramsURL = paramsURL.concat(`&led=${item.id}`);
    });
  }
  if (aquarium.technics.heating !== defaultAquarium.technics.heating) {
    aquarium.technics.heating.forEach((item) => {
      paramsURL = paramsURL.concat(`&heating=${item.id}`);
    });
  }
  if (aquarium.technics.filter !== defaultAquarium.technics.filter) {
    aquarium.technics.filter.forEach((item) => {
      paramsURL = paramsURL.concat(`&filter=${item.id}`);
    });
  }
  if (
    aquarium.technics.circulationPump !==
    defaultAquarium.technics.circulationPump
  ) {
    aquarium.technics.circulationPump.forEach((item) => {
      paramsURL = paramsURL.concat(`&circulationPump=${item.id}`);
    });
  }
  if (
    aquarium.technics.proteinSkimmer !== defaultAquarium.technics.proteinSkimmer
  ) {
    aquarium.technics.proteinSkimmer.forEach((item) => {
      paramsURL = paramsURL.concat(`&proteinSkimmer=${item.id}`);
    });
  }
  if (
    aquarium.technics.automaticRefillSystem !==
    defaultAquarium.technics.automaticRefillSystem
  ) {
    aquarium.technics.automaticRefillSystem.forEach((item) => {
      paramsURL = paramsURL.concat(`&automaticRefillSystem=${item.id}`);
    });
  }
  if (aquarium.technics.co2System !== defaultAquarium.technics.co2System) {
    aquarium.technics.co2System.forEach((item) => {
      paramsURL = paramsURL.concat(`&co2System=${item.id}`);
    });
  }
  if (aquarium.technics.interior !== defaultAquarium.technics.interior) {
    aquarium.technics.interior.forEach((item) => {
      paramsURL = paramsURL.concat(`&interior=${item.id}`);
    });
  }

  if (aquarium.delivery !== defaultAquarium.delivery)
    paramsURL = paramsURL.concat(`&delivery=${aquarium.delivery}`);

  // Change URL
  window.history.replaceState(null, "Aqualife Configurator", paramsURL);
  return paramsURL;
};
