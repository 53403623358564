import { SuperUser, UserRole } from "../@types/user";

export const superUsers: SuperUser[] = [
  {
    firstname: "Raf",
    lastname: "Thijs",
    company: "Aqualife",
    email: "raf@aqualife.be",
    password: "jkfsjkfjsdkl",
    role: UserRole.owner,
  },
  {
    firstname: "Dries",
    lastname: "Vanmierlo",
    company: "DV Media en Fotografie",
    email: "info@dvmedia.be",
    password: "jkfsjkfjsdkl",
    role: UserRole.owner,
  },
];
