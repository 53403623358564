import React from "react";
import { DeliveryOption } from "../../@types/aquarium";
import { TextOption } from "../../@types/configurator";
import { StepItemTitle } from "../atoms/StyledComponents";

type Props = {
  title: string;
  value: DeliveryOption | null;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  options: TextOption[];
};

function OptionMenu({ title, value, setValue, options }: Props) {
  return (
    <>
      <h3 className="option-menu-title">{title}</h3>
      {options.map((option: TextOption) => (
        <label
          className="imageoption-label window-paint-side-option option-menu-label"
          htmlFor={title + "-" + option.id}
          key={"label-" + title + option.id}
        >
          {option.title}
          <input
            type="radio"
            name={title}
            checked={value === option.id ? true : false}
            id={title + "-" + option.id}
            className="overview-text-option"
            onChange={() => setValue(option.id)}
          />
          <span className="checkmark"></span>
        </label>
      ))}
    </>
  );
}

export default OptionMenu;
