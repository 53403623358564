import React from "react";
import { SubTitle, Text } from "../atoms/StyledComponents";
import "../../styles/footer.css";
import { AllTextObject } from "../../@types/language";

type Props = { allText: AllTextObject };

function Footer({ allText }: Props) {
  return (
    <footer className="footer-container">
      <div className="footer-content-container">
        <div>
          <SubTitle padding="var(--spacing-sm)">Aqualife N.V.</SubTitle>
          <Text>{allText.tid_foo_01}</Text>
        </div>
        <div>
          <SubTitle padding="var(--spacing-sm)">{allText.tid_foo_04}</SubTitle>

          <Text>{allText.tid_foo_02}</Text>
        </div>
        <div>
          <SubTitle padding="var(--spacing-sm)">{allText.tid_foo_05}</SubTitle>

          <Text>{allText.tid_foo_03}</Text>
        </div>
      </div>
      <Text style={{ textAlign: "center" }}>
        &copy; 2024{" "}
        <a
          style={{ textDecoration: "none", color: "var(--neutral-100)" }}
          href="https://www.dvmedia.be"
          target="_blank"
          rel="noreferrer"
        >
          Dries Vanmierlo
        </a>{" "}
        & Daan Ezendam
      </Text>
    </footer>
  );
}

export default Footer;
