import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../../@types/user";

const initialState: User = {
  email: null,
  agreedToConditions: false,
  loggedIn: false,
  firstname: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserFirstname: (state, action: PayloadAction<string>) => {
      state.firstname = action.payload;
    },
    setAgreedToConditions: (state, action: PayloadAction<boolean>) => {
      state.agreedToConditions = action.payload;
    },
  },
});

export const { setUserEmail, setAgreedToConditions, setUserFirstname } =
  userSlice.actions;

export default userSlice.reducer;
