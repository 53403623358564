import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const scale = 25

//makes a box with specified variables
export function makePlank(scene: THREE.Scene, x: number,y: number,z: number,width: number, height: number, depth: number, color: THREE.ColorRepresentation, opacity=1, texture?: THREE.Texture){
    const geometry = new THREE.BoxGeometry( 1, 1, 1 ); 
    let cube: THREE.Mesh
    if(typeof texture === "undefined"){
            const material = new THREE.MeshPhongMaterial( {color: color, shininess: 250, emissive:color} )
            if(opacity < 1){
                material.transparent = true;
                material.opacity = opacity;
            }
            cube = new THREE.Mesh( geometry, material );
            
    }else{
        let loadedTexture = new THREE.TextureLoader().load(String(texture))
        const material = makeGeometryMaterials(loadedTexture, width, depth, height)
        cube = new THREE.Mesh( geometry, material );
    }
    cube.castShadow = true
    cube.receiveShadow = true
    cube.geometry.scale(width, height, depth)
    cube.position.set(x,y,z);
    scene.add( cube );
    return cube;
}

export function makeGeometryMaterials(texture: THREE.Texture, width: number, depth: number, height: number): THREE.MeshPhongMaterial[]{
    //console.log(width, depth, height)
    let geometryMaterials = [
        new THREE.MeshPhongMaterial({map: textureWithCorrectSettings(texture, depth, height)}),//RIGHT SIDE
        new THREE.MeshPhongMaterial({map: textureWithCorrectSettings(texture, depth, height)}),//LEFT SIDE
        new THREE.MeshPhongMaterial({map: textureWithCorrectSettings(texture, depth, width)}),//TOP SIDE
        new THREE.MeshPhongMaterial({map: textureWithCorrectSettings(texture, depth, width)}),//BOTTOM SIDE
        new THREE.MeshPhongMaterial({map: textureWithCorrectSettings(texture, width, height)}),//FRONT SIDE
        new THREE.MeshPhongMaterial({map: textureWithCorrectSettings(texture, width, height)}),// BACK SIDE
    ];
    return geometryMaterials;
}

export function makeOpaqueGeometryMaterials(texture: THREE.Texture, width: number, depth: number, height: number, opacity: number): THREE.MeshPhongMaterial[]{
    //console.log(width, depth, height)
    let geometryMaterials = [
        new THREE.MeshPhongMaterial({map: textureWithCorrectSettings(texture, depth, height),transparent: true, opacity: opacity}),//RIGHT SIDE
        new THREE.MeshPhongMaterial({map: textureWithCorrectSettings(texture, depth, height),transparent: true, opacity: opacity}),//LEFT SIDE
        new THREE.MeshPhongMaterial({map: textureWithCorrectSettings(texture, depth, width),transparent: true, opacity: opacity}),//TOP SIDE
        new THREE.MeshPhongMaterial({map: textureWithCorrectSettings(texture, depth, width),transparent: true, opacity: opacity}),//BOTTOM SIDE
        new THREE.MeshPhongMaterial({map: textureWithCorrectSettings(texture, width, height),transparent: true, opacity: opacity}),//FRONT SIDE
        new THREE.MeshPhongMaterial({map: textureWithCorrectSettings(texture, width, height),transparent: true, opacity: opacity}),// BACK SIDE
    ];
    return geometryMaterials;
}

function textureWithCorrectSettings(texture: THREE.Texture, x: number, y: number): THREE.Texture{
    let new_texture = texture;
    new_texture.wrapS = THREE.RepeatWrapping;
    new_texture.wrapT = THREE.RepeatWrapping;
    new_texture.repeat.set(x*scale/130, y*scale/280);
    return new_texture;
}

// this function colors sides of your plank
// this works the same as make plank, but now you can give an array to color and opacity to specify for each side what the color and opacity should be
//[RIGHT, LEFT, TOP, BOTTOM, FRONT, BACK]
export function colorPlankSide(scene: THREE.Scene, x: number,y: number,z: number,width: number, height: number, depth: number, color: THREE.ColorRepresentation[], opacity=[1,1,1,1,1,1]){
    const geometry = new THREE.BoxGeometry( 1, 1, 1 ); 
    let cubeMaterials = [];
    for(let i = 0; i < 6; i++){
        let material = new THREE.MeshPhongMaterial( {color: color[i], shininess: 50, emissive:color[i]} ); 
        if(opacity[i] < 1){
            material.transparent = true;
            material.opacity = opacity[i];
        }else{
            material.side = THREE.DoubleSide;
        }
        cubeMaterials.push(material)
    }
    const cube = new THREE.Mesh( geometry, cubeMaterials ); 
    cube.geometry.scale(width, height, depth)
    cube.position.set(x,y,z);
    scene.add( cube );
    return cube;
}

//makes a rectangular plane, same as makeplank but the plank is flat
// width, height or depth has to be 0 to say what the direction of the plane is

export function makeRectangle(scene: THREE.Scene, x: number,y: number,z: number,width: number, height: number, depth: number, color: THREE.ColorRepresentation, opacity=1){
    const geometry = new THREE.PlaneGeometry( 1, 1 );
    const material = new THREE.MeshBasicMaterial( {color: color, side: THREE.DoubleSide} );
    geometry.scale(width, height, depth);
    
    const plane = new THREE.Mesh( geometry, material );
    plane.position.x = x;
    plane.position.y = y;
    plane.position.z = z;
    scene.add( plane );
    return plane;
}

export function makeCircle(scene: THREE.Scene, x: number,y: number,z: number, radius: number, color: THREE.ColorRepresentation, opacity=1){
    const geometry = new THREE.CircleGeometry( radius, 32 ); 
    const material = new THREE.MeshBasicMaterial( { color: color } ); 
    const circle = new THREE.Mesh( geometry, material ); 
    circle.position.x = x;
    circle.position.y = y;
    circle.position.z = z;
    scene.add( circle );
    return circle;
}

export function makeCylinder(scene: THREE.Scene, x: number, y: number, z: number, radius: number, height: number, color: THREE.ColorRepresentation, secondRadius = 0){
    let geometry: THREE.CylinderGeometry
    if(secondRadius === 0){
        geometry = new THREE.CylinderGeometry(radius, radius, height, 32);
    }else{
        geometry = new THREE.CylinderGeometry(radius, secondRadius, height, 32);
    }
    
    const material = new THREE.MeshPhongMaterial({color: color, shininess: 50, emissive:color})
    const cylinder = new THREE.Mesh(geometry, material);
    cylinder.position.set(x,y,z)
    scene.add(cylinder);
    return cylinder;
}