import React, { useEffect, useState } from "react";
import "../../styles/header.css";
import logo from "../../images/aqualife-logo-white.png";
import {
  ArrowLeft,
  List,
  UserCircle,
  UserCircleCheck,
} from "@phosphor-icons/react";
import LanguageSelector from "../molecules/LanguageSelector";
import { AllTextObject } from "../../@types/language";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { SubTitle } from "../atoms/StyledComponents";
import TextInput from "../molecules/TextInput";
import PrimaryButton from "../atoms/PrimaryButton";

type Props = { allText: AllTextObject };

function Header({ allText }: Props) {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [menuToggleChecked, setMenuTogggleChecked] = useState(false);
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user);
  const [showLoginScreen, setShowLoginScreen] = useState<boolean>(false);
  const [loginEmail, setLoginEmail] = useState<string | null>(null);
  const [loginPassword, setLoginPassword] = useState<string | null>(null);
  const [hidePassword, setHidePassword] = useState<boolean>(true);

  useEffect(() => {
    window.addEventListener(
      "resize",
      function (event) {
        setInnerWidth(this.window.innerWidth);
      },
      true
    );
  });

  const handleLogin = () => {
    //TODO DAAN: hier een login request plaatsen
    //Stuur enkel een json object door met email en password
    //de backend/api geeft een antwoord terug
  };

  return (
    <>
      <header
        id={location.pathname === "/configurator" ? "header-small" : "header"}
      >
        <a className="header-figure-container" href="/">
          <figure className="header-figure">
            <img className="header-img" src={logo} alt="Aqualife logo" />
          </figure>
        </a>
        {location.pathname === "/" ? (
          <ul id="header-main-menu">
            <li>
              <a href="https://www.aqualife-aquarium.com/">
                {allText.tid_hea_01}
              </a>
            </li>
            <li>
              <a href="https://www.aqualife-aquarium.com/webshop">
                {allText.tid_hea_02}
              </a>
            </li>
            <li>
              <a href="https://www.aqualife-aquarium.com/onzediensten">
                {allText.tid_hea_03}
              </a>
            </li>
            <li>
              <a href="https://www.aqualife-aquarium.com/contact">
                {allText.tid_hea_04}
              </a>
            </li>
          </ul>
        ) : (
          //TODO: dit weghalen eens het allemaal klaar is
          <p style={{ margin: "0", color: "#fff" }}>
            <b>DEMO VERSIE</b>
          </p>
        )}
        <div id="header-side-menu">
          <LanguageSelector allText={allText} size={32} />
          {user.loggedIn ? (
            <UserCircleCheck
              size={32}
              onClick={() => setShowLoginScreen(!showLoginScreen)}
              className="header-user-icon"
            />
          ) : (
            <UserCircle
              size={32}
              onClick={() => setShowLoginScreen(!showLoginScreen)}
              className="header-user-icon"
            />
          )}
          {location.pathname === "/" && setNavMenu(innerWidth)}
        </div>
      </header>
      {showLoginScreen && (
        <section className="login-screen-container">
          <div className="login-screen-content-container">
            <div className="login-screen-content-box">
              <div className="login-header">
                <ArrowLeft
                  size={32}
                  weight="bold"
                  color="var(--primary-700)"
                  className="login-header-arrow"
                  onClick={() => setShowLoginScreen(!showLoginScreen)}
                />
                <SubTitle color="var(--primary-700)">
                  {allText.tid_hea_05}
                </SubTitle>
              </div>
              <form className="login-body">
                <TextInput
                  title={allText.tid_hea_06}
                  value={loginEmail ? loginEmail : ""}
                  setValue={setLoginEmail}
                />
                <TextInput
                  title={allText.tid_hea_07}
                  value={loginPassword ? loginPassword : ""}
                  setValue={setLoginPassword}
                  hidden={hidePassword}
                />
                <label
                  htmlFor="login-show-password-button"
                  className={
                    "window-paint-side-option login-show-password-button"
                  }
                >
                  {allText.tid_hea_08}
                  <input
                    checked={!hidePassword}
                    type="checkbox"
                    id="login-show-password-button"
                    onChange={() => setHidePassword(!hidePassword)}
                  />
                  <span className="checkmark"></span>
                </label>
                <PrimaryButton
                  title={allText.tid_hea_09}
                  action={() => handleLogin()}
                  disabled={true}
                />
              </form>
            </div>
          </div>
        </section>
      )}
    </>
  );

  function closeMenu() {
    setMenuTogggleChecked(!menuToggleChecked);
  }

  function setNavMenu(width: number) {
    if (width < 1024) {
      return (
        <div className="hamburger-menu">
          <input
            id="menu__toggle"
            type="checkbox"
            checked={menuToggleChecked}
            onClick={() => setMenuTogggleChecked(!menuToggleChecked)}
          />
          <label className="menu__btn" htmlFor="menu__toggle">
            <span></span>
          </label>

          <nav className="menu__box" onClick={closeMenu}>
            <li>
              <a href="https://www.aqualife-aquarium.com/">
                {allText.tid_hea_01}
              </a>
            </li>
            <li>
              <a href="https://www.aqualife-aquarium.com/webshop">
                {allText.tid_hea_02}
              </a>
            </li>
            <li>
              <a href="https://www.aqualife-aquarium.com/onzediensten">
                {allText.tid_hea_03}
              </a>
            </li>
            <li>
              <a href="https://www.aqualife-aquarium.com/contact">
                {allText.tid_hea_04}
              </a>
            </li>
          </nav>
        </div>
      );
    }
  }
}

export default Header;
