import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import allLocalText from "../../data/text/allTexts.json";
import { getAllTextForLanguage } from "../../utils/textRegulator";
import { AllTextObject, InputLanguageObject } from "../../@types/language";

export interface LanguageState {
  activeLanguage: string;
  allText: AllTextObject;
  allTextFull: InputLanguageObject[];
}

const initialState: LanguageState = {
  activeLanguage: "dutch",
  allText: getAllTextForLanguage(allLocalText, "dutch"),
  allTextFull: allLocalText,
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setActiveLanguage: (state, action: PayloadAction<string>) => {
      state.activeLanguage = action.payload;
    },
    setAllText: (state, action: PayloadAction<AllTextObject>) => {
      state.allText = action.payload;
    },
    setAllTextFull: (
      state,
      action: PayloadAction<InputLanguageObject[]>
    ) => {
      state.allTextFull = action.payload;
    },
  },
});

export const { setActiveLanguage, setAllText, setAllTextFull } =
  languageSlice.actions;

export default languageSlice.reducer;
