import React from "react";

type Props = { number: number; title: string; subtitle: string };

function DashboardInsight({ number, title, subtitle }: Props) {
  return (
    <div className="dashboard-insight-container">
      <div className="dashboard-insight-content-horizontal">
        <div className="dashboard-insight-number">{number}</div>
        <div className="dashboard-insight-x">
          <div>X</div>
        </div>
      </div>
      <div className="dashboard-insight-content-vertical">
        <div className="dashboard-insight-title">{title}</div>
        <div className="dashboard-insight-subtitle">{subtitle}</div>
      </div>
    </div>
  );
}

export default DashboardInsight;
