import * as THREE from "three";
import { makeRectangle } from "./helper";

const scale = 25;
const PI = 3.14159265359;
let glassWidth = 0.8 / scale;

let siliconstrips: THREE.Mesh[] = []; //the black edges

let currWidth: number;
let currHeight: number;
let currDepth: number;
let siliconHeight: number;
let siliconColor = 0x000000;
let siliconTransparency = 1;

export function drawSiliconStrips(
  scene: THREE.Scene,
  width: number,
  height: number,
  depth: number,
  newGlassWidth: number
) {
  currWidth = width;
  currHeight = height;
  currDepth = depth;
  glassWidth = newGlassWidth;
  siliconHeight = height / scale - glassWidth;
  //front
  siliconstrips[0] = makeRectangle(
    scene,
    0,
    -height / (2 * scale),
    depth / (2 * scale) + 0.001,
    width / scale + 0.002,
    glassWidth,
    0,
    siliconColor,
    siliconTransparency
  );
  siliconstrips[1] = makeRectangle(
    scene,
    -width / (2 * scale) + glassWidth / 2 - 0.001,
    0,
    depth / (2 * scale) + 0.001,
    glassWidth,
    height / scale - glassWidth,
    0,
    siliconColor,
    siliconTransparency
  );
  siliconstrips[2] = makeRectangle(
    scene,
    width / (2 * scale) - glassWidth / 2 + 0.001,
    0,
    depth / (2 * scale) + 0.001,
    glassWidth,
    height / scale - glassWidth,
    0,
    siliconColor,
    siliconTransparency
  );

  //left
  siliconstrips[3] = makeRectangle(
    scene,
    -width / (scale * 2) - 0.001,
    -height / (2 * scale),
    0,
    depth/scale + 0.002,
    glassWidth,
    0,
    siliconColor,
    siliconTransparency
  );
  siliconstrips[4] = makeRectangle(
    scene,
    -width / (2 * scale) - 0.001,
    0,
    depth / (2 * scale) - glassWidth / 2 + 0.001,
    glassWidth,
    height / scale - glassWidth,
    0,
    siliconColor,
    siliconTransparency
  );
  siliconstrips[5] = makeRectangle(
    scene,
    -width / (2 * scale) - 0.001,
    0,
    -depth / (2 * scale) + glassWidth / 2 - 0.001,
    glassWidth,
    height / scale - glassWidth,
    0,
    siliconColor,
    siliconTransparency
  );

  //back
  siliconstrips[6] = makeRectangle(
    scene,
    0,
    -height / (2 * scale),
    -(depth / (2 * scale) + 0.001),
    width / scale + 0.002,
    glassWidth,
    0,
    siliconColor,
    siliconTransparency
  );
  siliconstrips[7] = makeRectangle(
    scene,
    -width / (2 * scale) + glassWidth / 2 - 0.001,
    0,
    -(depth / (2 * scale) + 0.001),
    glassWidth,
    height / scale - glassWidth,
    0,
    siliconColor,
    siliconTransparency
  );
  siliconstrips[8] = makeRectangle(
    scene,
    width / (2 * scale) - glassWidth / 2 + 0.001,
    0,
    -(depth / (2 * scale) + 0.001),
    glassWidth,
    height / scale - glassWidth,
    0,
    siliconColor,
    siliconTransparency
  );

  //right
  siliconstrips[9] = makeRectangle(
    scene,
    width / (scale * 2) + 0.001,
    -height / (2 * scale),
    0,
    depth/scale + 0.002,
    glassWidth,
    0,
    siliconColor,
    siliconTransparency
  );
  siliconstrips[10] = makeRectangle(
    scene,
    width / (2 * scale) + 0.001,
    0,
    depth / (2 * scale) - glassWidth / 2 + 0.001,
    glassWidth,
    height / scale - glassWidth,
    0,
    siliconColor,
    siliconTransparency
  );
  siliconstrips[11] = makeRectangle(
    scene,
    width / (2 * scale) + 0.001,
    0,
    -depth / (2 * scale) + glassWidth / 2 - 0.001,
    glassWidth,
    height / scale - glassWidth,
    0,
    siliconColor,
    siliconTransparency
  );

  for (let i = 0; i < 4; i++) {
    siliconstrips[i * 3].rotateY(((90 * i) / 180) * PI);
    siliconstrips[i * 3 + 1].rotateY(((90 * i) / 180) * PI);
    siliconstrips[i * 3 + 2].rotateY(((90 * i) / 180) * PI);
  }
}

export function changeSiliconWidth(width: number) {
  siliconstrips[0].geometry.scale(width / currWidth, 1, 1);
  siliconstrips[1].position.x = -width / (2 * scale) + glassWidth / 2 - 0.001;
  siliconstrips[2].position.x = width / (2 * scale) - glassWidth / 2 + 0.001;

  siliconstrips[3].position.x = -width / (scale * 2) - 0.001;
  siliconstrips[4].position.x = -width / (scale * 2) - 0.001;
  siliconstrips[5].position.x = -width / (scale * 2) - 0.001;

  siliconstrips[6].geometry.scale(width / currWidth, 1, 1);
  siliconstrips[7].position.x = -width / (2 * scale) + glassWidth / 2 - 0.001;
  siliconstrips[8].position.x = width / (2 * scale) - glassWidth / 2 + 0.001;

  siliconstrips[9].position.x = width / (scale * 2) + 0.001;
  siliconstrips[10].position.x = width / (scale * 2) + 0.001;
  siliconstrips[11].position.x = width / (scale * 2) + 0.001;

  currWidth = width;
}

export function changeSiliconHeight(height: number) {
  for (let i = 0; i < 4; i++) {
    siliconstrips[i * 3].position.y = -height / (2 * scale);
    siliconstrips[i * 3 + 1].geometry.scale(
      1,
      (height / scale - glassWidth) / (currHeight / scale - glassWidth),
      1
    );
    siliconstrips[i * 3 + 2].geometry.scale(
      1,
      (height / scale - glassWidth) / (currHeight / scale - glassWidth),
      1
    );
  }
  siliconHeight *=
    (height / scale - glassWidth) / (currHeight / scale - glassWidth);
  currHeight = height;
}

export function changeSiliconDepth(depth: number) {
  siliconstrips[0].position.z = depth / (2 * scale) + 0.001;
  siliconstrips[1].position.z = depth / (2 * scale) + 0.001;
  siliconstrips[2].position.z = depth / (2 * scale) + 0.001;

  siliconstrips[3].geometry.scale(depth / currDepth, 1, 1);
  siliconstrips[4].position.z = depth / (2 * scale) - glassWidth / 2 + 0.001;
  siliconstrips[5].position.z = -depth / (2 * scale) + glassWidth / 2 - 0.001;

  siliconstrips[6].position.z = -(depth / (2 * scale) + 0.001);
  siliconstrips[7].position.z = -(depth / (2 * scale) + 0.001);
  siliconstrips[8].position.z = -(depth / (2 * scale) + 0.001);

  siliconstrips[9].geometry.scale(depth / currDepth, 1, 1);
  siliconstrips[10].position.z = depth / (2 * scale) - glassWidth / 2 + 0.001;
  siliconstrips[11].position.z = -depth / (2 * scale) + glassWidth / 2 - 0.001;

  currDepth = depth;
}

export function changeSiliconGlassWidth(newGlassWidth: number) {
  for (let i = 0; i < 4; i++) {
    siliconstrips[i * 3].geometry.scale(1, newGlassWidth / glassWidth, 1);
    siliconstrips[i * 3 + 1].geometry.scale(
      newGlassWidth / glassWidth,
      (currHeight / scale - newGlassWidth) / (currHeight / scale - glassWidth),
      1
    );
    siliconstrips[i * 3 + 2].geometry.scale(
      newGlassWidth / glassWidth,
      (currHeight / scale - newGlassWidth) / (currHeight / scale - glassWidth),
      1
    );
  }

  siliconstrips[1].position.x =
    -currWidth / (2 * scale) + newGlassWidth / 2 - 0.001;
  siliconstrips[2].position.x =
    currWidth / (2 * scale) - newGlassWidth / 2 + 0.001;

  siliconstrips[4].position.z =
    currDepth / (2 * scale) - newGlassWidth / 2 + 0.001;
  siliconstrips[5].position.z =
    -currDepth / (2 * scale) + newGlassWidth / 2 - 0.001;

  siliconstrips[7].position.x =
    -currWidth / (2 * scale) + newGlassWidth / 2 - 0.001;
  siliconstrips[8].position.x =
    currWidth / (2 * scale) - newGlassWidth / 2 + 0.001;

  siliconstrips[10].position.z =
    currDepth / (2 * scale) - newGlassWidth / 2 + 0.001;
  siliconstrips[11].position.z =
    -currDepth / (2 * scale) + newGlassWidth / 2 - 0.001;

  glassWidth = newGlassWidth;
}

export function changeSiliconColor(scene: THREE.Scene, transparent: boolean){
  if((transparent && siliconTransparency === 0.5) || (!transparent && siliconTransparency === 1)){
    return
  }
  
  if(transparent){
    siliconColor = 0xa1c7c5
    siliconTransparency = 0.5;
  }else{
    siliconColor = 0x000000
    siliconTransparency = 1;
  }

  for(let i = 0; i < siliconstrips.length; i++){
    scene.remove(siliconstrips[i])
    /*const material = new THREE.MeshPhongMaterial( {color: siliconColor, shininess: 50, emissive:siliconColor} )
    if(siliconTransparency < 1){
        material.transparent = true;
        material.opacity = siliconTransparency;
    }
    siliconstrips[i].material = material*/
  }
  drawSiliconStrips(scene, currWidth, currHeight, currDepth, glassWidth)
}
