import * as THREE from "three";
import { colorPlankSide, makeCircle, makePlank, makeRectangle } from "./helper";
import { FilterLocation } from "../../@types/aquarium";
import filterWidth from "./jsonFiles/filterWidthInfo.json";
import { Scales } from "@phosphor-icons/react";
import { makeSideOptiWhite } from "./tank";
import { changeFilterDepth } from "./filteroptions";

const scale = 25;
const PI = 3.14159265359;
let bioFilterHeight = 45;
export const biggerFilterHeight = 45;
export const smallerFilterHeight = 35;
export const closetHeightchanger = 75; // if closetheight is smaller than this, the filter should become as big as smallerfilterHeight
export const closetHeightdisappear = 60; // if closetheight is smaller than this, the filter should disappear
let filterDepth = 43;
export const maxFilterDepth = 60; //max depth of the filter
export const filterDepthDiff = 10; // difference between depth of aquarium and filter
const filterGlassWidth = 1;
//const filterWidthDiff = 40; // this is how much smaller the filter is than the whole aquarium
//const sideCompWidth = 12; // the width of the 2 sidecompartiments
const compDist = 4; // how far the first 3 filtercompartiments are away from each other, last compartiment is against the 3rd
let heightDiff = 40; //how far down from the aquarium the filter stands
const landingWidth = 28; //overloop lengte
const landingDepth = 11; //overloop breedte
const landingHoleDiameter = 3.2; //diameter boorgaten op bodem van overloop
const landingthickness = 0.6; //glassdikte van overloop
const bottomPlanksHeightDiff = 10; //filterplanks that stand on bottom have to be 10cm under the roof of the filter
const floatingPlanksHeightDiff = 3; // filterplanks that do not stand on the bottom have to bo 3cm under the roof
const maxCoverPlanksWidth = 35;
const coverPlankIncapeWidth = 10;
const coverPlankIncapeDepth = 5;
const coverPanelThickness = 0.3;
const coverPanelHeightDiff = 2; //how much te coverpanels have to be inside the aquarium
const marineConformFilterWidth = 85; //length of a filter that is placed in a marine conform closet
const closetThickness = 1.8;
const leftSeperatorHeightDiff = 2; // how much the most left filterplank (plank with incaping) should be from the ceiling
const backVoorfilterHeightDiff = 6; // how much the plank should be from the ceiling that seperates the voorfilter from the waterentrance
const thirdCompartimentWidth = 200; // if the width is larger than this value, a thrid compartiment should appear
let widthOffset = 4; // how far the filter should be from the left side of the aquarium
const seepthroughRadius = 0.5; // radius of 1 hole in seepthrough

let currFilterLocation: FilterLocation;
let amountCoverPanels: number = 0;
let showCoverPanels = false;
let filterMarineConform = false;
let currWaterProofPlateHeight = 0;

export function makeBioFilter(
  scene: THREE.Scene,
  width: number,
  height: number,
  closetHeight: number,
  depth: number,
  waterproofPlateHeight: number,
  filterPlanks: THREE.Mesh[][]
) {
  if (filterMarineConform) {
    return;
  }
  changeBioFilterDepth(depth);
  let filterWidthDiff = getFilterWidthDiff(width);
  let sideCompWidth = getSideCompWidth(width);
  for (let i = 0; i < 13 + amountCoverPanels; i++) {
    scene.remove(filterPlanks[0][i]);
  }
  if (filterPlanks.length > 1)
    for (let i = 0; i < filterPlanks[1].length; i++) {
      scene.remove(filterPlanks[1][i]);
    }
  if (closetHeight === 0) {
    bioFilterHeight = biggerFilterHeight;
  } else if (closetHeight < closetHeightdisappear && closetHeight !== 0) {
    return;
  }

  if (closetHeight < closetHeightchanger && closetHeight !== 0) {
    bioFilterHeight = smallerFilterHeight;
  } else {
    bioFilterHeight = biggerFilterHeight;
  }
  //console.log(-height/2-heightDiff-filterHeight/2)
  //left plank
  filterPlanks[0][0] = colorPlankSide(
    scene,
    -width / scale / 2 + filterGlassWidth / scale / 2 + widthOffset / scale,
    -height / scale / 2 - heightDiff / scale - waterproofPlateHeight / scale,
    0,
    filterGlassWidth / scale,
    bioFilterHeight / scale,
    filterDepth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000, 0x000000],
    [0.4, 0.4, 0.4, 1, 1, 1]
  );
  //back plank
  filterPlanks[0][1] = colorPlankSide(
    scene,
    -filterWidthDiff / scale / 2 + widthOffset / scale,
    -height / scale / 2 - heightDiff / scale - waterproofPlateHeight / scale,
    filterDepth / scale / 2,
    width / scale - filterWidthDiff / scale,
    bioFilterHeight / scale,
    filterGlassWidth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0xd9d9d9, 0xd9d9d9],
    [0.4, 0.4, 0.4, 1, 0.4, 0.4]
  );
  //right plank
  filterPlanks[0][2] = colorPlankSide(
    scene,
    -width / scale / 2 +
      (width / scale - filterWidthDiff / scale) +
      widthOffset / scale,
    -height / scale / 2 - heightDiff / scale - waterproofPlateHeight / scale,
    0,
    filterGlassWidth / scale,
    bioFilterHeight / scale,
    filterDepth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000, 0x000000],
    [0.4, 0.4, 0.4, 1, 1, 1]
  );
  //front plank
  filterPlanks[0][3] = colorPlankSide(
    scene,
    -filterWidthDiff / scale / 2 + widthOffset / scale,
    -height / scale / 2 - heightDiff / scale - waterproofPlateHeight / scale,
    -filterDepth / scale / 2,
    width / scale - filterWidthDiff / scale,
    bioFilterHeight / scale,
    filterGlassWidth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0xd9d9d9, 0xd9d9d9],
    [0.4, 0.4, 0.4, 1, 0.4, 0.4]
  );
  //lower plank
  filterPlanks[0][4] = colorPlankSide(
    scene,
    -filterWidthDiff / scale / 2 + widthOffset / scale,
    -height / scale / 2 -
      heightDiff / scale -
      bioFilterHeight / scale / 2 -
      waterproofPlateHeight / scale,
    0,
    width / scale - filterWidthDiff / scale,
    filterGlassWidth / scale,
    filterDepth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9],
    [0.4, 0.4, 0.4, 0.4, 0.4, 0.4]
  );

  //planks from left to right
  //voorfilter back plank
  filterPlanks[0][5] = colorPlankSide(
    scene,
    -width / scale / 2 +
      sideCompWidth / scale / 2 +
      filterGlassWidth / scale / 2 +
      widthOffset / scale,
    -height / scale / 2 -
      heightDiff / scale -
      backVoorfilterHeightDiff / scale / 2 -
      waterproofPlateHeight / scale,
    -filterDepth / scale / 2 + compDist / scale,
    sideCompWidth / scale - filterGlassWidth / scale,
    bioFilterHeight / scale - backVoorfilterHeightDiff / scale,
    filterGlassWidth / scale,
    [0x000000, 0x000000, 0xd9d9d9, 0x000000, 0xd9d9d9, 0xd9d9d9],
    [1, 1, 0.4, 1, 0.4, 0.4]
  );
  //voorfilter right plank
  //filterPlanks[0][6] = makePlank(scene, -width/scale/2+sideCompWidth/scale, -height/scale/2-heightDiff/scale+compDist/scale/2, 0, filterGlassWidth/scale, bioFilterHeight/scale-compDist/scale, filterDepth/scale, 0xd9d9d9, 0.4);
  filterPlanks[0][6] = makeSidepanel(
    scene,
    [
      [
        -filterDepth / scale / 2 + filterGlassWidth / scale / 2,
        -height / scale / 2 -
          heightDiff / scale -
          bioFilterHeight / scale / 2 +
          filterGlassWidth / scale / 2 -
          waterproofPlateHeight / scale,
      ],
      [
        filterDepth / scale / 2 -
          filterGlassWidth / scale / 2 -
          compDist / scale,
        -height / scale / 2 -
          heightDiff / scale -
          bioFilterHeight / scale / 2 +
          filterGlassWidth / scale / 2 -
          waterproofPlateHeight / scale,
      ],
      [
        filterDepth / scale / 2 - filterGlassWidth / scale / 2,
        -height / scale / 2 -
          heightDiff / scale -
          bioFilterHeight / scale / 2 +
          filterGlassWidth / scale / 2 +
          compDist / scale -
          waterproofPlateHeight / scale,
      ],
      [
        filterDepth / scale / 2 - filterGlassWidth / scale / 2,
        -height / scale / 2 -
          heightDiff / scale +
          bioFilterHeight / scale / 2 +
          filterGlassWidth / scale / 2 -
          leftSeperatorHeightDiff / scale -
          waterproofPlateHeight / scale,
      ],
      [
        -filterDepth / scale / 2 + filterGlassWidth / scale / 2,
        -height / scale / 2 -
          heightDiff / scale +
          bioFilterHeight / scale / 2 +
          filterGlassWidth / scale / 2 -
          leftSeperatorHeightDiff / scale -
          waterproofPlateHeight / scale,
      ],
    ],
    filterGlassWidth / scale,
    0xd9d9d9,
    0.4
  );
  filterPlanks[0][6].rotateY((PI * 270) / 180);
  filterPlanks[0][6].position.x =
    -width / scale / 2 + sideCompWidth / scale + widthOffset / scale;
  //compartiment 2 left plank
  filterPlanks[0][7] = colorPlankSide(
    scene,
    -width / scale / 2 +
      sideCompWidth / scale +
      compDist / scale +
      widthOffset / scale,
    -height / scale / 2 -
      heightDiff / scale -
      bottomPlanksHeightDiff / scale / 2 -
      waterproofPlateHeight / scale,
    0,
    filterGlassWidth / scale,
    bioFilterHeight / scale - bottomPlanksHeightDiff / scale,
    filterDepth / scale - filterGlassWidth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000, 0x000000],
    [0.4, 0.4, 0.4, 1, 1, 1]
  );
  if (width > 200) {
    let filterWidthWithoutSideComp =
      width - filterWidthDiff - 2 * sideCompWidth;
    //compartiment 2 right plank
    filterPlanks[0][8] = colorPlankSide(
      scene,
      -width / scale / 2 +
        sideCompWidth / scale -
        compDist / scale / 2 +
        widthOffset / scale +
        filterWidthWithoutSideComp / scale / 3,
      -height / scale / 2 -
        heightDiff / scale -
        floatingPlanksHeightDiff / scale / 2 +
        compDist / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      filterGlassWidth / scale,
      bioFilterHeight / scale -
        floatingPlanksHeightDiff / scale -
        compDist / scale,
      filterDepth / scale - filterGlassWidth / scale,
      [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000],
      [0.4, 0.4, 0.4, 0.4, 1, 1]
    );
    //compartiment 3 left plank
    filterPlanks[0][9] = colorPlankSide(
      scene,
      -width / scale / 2 +
        sideCompWidth / scale +
        compDist / scale / 2 +
        widthOffset / scale +
        filterWidthWithoutSideComp / scale / 3,
      -height / scale / 2 -
        heightDiff / scale -
        bottomPlanksHeightDiff / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      filterGlassWidth / scale,
      bioFilterHeight / scale - bottomPlanksHeightDiff / scale,
      filterDepth / scale - filterGlassWidth / scale,
      [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000, 0x000000],
      [0.4, 0.4, 0.4, 1, 1, 1]
    );
    //compartiment 4 right plank
    filterPlanks[0][10] = colorPlankSide(
      scene,
      -width / scale / 2 +
        (width / scale - filterWidthDiff / scale) -
        sideCompWidth / scale +
        widthOffset / scale,
      -height / scale / 2 -
        heightDiff / scale -
        floatingPlanksHeightDiff / scale / 2 +
        compDist / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      filterGlassWidth / scale,
      bioFilterHeight / scale -
        floatingPlanksHeightDiff / scale -
        +compDist / scale,
      filterDepth / scale - filterGlassWidth / scale,
      [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000],
      [0.4, 0.4, 0.4, 0.4, 1, 1]
    );

    // if width is larger than 200cm -> third compartiment
    //compartiment 3 right planks
    filterPlanks[0][11] = colorPlankSide(
      scene,
      -width / scale / 2 +
        sideCompWidth / scale -
        compDist / scale / 2 +
        widthOffset / scale +
        (2 * filterWidthWithoutSideComp) / scale / 3,
      -height / scale / 2 -
        heightDiff / scale -
        floatingPlanksHeightDiff / scale / 2 +
        compDist / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      filterGlassWidth / scale,
      bioFilterHeight / scale -
        floatingPlanksHeightDiff / scale -
        compDist / scale,
      filterDepth / scale - filterGlassWidth / scale,
      [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000],
      [0.4, 0.4, 0.4, 0.4, 1, 1]
    );
    //compartiment 4 left plank
    filterPlanks[0][12] = colorPlankSide(
      scene,
      -width / scale / 2 +
        sideCompWidth / scale +
        compDist / scale / 2 +
        widthOffset / scale +
        (2 * filterWidthWithoutSideComp) / scale / 3,
      -height / scale / 2 -
        heightDiff / scale -
        bottomPlanksHeightDiff / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      filterGlassWidth / scale,
      bioFilterHeight / scale - bottomPlanksHeightDiff / scale,
      filterDepth / scale - filterGlassWidth / scale,
      [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000, 0x000000],
      [0.4, 0.4, 0.4, 1, 1, 1]
    );
    //makeFilterSeepThrough(scene, -width/scale/2+sideCompWidth/scale-compDist/scale/2+widthOffset/scale+filterWidthWithoutSideComp/scale/6, -height/scale/2-heightDiff/scale+filterGlassWidth/scale/2+coverPlankIncapeDepth/scale, 0, filterWidthWithoutSideComp/scale/3, filterDepth/scale-2*filterGlassWidth/scale, filterGlassWidth/scale, filterPlanks)
  } else {
    //compartiment 2 right plank
    filterPlanks[0][8] = colorPlankSide(
      scene,
      -filterWidthDiff / scale / 2 - compDist / scale / 2 + widthOffset / scale,
      -height / scale / 2 -
        heightDiff / scale -
        floatingPlanksHeightDiff / scale / 2 +
        compDist / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      filterGlassWidth / scale,
      bioFilterHeight / scale -
        floatingPlanksHeightDiff / scale -
        compDist / scale,
      filterDepth / scale - filterGlassWidth / scale,
      [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000],
      [0.4, 0.4, 0.4, 0.4, 1, 1]
    );
    //compartiment 3 left plank
    filterPlanks[0][9] = colorPlankSide(
      scene,
      -filterWidthDiff / scale / 2 + compDist / scale / 2 + widthOffset / scale,
      -height / scale / 2 -
        heightDiff / scale -
        bottomPlanksHeightDiff / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      filterGlassWidth / scale,
      bioFilterHeight / scale - bottomPlanksHeightDiff / scale,
      filterDepth / scale - filterGlassWidth / scale,
      [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000, 0x000000],
      [0.4, 0.4, 0.4, 1, 1, 1]
    );
    //compartiment 3 right plank
    filterPlanks[0][10] = colorPlankSide(
      scene,
      -width / scale / 2 +
        (width / scale - filterWidthDiff / scale) -
        sideCompWidth / scale +
        widthOffset / scale,
      -height / scale / 2 -
        heightDiff / scale -
        floatingPlanksHeightDiff / scale / 2 +
        compDist / scale / 2 -
        waterproofPlateHeight / scale,
      0,
      filterGlassWidth / scale,
      bioFilterHeight / scale -
        floatingPlanksHeightDiff / scale -
        +compDist / scale,
      filterDepth / scale - filterGlassWidth / scale,
      [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000],
      [0.4, 0.4, 0.4, 0.4, 1, 1]
    );
    // TODO if width is larger than 200cm -> third compartiment
    filterPlanks[0][11] = colorPlankSide(
      scene,
      0,
      0,
      0,
      0,
      0,
      0,
      [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9],
      [0.4, 0.4, 0.4, 0.4, 0.4, 0.4]
    );
    filterPlanks[0][12] = colorPlankSide(
      scene,
      0,
      0,
      0,
      0,
      0,
      0,
      [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9],
      [0.4, 0.4, 0.4, 0.4, 0.4, 0.4]
    );
    scene.remove(filterPlanks[0][11]);
    scene.remove(filterPlanks[0][12]);
  }
  if (filterPlanks[0].length > 13) {
    for (let i = 12 + amountCoverPanels; i >= 13; i--) {
      filterPlanks[0].splice(i, 1);
    }
  }

  //cover planks
  amountCoverPanels = Math.ceil(
    (width - filterWidthDiff) / maxCoverPlanksWidth
  );
  let coverPanelWidth = (width - filterWidthDiff) / amountCoverPanels;
  filterPlanks[0].splice(
    13,
    0,
    makeSidepanel(
      scene,
      [
        [
          -width / scale / 2 + coverPanelWidth / scale + widthOffset / scale,
          -filterDepth / scale / 2 + filterGlassWidth / scale / 2,
        ],
        [
          -width / scale / 2 + coverPanelWidth / scale + widthOffset / scale,
          filterDepth / scale / 2 - filterGlassWidth / scale / 2,
        ],
        [
          -width / scale / 2 + filterGlassWidth / scale + widthOffset / scale,
          filterDepth / scale / 2 - filterGlassWidth / scale / 2,
        ],
        [
          -width / scale / 2 + filterGlassWidth / scale + widthOffset / scale,
          -filterDepth / scale / 2 +
            filterGlassWidth / scale / 2 +
            coverPlankIncapeDepth / scale,
        ],
        [
          -width / scale / 2 +
            filterGlassWidth / scale +
            coverPlankIncapeWidth / scale +
            widthOffset / scale,
          -filterDepth / scale / 2 +
            filterGlassWidth / scale / 2 +
            coverPlankIncapeDepth / scale,
        ],
        [
          -width / scale / 2 +
            filterGlassWidth / scale +
            coverPlankIncapeWidth / scale +
            widthOffset / scale,
          -filterDepth / scale / 2 + filterGlassWidth / scale / 2,
        ],
      ],
      coverPanelThickness / scale,
      0x000000
    )
  );
  filterPlanks[0][13].rotateX((PI * 90) / 180);
  filterPlanks[0][13].position.y =
    -height / scale / 2 -
    heightDiff / scale +
    bioFilterHeight / scale / 2 +
    filterGlassWidth / scale / 2 +
    coverPanelThickness / scale / 2 -
    coverPanelHeightDiff / scale -
    waterproofPlateHeight / scale;

  for (let i = 1; i < amountCoverPanels - 1; i++) {
    filterPlanks[0].splice(
      13 + i,
      0,
      makePlank(
        scene,
        -width / scale / 2 +
          (i * coverPanelWidth) / scale +
          coverPanelWidth / scale / 2 +
          widthOffset / scale,
        -height / scale / 2 -
          heightDiff / scale +
          bioFilterHeight / scale / 2 +
          filterGlassWidth / scale / 2 -
          coverPanelHeightDiff / scale -
          waterproofPlateHeight / scale,
        0,
        coverPanelWidth / scale - 0.05,
        coverPanelThickness / scale,
        filterDepth / scale - filterGlassWidth / scale,
        0x000000
      )
    );
  }
  filterPlanks[0].splice(
    12 + amountCoverPanels,
    0,
    makeSidepanel(
      scene,
      [
        [
          -width / scale / 2 +
            (width / scale - filterWidthDiff / scale) -
            coverPanelWidth / scale +
            widthOffset / scale,
          -filterDepth / scale / 2 + filterGlassWidth / scale / 2,
        ],
        [
          -width / scale / 2 +
            (width / scale - filterWidthDiff / scale) -
            filterGlassWidth / scale -
            coverPlankIncapeWidth / scale +
            widthOffset / scale,
          -filterDepth / scale / 2 + filterGlassWidth / scale / 2,
        ],
        [
          -width / scale / 2 +
            (width / scale - filterWidthDiff / scale) -
            filterGlassWidth / scale -
            coverPlankIncapeWidth / scale +
            widthOffset / scale,
          -filterDepth / scale / 2 +
            filterGlassWidth / scale / 2 +
            coverPlankIncapeDepth / scale,
        ],
        [
          -width / scale / 2 +
            (width / scale - filterWidthDiff / scale) -
            filterGlassWidth / scale +
            widthOffset / scale,
          -filterDepth / scale / 2 +
            filterGlassWidth / scale / 2 +
            coverPlankIncapeDepth / scale,
        ],
        [
          -width / scale / 2 +
            (width / scale - filterWidthDiff / scale) -
            filterGlassWidth / scale +
            widthOffset / scale,
          filterDepth / scale / 2 - filterGlassWidth / scale / 2,
        ],
        [
          -width / scale / 2 +
            (width / scale - filterWidthDiff / scale) -
            coverPanelWidth / scale +
            widthOffset / scale,
          filterDepth / scale / 2 - filterGlassWidth / scale / 2,
        ],
      ],
      coverPanelThickness / scale,
      0x000000
    )
  );
  filterPlanks[0][12 + amountCoverPanels].rotateX((PI * 90) / 180);
  filterPlanks[0][12 + amountCoverPanels].position.y =
    -height / scale / 2 -
    heightDiff / scale +
    bioFilterHeight / scale / 2 +
    filterGlassWidth / scale / 2 +
    coverPanelThickness / scale / 2 -
    coverPanelHeightDiff / scale -
    waterproofPlateHeight / scale;
  if (!showCoverPanels) {
    for (let i = 0; i < amountCoverPanels; i++) {
      scene.remove(filterPlanks[0][13 + i]);
    }
  }
  currWaterProofPlateHeight = waterproofPlateHeight;
  //makeFilterSeepThrough(scene, -width/scale/2+sideCompWidth/scale/2+filterGlassWidth/scale/2+widthOffset/scale, -height/scale/2-heightDiff/scale-bottomPlanksHeightDiff/scale/2+filterHeight/scale/2-bottomPlanksHeightDiff/scale, compDist/scale/2, sideCompWidth/scale-filterGlassWidth/scale, filterDepth/scale-compDist/scale-2*filterGlassWidth/scale, filterGlassWidth/scale, filterPlanks)
}

export function getFilterWidthDiff(aquariumWidth: number) {
  for (let i = 0; i < filterWidth["aquariumWidth"].length; i++) {
    if (aquariumWidth < Number(filterWidth["aquariumWidth"][i])) {
      return Number(filterWidth["filterWidthDiff"][i - 1]);
    }
  }
  return 0;
}

function getSideCompWidth(aquariumWidth: number) {
  for (let i = 0; i < filterWidth["aquariumWidth"].length; i++) {
    if (aquariumWidth > Number(filterWidth["aquariumWidth"][i])) {
      return Number(filterWidth["sideCompWidth"][i]);
    }
  }
  return 0;
}

export function changeBioLocation(
  scene: THREE.Scene,
  width: number,
  height: number,
  bioFilterHeight: number,
  depth: number,
  glassWidth: number,
  filterPlanks: THREE.Mesh[][],
  filterLocation: FilterLocation
) {
  currFilterLocation = filterLocation; 
  let amountPlanks = filterPlanks[0].length;
  for (let i = amountPlanks - 1; i >= 13 + amountCoverPanels; i--) {
    scene.remove(filterPlanks[0][i]);
    filterPlanks[0].splice(i);
  }

  switch (filterLocation) {
    case FilterLocation.leftBack: {
      //front landingplank
      filterPlanks[0].push(
        makePlank(
          scene,
          -width / scale / 2 + landingWidth / scale / 2 + glassWidth,
          -(height - bioFilterHeight) / scale / 2,
          -depth / scale / 2 + glassWidth / 2 + landingDepth / scale,
          landingWidth / scale,
          bioFilterHeight / scale,
          landingthickness / scale,
          0x000000
        )
      );
      //right landingplank
      filterPlanks[0].push(
        makePlank(
          scene,
          -width / scale / 2 +
            landingWidth / scale +
            glassWidth -
            landingthickness / scale / 2,
          -(height - bioFilterHeight) / scale / 2,
          -depth / scale / 2 + glassWidth + landingDepth / scale / 2,
          landingthickness / scale,
          bioFilterHeight / scale,
          landingDepth / scale - landingthickness / scale,
          0x000000
        )
      );
      //left hole
      let lhole = makeCircle(
        scene,
        -width / scale / 2 + landingWidth / scale / 4 + glassWidth,
        -height / scale / 2 + glassWidth / 2 + 0.001,
        -depth / scale / 2 + glassWidth / 2 + landingDepth / scale / 2,
        landingHoleDiameter / scale / 2,
        0x000000
      );
      lhole.rotateX((PI * 270) / 180);
      //middle hole
      let mhole = makeCircle(
        scene,
        -width / scale / 2 + landingWidth / scale / 2 + glassWidth,
        -height / scale / 2 + glassWidth / 2 + 0.001,
        -depth / scale / 2 + glassWidth / 2 + landingDepth / scale / 2,
        landingHoleDiameter / scale / 2,
        0x000000
      );
      mhole.rotateX((PI * 270) / 180);
      //right hole
      let rhole = makeCircle(
        scene,
        -width / scale / 2 + (3 * landingWidth) / scale / 4 + glassWidth,
        -height / scale / 2 + glassWidth / 2 + 0.001,
        -depth / scale / 2 + glassWidth / 2 + landingDepth / scale / 2,
        landingHoleDiameter / scale / 2,
        0x000000
      );
      rhole.rotateX((PI * 270) / 180);
      filterPlanks[0].push(lhole);
      filterPlanks[0].push(mhole);
      filterPlanks[0].push(rhole);
      break;
    }
    case FilterLocation.leftMid: {
      //back
      filterPlanks[0].push(makePlank(scene,-width/scale/2+landingDepth/scale/2+glassWidth, -(height-bioFilterHeight)/scale/2, -landingWidth/scale/2, landingDepth/scale, bioFilterHeight/scale, landingthickness/scale, 0x000000));
      //right
      filterPlanks[0].push(makePlank(scene,-width/scale/2+landingDepth/scale+glassWidth-landingthickness/scale/2,-(height-bioFilterHeight)/scale/2,0, landingthickness/scale, bioFilterHeight/scale, landingWidth/scale-landingthickness/scale, 0x000000));
      //front
      filterPlanks[0].push(makePlank(scene,-width/scale/2+landingDepth/scale/2+glassWidth,-(height-bioFilterHeight)/scale/2, landingWidth/scale/2, landingDepth/scale, bioFilterHeight/scale, landingthickness/scale, 0x000000));
      //left hole
      let lhole = makeCircle(scene, -width/scale/2+landingDepth/scale/2+glassWidth, -height/scale/2+glassWidth/2+0.001, landingWidth/scale/4, landingHoleDiameter/scale/2, 0x000000);
      lhole.rotateX(PI*270/180)
      //middle hole
      let mhole = makeCircle(scene, -width/scale/2+landingDepth/scale/2+glassWidth, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000);
      mhole.rotateX(PI*270/180)
      //right hole
      let rhole = makeCircle(scene, -width/scale/2+landingDepth/scale/2+glassWidth, -height/scale/2+glassWidth/2+0.001, -landingWidth/scale/4, landingHoleDiameter/scale/2, 0x000000);
      rhole.rotateX(PI*270/180)
      filterPlanks[0].push(lhole);
      filterPlanks[0].push(mhole);
      filterPlanks[0].push(rhole)
      break;
    }
    case FilterLocation.midBack: {
      //left
      filterPlanks[0].push(
        makePlank(
          scene,
          -landingWidth / scale / 2 + landingthickness / scale / 2,
          -(height - bioFilterHeight) / scale / 2,
          -depth / scale / 2 + glassWidth + landingDepth / scale / 2,
          landingthickness / scale,
          bioFilterHeight / scale,
          landingDepth / scale - landingthickness / scale,
          0x000000
        )
      );
      //front
      filterPlanks[0].push(
        makePlank(
          scene,
          0,
          -(height - bioFilterHeight) / scale / 2,
          -depth / scale / 2 + glassWidth / 2 + landingDepth / scale,
          landingWidth / scale,
          bioFilterHeight / scale,
          landingthickness / scale,
          0x000000
        )
      );
      //right
      filterPlanks[0].push(
        makePlank(
          scene,
          landingWidth / scale / 2 - landingthickness / scale / 2,
          -(height - bioFilterHeight) / scale / 2,
          -depth / scale / 2 + glassWidth + landingDepth / scale / 2,
          landingthickness / scale,
          bioFilterHeight / scale,
          landingDepth / scale - landingthickness / scale,
          0x000000
        )
      );
      //left hole
      let lhole = makeCircle(
        scene,
        -landingWidth / scale / 4,
        -height / scale / 2 + glassWidth / 2 + 0.001,
        -depth / scale / 2 + glassWidth / 2 + landingDepth / scale / 2,
        landingHoleDiameter / scale / 2,
        0x000000
      );
      lhole.rotateX((PI * 270) / 180);
      //middle hole
      let mhole = makeCircle(
        scene,
        0,
        -height / scale / 2 + glassWidth / 2 + 0.001,
        -depth / scale / 2 + glassWidth / 2 + landingDepth / scale / 2,
        landingHoleDiameter / scale / 2,
        0x000000
      );
      mhole.rotateX((PI * 270) / 180);
      //right hole
      let rhole = makeCircle(
        scene,
        landingWidth / scale / 4,
        -height / scale / 2 + glassWidth / 2 + 0.001,
        -depth / scale / 2 + glassWidth / 2 + landingDepth / scale / 2,
        landingHoleDiameter / scale / 2,
        0x000000
      );
      rhole.rotateX((PI * 270) / 180);
      filterPlanks[0].push(lhole);
      filterPlanks[0].push(mhole);
      filterPlanks[0].push(rhole);
      break;
    }
    case FilterLocation.midMid: {
      //left
      filterPlanks[0].push(
        makePlank(
          scene,
          -landingWidth / scale / 2 + landingthickness / scale / 2,
          -(height - bioFilterHeight) / scale / 2,
          0,
          landingthickness / scale,
          bioFilterHeight / scale,
          landingDepth / scale - landingthickness / scale,
          0x000000
        )
      );
      //front
      filterPlanks[0].push(
        makePlank(
          scene,
          0,
          -(height - bioFilterHeight) / scale / 2,
          landingDepth / scale / 2,
          landingWidth / scale,
          bioFilterHeight / scale,
          landingthickness / scale,
          0x000000
        )
      );
      //right
      filterPlanks[0].push(
        makePlank(
          scene,
          landingWidth / scale / 2 - landingthickness / scale / 2,
          -(height - bioFilterHeight) / scale / 2,
          0,
          landingthickness / scale,
          bioFilterHeight / scale,
          landingDepth / scale - landingthickness / scale,
          0x000000
        )
      );
      //back
      filterPlanks[0].push(
        makePlank(
          scene,
          0,
          -(height - bioFilterHeight) / scale / 2,
          -landingDepth / scale / 2,
          landingWidth / scale,
          bioFilterHeight / scale,
          landingthickness / scale,
          0x000000
        )
      );
      //left hole
      let lhole = makeCircle(
        scene,
        -landingWidth / scale / 4,
        -height / scale / 2 + glassWidth / 2 + 0.001,
        0,
        landingHoleDiameter / scale / 2,
        0x000000
      );
      lhole.rotateX((PI * 270) / 180);
      //middle hole
      let mhole = makeCircle(
        scene,
        0,
        -height / scale / 2 + glassWidth / 2 + 0.001,
        0,
        landingHoleDiameter / scale / 2,
        0x000000
      );
      mhole.rotateX((PI * 270) / 180);
      //right hole
      let rhole = makeCircle(
        scene,
        landingWidth / scale / 4,
        -height / scale / 2 + glassWidth / 2 + 0.001,
        0,
        landingHoleDiameter / scale / 2,
        0x000000
      );
      rhole.rotateX((PI * 270) / 180);
      filterPlanks[0].push(lhole);
      filterPlanks[0].push(mhole);
      filterPlanks[0].push(rhole);
      break;
    }
    case FilterLocation.rightBack: {
      //front landingplank
      filterPlanks[0].push(
        makePlank(
          scene,
          width / scale / 2 - landingWidth / scale / 2 - glassWidth,
          -(height - bioFilterHeight) / scale / 2,
          -depth / scale / 2 + glassWidth / 2 + landingDepth / scale,
          landingWidth / scale,
          bioFilterHeight / scale,
          landingthickness / scale,
          0x000000
        )
      );
      //left landingplank
      filterPlanks[0].push(
        makePlank(
          scene,
          width / scale / 2 -
            landingWidth / scale -
            glassWidth +
            landingthickness / scale / 2,
          -(height - bioFilterHeight) / scale / 2,
          -depth / scale / 2 + glassWidth + landingDepth / scale / 2,
          landingthickness / scale,
          bioFilterHeight / scale,
          landingDepth / scale - landingthickness / scale,
          0x000000
        )
      );
      //left hole
      let lhole = makeCircle(
        scene,
        width / scale / 2 - (3 * landingWidth) / scale / 4 - glassWidth,
        -height / scale / 2 + glassWidth / 2 + 0.001,
        -depth / scale / 2 + glassWidth / 2 + landingDepth / scale / 2,
        landingHoleDiameter / scale / 2,
        0x000000
      );
      lhole.rotateX((PI * 270) / 180);
      //middle hole
      let mhole = makeCircle(
        scene,
        width / scale / 2 - landingWidth / scale / 2 - glassWidth,
        -height / scale / 2 + glassWidth / 2 + 0.001,
        -depth / scale / 2 + glassWidth / 2 + landingDepth / scale / 2,
        landingHoleDiameter / scale / 2,
        0x000000
      );
      mhole.rotateX((PI * 270) / 180);
      //right hole
      let rhole = makeCircle(
        scene,
        width / scale / 2 - landingWidth / scale / 4 - glassWidth,
        -height / scale / 2 + glassWidth / 2 + 0.001,
        -depth / scale / 2 + glassWidth / 2 + landingDepth / scale / 2,
        landingHoleDiameter / scale / 2,
        0x000000
      );
      rhole.rotateX((PI * 270) / 180);
      filterPlanks[0].push(lhole);
      filterPlanks[0].push(mhole);
      filterPlanks[0].push(rhole);
      break;
    }
    case FilterLocation.rightMid: {
      //back
      filterPlanks[0].push(makePlank(scene,width/scale/2-landingDepth/scale/2-glassWidth, -(height-bioFilterHeight)/scale/2, -landingWidth/scale/2, landingDepth/scale, bioFilterHeight/scale, landingthickness/scale, 0x000000));
      //left
      filterPlanks[0].push(makePlank(scene,width/scale/2-landingDepth/scale-glassWidth+landingthickness/scale/2,-(height-bioFilterHeight)/scale/2,0, landingthickness/scale, bioFilterHeight/scale, landingWidth/scale-landingthickness/scale, 0x000000));
      //front
      filterPlanks[0].push(makePlank(scene,width/scale/2-landingDepth/scale/2-glassWidth,-(height-bioFilterHeight)/scale/2 , landingWidth/scale/2, landingDepth/scale, bioFilterHeight/scale, landingthickness/scale, 0x000000));
      //left hole
      let lhole = makeCircle(scene, width/scale/2-landingDepth/scale/2-glassWidth, -height/scale/2+glassWidth/2+0.001, landingWidth/scale/4, landingHoleDiameter/scale/2, 0x000000)
      lhole.rotateX(PI*270/180)
      //middle hole
      let mhole = makeCircle(scene, width/scale/2-landingDepth/scale/2-glassWidth, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000)
      mhole.rotateX(PI*270/180)
      //right hole
      let rhole = makeCircle(scene, width/scale/2-landingDepth/scale/2-glassWidth, -height/scale/2+glassWidth/2+0.001, -landingWidth/scale/4, landingHoleDiameter/scale/2, 0x000000)
      rhole.rotateX(PI*270/180)
      filterPlanks[0].push(lhole);
      filterPlanks[0].push(mhole);
      filterPlanks[0].push(rhole)
      break;
    }
  }
}

export function changeBioLandingWidth(
  scene: THREE.Scene,
  widthDiff: number,
  filterPlanks: THREE.Mesh[][]
) {
  if (
    currFilterLocation !== FilterLocation.midBack &&
    currFilterLocation !== FilterLocation.midMid
  ) {
    for (let i = 13 + amountCoverPanels; i < filterPlanks[0].length; i++) {
      if (
        currFilterLocation === FilterLocation.leftBack ||
        currFilterLocation === FilterLocation.leftMid
      ) {
        filterPlanks[0][i].position.x -= widthDiff / scale / 2;
      } else {
        filterPlanks[0][i].position.x += widthDiff / scale / 2;
      }
    }
  }
}

export function changeBioLandingHeight(
  scene: THREE.Scene,
  currHeight: number,
  newHeight: number,
  filterPlanks: THREE.Mesh[][]
) {
  for (let i = 13 + amountCoverPanels; i < filterPlanks[0].length; i++) {
    if (i >= filterPlanks[0].length - 3) {
      filterPlanks[0][i].position.y -= (newHeight - currHeight) / scale / 2;
    } else {
      filterPlanks[0][i].geometry.scale(1, newHeight / currHeight, 1);
    }
  }
}

export function changeBioLandingDepth(
  scene: THREE.Scene,
  depthDiff: number,
  filterPlanks: THREE.Mesh[][]
) {
  if (
    currFilterLocation !== FilterLocation.leftMid &&
    currFilterLocation !== FilterLocation.midMid &&
    currFilterLocation !== FilterLocation.rightMid
  ) {
    for (let i = 13 + amountCoverPanels; i < filterPlanks[0].length; i++) {
      filterPlanks[0][i].position.z -= depthDiff / scale / 2;
    }
  }
}

//changes the depth of the filter but you have to still call the make filter function
export function changeBioFilterDepth(newDepth: number) {
  if (newDepth - filterDepthDiff < maxFilterDepth) {
    filterDepth = newDepth - filterDepthDiff;
  } else {
    filterDepth = maxFilterDepth;
  }
}
// makes a panel with a shape defined by path, so path contains coordinates and the panelshape follows these coordinates
function makeSidepanel(
  scene: THREE.Scene,
  path: number[][],
  depth: number,
  color: THREE.ColorRepresentation,
  opacity = 1
) {
  let shape = new THREE.Shape();
  shape.moveTo(path[0][0], path[0][1]);
  for (let i = 1; i < path.length; i++) {
    shape.lineTo(path[i][0], path[i][1]);
  }
  shape.lineTo(path[0][0], path[0][1]);

  let shapeGeo = new THREE.ExtrudeGeometry(shape, {
    depth: depth,
    bevelEnabled: false,
  });
  let shapeMat = new THREE.MeshPhongMaterial({
    color: color,
    shininess: 50,
    emissive: color,
  });
  if (opacity < 1) {
    shapeMat.transparent = true;
    shapeMat.opacity = opacity;
  }
  let shapeMesh = new THREE.Mesh(shapeGeo, shapeMat);

  scene.add(shapeMesh);
  return shapeMesh;
}

export function setCoverPanels(
  scene: THREE.Scene,
  filterPlanks: THREE.Mesh[][],
  drawBioCoverPanels: boolean
) {
  showCoverPanels = drawBioCoverPanels;
  if (!showCoverPanels) {
    for (let i = 0; i < amountCoverPanels; i++) {
      scene.remove(filterPlanks[0][13 + i]);
    }
  } else {
    for (let i = 0; i < amountCoverPanels; i++) {
      scene.add(filterPlanks[0][13 + i]);
    }
  }
}

export function makeBioMarineConform(
  scene: THREE.Scene,
  marineConform: boolean,
  width: number,
  height: number,
  lowerClosetHeight: number,
  filterBottomHeight: number,
  depth: number,
  filterPlanks: THREE.Mesh[][]
) {
  filterMarineConform = marineConform;
  if (!marineConform) {
    makeBioFilter(
      scene,
      width,
      height,
      lowerClosetHeight,
      depth,
      currWaterProofPlateHeight,
      filterPlanks
    );
    return;
  }

  filterMarineConform = true;
  bioFilterHeight = biggerFilterHeight;
  for (let i = 0; i < 13 + amountCoverPanels; i++) {
    scene.remove(filterPlanks[0][i]);
  }
  let filterWidthDiff = width - marineConformFilterWidth;
  let sideCompWidth = getSideCompWidth(width);
  let marineConformHeightDiff =
    -height / 2 - (filterBottomHeight + bioFilterHeight / 2);

  //left plank
  filterPlanks[0][0] = colorPlankSide(
    scene,
    -width / scale / 2 + closetThickness / scale,
    -height / scale / 2 -
      marineConformHeightDiff / scale -
      currWaterProofPlateHeight / scale,
    0,
    filterGlassWidth / scale,
    bioFilterHeight / scale,
    filterDepth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000, 0x000000],
    [0.4, 0.4, 0.4, 1, 1, 1]
  );
  //back plank
  filterPlanks[0][1] = colorPlankSide(
    scene,
    -filterWidthDiff / scale / 2 + closetThickness / scale,
    -height / scale / 2 -
      marineConformHeightDiff / scale -
      currWaterProofPlateHeight / scale,
    filterDepth / scale / 2,
    width / scale - filterWidthDiff / scale,
    bioFilterHeight / scale,
    filterGlassWidth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0xd9d9d9, 0xd9d9d9],
    [0.4, 0.4, 0.4, 1, 0.4, 0.4]
  );
  //right plank
  filterPlanks[0][2] = colorPlankSide(
    scene,
    -width / scale / 2 +
      (width / scale - filterWidthDiff / scale) +
      closetThickness / scale,
    -height / scale / 2 -
      marineConformHeightDiff / scale -
      currWaterProofPlateHeight / scale,
    0,
    filterGlassWidth / scale,
    bioFilterHeight / scale,
    filterDepth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000, 0x000000],
    [0.4, 0.4, 0.4, 1, 1, 1]
  );
  //front plank
  filterPlanks[0][3] = colorPlankSide(
    scene,
    -filterWidthDiff / scale / 2 + closetThickness / scale,
    -height / scale / 2 -
      marineConformHeightDiff / scale -
      currWaterProofPlateHeight / scale,
    -filterDepth / scale / 2,
    width / scale - filterWidthDiff / scale,
    bioFilterHeight / scale,
    filterGlassWidth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0xd9d9d9, 0xd9d9d9],
    [0.4, 0.4, 0.4, 1, 0.4, 0.4]
  );
  //lower plank
  filterPlanks[0][4] = colorPlankSide(
    scene,
    -filterWidthDiff / scale / 2 + closetThickness / scale,
    -height / scale / 2 -
      marineConformHeightDiff / scale -
      bioFilterHeight / scale / 2 -
      currWaterProofPlateHeight / scale,
    0,
    width / scale - filterWidthDiff / scale,
    filterGlassWidth / scale,
    filterDepth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9],
    [0.4, 0.4, 0.4, 0.4, 0.4, 0.4]
  );

  //planks from left to right
  //voorfilter back plank
  filterPlanks[0][5] = colorPlankSide(
    scene,
    -width / scale / 2 +
      bottomPlanksHeightDiff / scale / 2 +
      filterGlassWidth / scale / 2 +
      closetThickness / scale,
    -height / scale / 2 -
      marineConformHeightDiff / scale -
      bottomPlanksHeightDiff / scale / 2 -
      currWaterProofPlateHeight / scale,
    -filterDepth / scale / 2 + compDist / scale,
    sideCompWidth / scale - filterGlassWidth / scale,
    bioFilterHeight / scale - bottomPlanksHeightDiff / scale,
    filterGlassWidth / scale,
    [0x000000, 0x000000, 0xd9d9d9, 0x000000, 0xd9d9d9, 0xd9d9d9],
    [1, 1, 0.4, 1, 0.4, 0.4]
  );
  //voorfilter right plank
  //filterPlanks[0][6] = makePlank(scene, -width/scale/2+sideCompWidth/scale, -height/scale/2-marineConformHeightDiff/scale+compDist/scale/2, 0, filterGlassWidth/scale, bioFilterHeight/scale-compDist/scale, filterDepth/scale, 0xd9d9d9, 0.4);
  filterPlanks[0][6] = makeSidepanel(
    scene,
    [
      [
        -filterDepth / scale / 2 + filterGlassWidth / scale / 2,
        -height / scale / 2 -
          marineConformHeightDiff / scale -
          bioFilterHeight / scale / 2 +
          filterGlassWidth / scale / 2 -
          currWaterProofPlateHeight / scale,
      ],
      [
        filterDepth / scale / 2 -
          filterGlassWidth / scale / 2 -
          compDist / scale,
        -height / scale / 2 -
          marineConformHeightDiff / scale -
          bioFilterHeight / scale / 2 +
          filterGlassWidth / scale / 2 -
          currWaterProofPlateHeight / scale,
      ],
      [
        filterDepth / scale / 2 - filterGlassWidth / scale / 2,
        -height / scale / 2 -
          marineConformHeightDiff / scale -
          bioFilterHeight / scale / 2 +
          filterGlassWidth / scale / 2 +
          compDist / scale -
          currWaterProofPlateHeight / scale,
      ],
      [
        filterDepth / scale / 2 - filterGlassWidth / scale / 2,
        -height / scale / 2 -
          marineConformHeightDiff / scale +
          bioFilterHeight / scale / 2 +
          filterGlassWidth / scale / 2 -
          floatingPlanksHeightDiff / scale -
          currWaterProofPlateHeight / scale,
      ],
      [
        -filterDepth / scale / 2 + filterGlassWidth / scale / 2,
        -height / scale / 2 -
          marineConformHeightDiff / scale +
          bioFilterHeight / scale / 2 +
          filterGlassWidth / scale / 2 -
          floatingPlanksHeightDiff / scale -
          currWaterProofPlateHeight / scale,
      ],
    ],
    filterGlassWidth / scale,
    0xd9d9d9,
    0.4
  );
  filterPlanks[0][6].rotateY((PI * 270) / 180);
  filterPlanks[0][6].position.x =
    -width / scale / 2 + sideCompWidth / scale + closetThickness / scale;
  //compartiment 2 left plank
  filterPlanks[0][7] = colorPlankSide(
    scene,
    -width / scale / 2 +
      sideCompWidth / scale +
      compDist / scale +
      closetThickness / scale,
    -height / scale / 2 -
      marineConformHeightDiff / scale -
      bottomPlanksHeightDiff / scale / 2 -
      currWaterProofPlateHeight / scale,
    0,
    filterGlassWidth / scale,
    bioFilterHeight / scale - bottomPlanksHeightDiff / scale,
    filterDepth / scale - filterGlassWidth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000, 0x000000],
    [0.4, 0.4, 0.4, 1, 1, 1]
  );
  //compartiment 2 right plank
  filterPlanks[0][8] = colorPlankSide(
    scene,
    -filterWidthDiff / scale / 2 -
      compDist / scale / 2 +
      closetThickness / scale,
    -height / scale / 2 -
      marineConformHeightDiff / scale -
      floatingPlanksHeightDiff / scale / 2 +
      compDist / scale / 2 -
      currWaterProofPlateHeight / scale,
    0,
    filterGlassWidth / scale,
    bioFilterHeight / scale -
      floatingPlanksHeightDiff / scale -
      compDist / scale,
    filterDepth / scale - filterGlassWidth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000],
    [0.4, 0.4, 0.4, 0.4, 1, 1]
  );
  //compartiment 3 left plank
  filterPlanks[0][9] = colorPlankSide(
    scene,
    -filterWidthDiff / scale / 2 +
      compDist / scale / 2 +
      closetThickness / scale,
    -height / scale / 2 -
      marineConformHeightDiff / scale -
      bottomPlanksHeightDiff / scale / 2 -
      currWaterProofPlateHeight / scale,
    0,
    filterGlassWidth / scale,
    bioFilterHeight / scale - bottomPlanksHeightDiff / scale,
    filterDepth / scale - filterGlassWidth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000, 0x000000],
    [0.4, 0.4, 0.4, 1, 1, 1]
  );
  //compartiment 3 right plank
  filterPlanks[0][10] = colorPlankSide(
    scene,
    -width / scale / 2 +
      (width / scale - filterWidthDiff / scale) -
      sideCompWidth / scale +
      closetThickness / scale,
    -height / scale / 2 -
      marineConformHeightDiff / scale -
      floatingPlanksHeightDiff / scale / 2 +
      compDist / scale / 2 -
      currWaterProofPlateHeight / scale,
    0,
    filterGlassWidth / scale,
    bioFilterHeight / scale -
      floatingPlanksHeightDiff / scale -
      +compDist / scale,
    filterDepth / scale - filterGlassWidth / scale,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0x000000, 0x000000],
    [0.4, 0.4, 0.4, 0.4, 1, 1]
  );
  //TODO
  filterPlanks[0][11] = colorPlankSide(
    scene,
    0,
    0,
    0,
    0,
    0,
    0,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9],
    [0.4, 0.4, 0.4, 0.4, 0.4, 0.4]
  );
  filterPlanks[0][12] = colorPlankSide(
    scene,
    0,
    0,
    0,
    0,
    0,
    0,
    [0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9, 0xd9d9d9],
    [0.4, 0.4, 0.4, 0.4, 0.4, 0.4]
  );
  scene.remove(filterPlanks[0][11]);
  scene.remove(filterPlanks[0][12]);
  if (filterPlanks[0].length > 13) {
    for (let i = 13 + amountCoverPanels; i <= 13; i--) {
      filterPlanks[0].splice(i, 1);
    }
  }

  //cover planks
  amountCoverPanels = Math.ceil(
    (width - filterWidthDiff) / maxCoverPlanksWidth
  );
  let coverPanelWidth = (width - filterWidthDiff) / amountCoverPanels;
  filterPlanks[0].splice(
    13,
    0,
    makeSidepanel(
      scene,
      [
        [
          -width / scale / 2 +
            coverPanelWidth / scale +
            closetThickness / scale,
          -filterDepth / scale / 2 + filterGlassWidth / scale / 2,
        ],
        [
          -width / scale / 2 +
            coverPanelWidth / scale +
            closetThickness / scale,
          filterDepth / scale / 2 - filterGlassWidth / scale / 2,
        ],
        [
          -width / scale / 2 +
            filterGlassWidth / scale +
            closetThickness / scale,
          filterDepth / scale / 2 - filterGlassWidth / scale / 2,
        ],
        [
          -width / scale / 2 +
            filterGlassWidth / scale +
            closetThickness / scale,
          -filterDepth / scale / 2 +
            filterGlassWidth / scale / 2 +
            coverPlankIncapeDepth / scale,
        ],
        [
          -width / scale / 2 +
            filterGlassWidth / scale +
            coverPlankIncapeWidth / scale +
            closetThickness / scale,
          -filterDepth / scale / 2 +
            filterGlassWidth / scale / 2 +
            coverPlankIncapeDepth / scale,
        ],
        [
          -width / scale / 2 +
            filterGlassWidth / scale +
            coverPlankIncapeWidth / scale +
            closetThickness / scale,
          -filterDepth / scale / 2 + filterGlassWidth / scale / 2,
        ],
      ],
      coverPanelThickness / scale,
      0x000000
    )
  );
  filterPlanks[0][13].rotateX((PI * 90) / 180);
  filterPlanks[0][13].position.y =
    -height / scale / 2 -
    marineConformHeightDiff / scale +
    bioFilterHeight / scale / 2 +
    filterGlassWidth / scale / 2 +
    coverPanelThickness / scale / 2 -
    coverPanelHeightDiff / scale -
    currWaterProofPlateHeight / scale;

  for (let i = 1; i < amountCoverPanels - 1; i++) {
    filterPlanks[0].splice(
      13 + i,
      0,
      makePlank(
        scene,
        -width / scale / 2 +
          (i * coverPanelWidth) / scale +
          coverPanelWidth / scale / 2 +
          closetThickness / scale,
        -height / scale / 2 -
          marineConformHeightDiff / scale +
          bioFilterHeight / scale / 2 +
          filterGlassWidth / scale / 2 +
          coverPanelThickness / scale / 2 -
          coverPanelHeightDiff / scale -
          currWaterProofPlateHeight / scale,
        0,
        coverPanelWidth / scale - 0.05,
        coverPanelThickness / scale,
        filterDepth / scale - filterGlassWidth / scale,
        0x000000
      )
    );
  }
  filterPlanks[0].splice(
    12 + amountCoverPanels,
    0,
    makeSidepanel(
      scene,
      [
        [
          -width / scale / 2 +
            (width / scale - filterWidthDiff / scale) -
            coverPanelWidth / scale +
            closetThickness / scale,
          -filterDepth / scale / 2 + filterGlassWidth / scale / 2,
        ],
        [
          -width / scale / 2 +
            (width / scale - filterWidthDiff / scale) -
            filterGlassWidth / scale -
            coverPlankIncapeWidth / scale +
            closetThickness / scale,
          -filterDepth / scale / 2 + filterGlassWidth / scale / 2,
        ],
        [
          -width / scale / 2 +
            (width / scale - filterWidthDiff / scale) -
            filterGlassWidth / scale -
            coverPlankIncapeWidth / scale +
            closetThickness / scale,
          -filterDepth / scale / 2 +
            filterGlassWidth / scale / 2 +
            coverPlankIncapeDepth / scale,
        ],
        [
          -width / scale / 2 +
            (width / scale - filterWidthDiff / scale) -
            filterGlassWidth / scale +
            closetThickness / scale,
          -filterDepth / scale / 2 +
            filterGlassWidth / scale / 2 +
            coverPlankIncapeDepth / scale,
        ],
        [
          -width / scale / 2 +
            (width / scale - filterWidthDiff / scale) -
            filterGlassWidth / scale +
            closetThickness / scale,
          filterDepth / scale / 2 - filterGlassWidth / scale / 2,
        ],
        [
          -width / scale / 2 +
            (width / scale - filterWidthDiff / scale) -
            coverPanelWidth / scale +
            closetThickness / scale,
          filterDepth / scale / 2 - filterGlassWidth / scale / 2,
        ],
      ],
      coverPanelThickness / scale,
      0x000000
    )
  );
  filterPlanks[0][12 + amountCoverPanels].rotateX((PI * 90) / 180);
  filterPlanks[0][12 + amountCoverPanels].position.y =
    -height / scale / 2 -
    marineConformHeightDiff / scale +
    bioFilterHeight / scale / 2 +
    filterGlassWidth / scale / 2 +
    coverPanelThickness / scale / 2 -
    coverPanelHeightDiff / scale -
    currWaterProofPlateHeight / scale;
  if (!showCoverPanels) {
    for (let i = 0; i < amountCoverPanels; i++) {
      scene.remove(filterPlanks[0][13 + i]);
    }
  }
}

export function changeBioMarineConformAquariumWidth(
  oldWidth: number,
  newWidth: number,
  filterPlanks: THREE.Mesh[][]
) {
  for (let i = 0; i < 13 + amountCoverPanels; i++) {
    filterPlanks[0][i].position.x -= (newWidth - oldWidth) / scale / 2;
  }
}

export function changeBioMarineConformAquariumHeight(
  currHeight: number,
  newHeight: number,
  filterPlanks: THREE.Mesh[][]
) {
  for (let i = 0; i < 13 + amountCoverPanels; i++) {
    filterPlanks[0][i].position.y -= (newHeight - currHeight) / scale / 2;
  }
}

// bioFilterHeight is where the filters lowest point should be
export function changeBioFilterHeightDiff(
  scene: THREE.Scene,
  newFilterHeight: number,
  lowerClosetHeight: number,
  filterPlanks: THREE.Mesh[][]
): boolean {
  if (newFilterHeight === 0) {
    return false;
  }
  if (filterMarineConform) {
    return false;
  }
  if (lowerClosetHeight < closetHeightdisappear && lowerClosetHeight !== 0) {
    for (let i = 0; i < 13 + amountCoverPanels; i++) {
      scene.remove(filterPlanks[0][i]);
    }
  } else if (
    lowerClosetHeight < closetHeightchanger &&
    bioFilterHeight !== smallerFilterHeight &&
    lowerClosetHeight !== 0
  ) {
    bioFilterHeight = smallerFilterHeight;
    heightDiff =
      -(newFilterHeight + bioFilterHeight / 2) - filterGlassWidth / 2;
    return true;
  } else {
    if (
      bioFilterHeight !== biggerFilterHeight &&
      lowerClosetHeight >= closetHeightchanger
    ) {
      bioFilterHeight = biggerFilterHeight;
      heightDiff =
        -(newFilterHeight + bioFilterHeight / 2) - filterGlassWidth / 2;
      return true;
    }

    for (let i = 0; i < 13 + amountCoverPanels; i++) {
      scene.add(filterPlanks[0][i]);
    }
    if (!showCoverPanels) {
      for (let i = 0; i < amountCoverPanels; i++) {
        scene.remove(filterPlanks[0][13 + i]);
      }
    }
  }
  let newFilterHeightDiff =
    -(newFilterHeight + bioFilterHeight / 2) - filterGlassWidth / 2;
  if (filterPlanks[0].length >= 13 + amountCoverPanels) {
    for (let i = 0; i < 13 + amountCoverPanels; i++) {
      filterPlanks[0][i].position.y -=
        (newFilterHeightDiff - heightDiff) / scale;
    }
  }

  heightDiff = newFilterHeightDiff;
  return false;
}

//only used if electraruimte is chosen on left side
//width should be current width of aquarium to calculate the width of the electraruimte
export function changeBioWidthOffset(width: number) {
  if (width === 0) {
    widthOffset = 0;
  } else {
    widthOffset = getElectraWidth(width);
  }
}

function getElectraWidth(width: number): number {
  for (let i = 0; i < filterWidth["aquariumWidth"].length; i++) {
    if (width < Number(filterWidth["aquariumWidth"][i])) {
      return Number(filterWidth["electrabreedte"][i - 1]);
    }
  }
  return 0;
}

function makeFilterSeepThrough(
  scene: THREE.Scene,
  x: number,
  y: number,
  z: number,
  width: number,
  depth: number,
  height: number,
  filterPlanks: THREE.Mesh[][]
) {
  if (filterPlanks.length === 1) {
    filterPlanks.push([]);
  }

  filterPlanks[1].push(
    makePlank(scene, x, y, z, width, height, depth, 0x000000)
  );
  let seepThroughColumns = (width * scale) / seepthroughRadius / 4;
  let seepthroughRows = (depth * scale) / seepthroughRadius / 4;
  for (let i = 0; i < seepThroughColumns; i++) {
    for (let j = 0; j < seepthroughRows; j++) {
      let circle = makeCircle(
        scene,
        x -
          width / 2 +
          (i * width) / seepThroughColumns +
          seepthroughRadius / scale,
        y + height / 2 + 0.001,
        z -
          depth / 2 +
          (j * depth) / seepthroughRows +
          seepthroughRadius / scale,
        seepthroughRadius / scale,
        0xffffff
      );
      circle.rotateX((-90 * PI) / 180);
      filterPlanks[1].push(circle);
    }
  }
}

export function bioChangeWaterProofPlateHeight(
  newWaterProofPlateHeight: number,
  filterPlanks: THREE.Mesh[][]
) {
  for (let i = 0; i < 13 + amountCoverPanels; i++) {
    filterPlanks[0][i].position.y +=
      currWaterProofPlateHeight - newWaterProofPlateHeight;
  }
  currWaterProofPlateHeight = newWaterProofPlateHeight;
}
