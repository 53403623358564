import React, { useEffect, useState } from "react";
import { StepStatus, Steps } from "../../@types/configurator";
import {
  CheckCircle,
  CircleDashed,
  WarningCircle,
} from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getIndividualStepInfo } from "../../utils/stepConfigurator";

type Props = { stepId: Steps };

function StepStatusIcon({ stepId }: Props) {
  const stepInfoListRedux = useSelector(
    (state: RootState) => state.configurator.stepInfoList
  );
  const [stepStatus, setStepStatus] = useState<StepStatus>(
    getIndividualStepInfo(stepId, stepInfoListRedux).status
  );
  useEffect(() => {
    setStepStatus(getIndividualStepInfo(stepId, stepInfoListRedux).status);
  }, [stepInfoListRedux]);

  return (
    <div className="configurator-step-button-status-icon">
      {stepStatus === StepStatus.toDo && (
        <CircleDashed size={24} weight="bold" color="var(--primary-300)" />
      )}
      {stepStatus === StepStatus.isCompleted && (
        <CheckCircle size={24} weight="bold" color="var(--green-500)" />
      )}
      {stepStatus === StepStatus.hasIssue && (
        <WarningCircle size={24} weight="bold" color="var(--red-500)" />
      )}
    </div>
  );
}

export default StepStatusIcon;
