import React, { useEffect, useState } from "react";
import ConfiguratorStep from "../../templates/ConfiguratorStep";
import { Steps } from "../../../@types/configurator";
import { Ruler } from "@phosphor-icons/react";
import StepItem from "../../templates/StepItem";
import dimensions_example from "../../../images/dimensions-example.png";
import {
  Aquarium,
  AquariumDimensions,
  SiliconColor,
} from "../../../@types/aquarium";
import IconToggle from "../../molecules/IconToggle";
import { useDispatch, useSelector } from "react-redux";
import {
  setExtraSiliconColor,
  setSharpenGlass as setSharpenGlassRedux,
} from "../../../redux/aquariumSlice";
import { RootState } from "../../../redux/store";
import { changeWidth } from "../../aquariumComponents/aquarium";
import { changeDepth } from "../../aquariumComponents/aquarium";
import { changeHeight } from "../../aquariumComponents/aquarium";
import { setAquariumDimensions as setAquariumDimensionsRedux } from "../../../redux/aquariumSlice";
import SiliconColorMenu from "../../molecules/SiliconColorMenu";
import DimensionSlider from "../../molecules/DimensionSlider";
import { useDebounce } from "use-debounce";
import { checkDimensions } from "../../../utils/aquarium";
import { AllTextObject } from "../../../@types/language";

type Props = {
  allText: AllTextObject;
  setOpenStep: React.Dispatch<React.SetStateAction<Steps | null>>;
};

function DimensionsStep({ allText, setOpenStep }: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const dispatch = useDispatch();
  const stepItemInfoOverview = useSelector(
    (state: RootState) => state.configurator.stepItemInfoOverview
  );

  const [aquariumDimensions, setAquariumDimensions] = useState(
    aquarium.aquariumDimensions
  );
  const [fallbackDimensions, setFallbackDimensions] = useState(
    aquarium.aquariumDimensions
  );
  const [saveToReduxDimensions] = useDebounce(fallbackDimensions, 500);
  const [areValidDimensions, setAreValidDimensions] = useState({
    isValidWidth: true,
    isValidDepth: true,
    isValidHeight: true,
  });

  const [sharpenGlass, setSharpenGlass] = useState(
    aquarium.sharpenGlass || false
  );

  const [siliconColor, setSiliconColor] = useState<SiliconColor>(
    aquarium.extra.siliconeColor
  );

  useEffect(() => {
    dispatch(setSharpenGlassRedux(sharpenGlass));
  }, [sharpenGlass]);

  useEffect(() => {
    dispatch(setExtraSiliconColor(siliconColor));
  }, [siliconColor]);

  // Reset Aquarium parameters when pushed on reset-button
  useEffect(() => {
    setAquariumDimensions(aquarium.aquariumDimensions);
    setSharpenGlass(aquarium.sharpenGlass);
    setSiliconColor(aquarium.extra.siliconeColor);
  }, [aquarium]);

  const [newWidth, setNewWidth] = useState<number>(aquariumDimensions.width);
  const [newDepth, setNewDepth] = useState<number>(aquariumDimensions.depth);
  const [newHeight, setNewHeight] = useState<number>(aquariumDimensions.height);

  useEffect(() => {
    setAreValidDimensions(checkDimensions(fallbackDimensions));
  }, [aquariumDimensions]);

  useEffect(() => {
    dispatch(setAquariumDimensionsRedux(saveToReduxDimensions));
  }, [saveToReduxDimensions]);

  const changeDimensions = (newDimensions: AquariumDimensions) => {
    // 2 already set the new dimensions to the sliders, no matter if they are correct or not
    setAquariumDimensions(newDimensions);

    // 3 check if the new values are correct and render the drawing
    const checkedValidDimensions = checkDimensions(newDimensions);
    setAreValidDimensions(checkedValidDimensions);
    if (
      checkedValidDimensions.isValidWidth &&
      checkedValidDimensions.isValidDepth &&
      checkedValidDimensions.isValidHeight
    ) {
      // 4 if the new values are correct, set them as a fallback values
      setFallbackDimensions(newDimensions);
    } else {
      // 4 if the new values were not correct, set the sliders back to the latest correct values aka the fallbackvalues
      setAquariumDimensions(fallbackDimensions);
      //rerender the drawing
      changeWidth(fallbackDimensions);
      changeDepth(fallbackDimensions);
      changeHeight(fallbackDimensions);
      // update the error state of the inputs
      setAreValidDimensions(checkDimensions(newDimensions));
    }
  };

  useEffect(() => {
    // 1 detect when new value is entered
    const newDimensions: AquariumDimensions = {
      width: newWidth,
      depth: newDepth,
      height: newHeight,
    };
    changeDimensions(newDimensions);
  }, [newWidth, newDepth, newHeight]);

  return (
    <ConfiguratorStep
      allText={allText}
      title={allText.tid_gen_03}
      step={2}
      stepId={Steps.dimensions}
      symbol={
        <Ruler
          className="configurator-step-header-symbol"
          size={80}
          color="var(--primary-300)"
        />
      }
      setOpenStep={setOpenStep}
    >
      <>
        <StepItem
          title={allText.tid_st1_01}
          helptext={allText.tid_st1_02}
          errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
          hasIssue={stepItemInfoOverview.dimensions.aquariumDimensions.hasIssue}
        >
          <>
            <figure className="dimensions-step-figure">
              <img
                src={dimensions_example}
                alt="Dimensions example"
                className="dimensions-step-img"
              />
            </figure>
            <DimensionSlider
              title={allText.tid_gen_09}
              aquariumDimension={aquariumDimensions.width}
              setValue={setNewWidth}
              isValid={areValidDimensions.isValidWidth}
              minValue={50}
              maxValue={360}
              id="aquarium-width"
              stepIssue={
                stepItemInfoOverview.dimensions.aquariumDimensions.hasIssue
              }
            />
            <DimensionSlider
              title={allText.tid_gen_10}
              aquariumDimension={aquariumDimensions.depth}
              setValue={setNewDepth}
              isValid={areValidDimensions.isValidDepth}
              minValue={30}
              maxValue={110}
              id="aquarium-depth"
              stepIssue={
                stepItemInfoOverview.dimensions.aquariumDimensions.hasIssue
              }
            />
            <DimensionSlider
              title={allText.tid_gen_11}
              aquariumDimension={aquariumDimensions.height}
              setValue={setNewHeight}
              isValid={areValidDimensions.isValidHeight}
              minValue={30}
              maxValue={115}
              id="aquarium-height"
              stepIssue={
                stepItemInfoOverview.dimensions.aquariumDimensions.hasIssue
              }
            />
          </>
        </StepItem>
        <StepItem
          title={allText.tid_st1_04}
          helptext={allText.tid_st1_05}
          errortext={allText.tid_st1_06}
          hasIssue={false}
        >
          <SiliconColorMenu
            allText={allText}
            checked={siliconColor}
            setValue={setSiliconColor}
          />
        </StepItem>
        <StepItem
          title={allText.tid_st1_07}
          helptext={allText.tid_st1_08}
          errortext={allText.tid_st1_09}
          hasIssue={false}
        >
          <div className="var">
            <IconToggle
              checked={sharpenGlass}
              setValue={setSharpenGlass}
              text={allText.tid_st1_10}
            />
          </div>
        </StepItem>
      </>
    </ConfiguratorStep>
  );
}

export default DimensionsStep;
