import React, { useState } from "react";
import { DashboardPages } from "../../@types/configurator";
import QuickFilterBox from "../atoms/QuickFilterBox";

type Props = {
  type: DashboardPages;
};

function QuickFilters({ type }: Props) {
  const [filterQuery, setFilterQuery] = useState<string | null>(null);

  function QuickFilterMenu(type: DashboardPages) {
    switch (type) {
      case DashboardPages.offertes:
        return (
          <>
            <QuickFilterBox
              text="test checkbox"
              query="filter-1"
              setValue={setFilterQuery}
            />
            <QuickFilterBox
              text="test checkbox 2"
              query="filter-2"
              setValue={setFilterQuery}
            />
            <QuickFilterBox
              text="test checkbox 3"
              query="filter-3"
              setValue={setFilterQuery}
            />
            <QuickFilterBox
              text="test checkbox 4"
              query="filter-4"
              setValue={setFilterQuery}
            />
          </>
        );
        break;
      case DashboardPages.products:
        return (
          <>
            <QuickFilterBox
              text="test checkbox"
              query="filter-1"
              setValue={setFilterQuery}
            />
            <QuickFilterBox
              text="test checkbox 2"
              query="filter-2"
              setValue={setFilterQuery}
            />
            <QuickFilterBox
              text="test checkbox 3"
              query="filter-3"
              setValue={setFilterQuery}
            />
            <QuickFilterBox
              text="test checkbox 4"
              query="filter-4"
              setValue={setFilterQuery}
            />
          </>
        );
        break;

      default:
        break;
    }
  }

  return (
    <div className="quick-filters-container">
      <h3 className="quick-filters-title">Quick filters</h3>
      <div className="quick-filters-selection-container">
        {QuickFilterMenu(type)}
      </div>
    </div>
  );
}

export default QuickFilters;
