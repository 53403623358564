import React from "react";
import { ImageOption } from "../../@types/configurator";
import {
  AquariumType,
  DoorType,
  ElectraRuimte,
  ExtraLEDFreshWaterOption,
  ExtraLEDSaltWaterOption,
  FilterLocation,
  FilterOptions,
  FullGlassCoverPanels,
  FurnitureType,
  PvcPipeworkPackage,
  TurtleIsland,
} from "../../@types/aquarium";
import { DecorTexture } from "../../@types/textures";

type Props = {
  name: string;
  option: ImageOption;
  value:
    | AquariumType
    | DecorTexture
    | FullGlassCoverPanels
    | TurtleIsland
    | FilterOptions
    | FilterLocation
    | FurnitureType
    | ElectraRuimte
    | DoorType
    | ExtraLEDFreshWaterOption
    | ExtraLEDSaltWaterOption
    | PvcPipeworkPackage
    | null;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  buttonText?: string;
  buttonAction?: any;
  buttonIcon?: JSX.Element;
};

function ConfiguratorImageOption({
  name,
  option,
  value,
  setValue,
  buttonText,
  buttonAction,
  buttonIcon,
}: Props) {
  return (
    <div
      className={
        "imageoption-container " +
        (value === option.id && value !== null
          ? "imageoption-container-active "
          : "imageoption-container-inactive ") +
        (value === null ? "imageoption-container-default" : "")
      }
      onClick={() => setValue(option.id)}
    >
      <input
        type="radio"
        name={name}
        checked={value === option.id ? true : false}
        id={name + "-" + option.id}
        readOnly
        className="imageoption-input"
      />
      <figure className="imageoption-figure">
        <img className="imageoption-img" src={option.img} alt={option.title} />
      </figure>
      <label className="imageoption-label" htmlFor="_test">
        {option.title}
      </label>
      {buttonText && buttonAction && value === option.id && (
        <button className="imageoption-button" onClick={buttonAction}>
          {buttonText}
          {buttonIcon}
        </button>
      )}
    </div>
  );
}

export default ConfiguratorImageOption;
