import { ImageOption } from "../@types/configurator";
import { DecorTexture } from "../@types/textures";
import texture01 from "../images/textures/texture01.jpg";
import texture02 from "../images/textures/texture02.jpg";
import texture03 from "../images/textures/texture03.jpg";
import texture04 from "../images/textures/texture04.jpg";
import texture05 from "../images/textures/texture05.jpg";
import texture06 from "../images/textures/texture06.jpg";
import texture07 from "../images/textures/texture07.jpg";
import texture08 from "../images/textures/texture08.jpg";
import texture09 from "../images/textures/texture09.jpg";
import texture10 from "../images/textures/texture10.jpg";
import texture11 from "../images/textures/texture11.jpg";
import texture12 from "../images/textures/texture12.jpg";
import texture13 from "../images/textures/texture13.jpg";
import texture14 from "../images/textures/texture14.jpg";
import texture15 from "../images/textures/texture15.jpg";
import texture16 from "../images/textures/texture16.jpg";
import texture17 from "../images/textures/texture17.jpg";
import texture18 from "../images/textures/texture18.jpg";
import texture19 from "../images/textures/texture19.jpg";
import texture20 from "../images/textures/texture20.jpg";
import texture21 from "../images/textures/texture21.jpg";
import texture22 from "../images/textures/texture22.jpg";
import texture23 from "../images/textures/texture23.jpg";
import placeholder from "../images/textures/placeholder.jpg";
import { AllTextObject } from "../@types/language";

export const decorTextures = (allText: AllTextObject): Array<ImageOption> => {
  return [
    {
      id: DecorTexture.texture01,
      title: allText.texture_01,
      img: texture01,
    },
    {
      id: DecorTexture.texture02,
      title: allText.texture_02,
      img: texture02,
    },
    {
      id: DecorTexture.texture03,
      title: allText.texture_03,
      img: texture03,
    },
    {
      id: DecorTexture.texture04,
      title: allText.texture_04,
      img: texture04,
    },
    {
      id: DecorTexture.texture05,
      title: allText.texture_05,
      img: texture05,
    },
    {
      id: DecorTexture.texture06,
      title: allText.texture_06,
      img: texture06,
    },
    {
      id: DecorTexture.texture07,
      title: allText.texture_07,
      img: texture07,
    },
    {
      id: DecorTexture.texture08,
      title: allText.texture_08,
      img: texture08,
    },
    {
      id: DecorTexture.texture09,
      title: allText.texture_09,
      img: texture09,
    },
    {
      id: DecorTexture.texture10,
      title: allText.texture_10,
      img: texture10,
    },
    {
      id: DecorTexture.texture11,
      title: allText.texture_11,
      img: texture11,
    },
    {
      id: DecorTexture.texture12,
      title: allText.texture_12,
      img: texture12,
    },
    {
      id: DecorTexture.texture13,
      title: allText.texture_13,
      img: texture13,
    },
    {
      id: DecorTexture.texture14,
      title: allText.texture_14,
      img: texture14,
    },
    {
      id: DecorTexture.texture15,
      title: allText.texture_15,
      img: texture15,
    },
    {
      id: DecorTexture.texture16,
      title: allText.texture_16,
      img: texture16,
    },
    {
      id: DecorTexture.texture17,
      title: allText.texture_17,
      img: texture17,
    },
    {
      id: DecorTexture.texture18,
      title: allText.texture_18,
      img: texture18,
    },
    {
      id: DecorTexture.texture19,
      title: allText.texture_19,
      img: texture19,
    },
    {
      id: DecorTexture.texture20,
      title: allText.texture_20,
      img: texture20,
    },
    {
      id: DecorTexture.texture21,
      title: allText.texture_21,
      img: texture21,
    },
    {
      id: DecorTexture.texture22,
      title: allText.texture_22,
      img: texture22,
    },
    {
      id: DecorTexture.texture23,
      title: allText.texture_23,
      img: texture23,
    },
  ];
};

export const decorTexturePlaceholder = (
  allText: AllTextObject
): ImageOption => {
  return {
    id: DecorTexture.placeholder,
    title: allText.texture_pl,
    img: placeholder,
  };
};
