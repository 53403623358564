import * as THREE from 'three';
import { makeCircle, makeCylinder, makePlank, makeRectangle } from './helper';
import { FilterLocation } from '../../@types/aquarium';

const scale = 25;
const PI = 3.14159265359;

const filterWidth = 35;
const filterDepth = 15;
const groovewidth = 2;
const leftCompWidth = 5;
const rightCompWidth = 10
const filterGlassThickness = 0.6;
const innerGlassHeightDiff = 4;
const tubeRadius = 1;
const tubeLength = 3;
const tubeHeightDiff = 10;
let currFilterLocation: FilterLocation;

export function makeBiologicalFilter(scene: THREE.Scene, width: number, height: number, depth: number, filterPlanks: THREE.Mesh[][]){

}

export function changeBiologicalLocation(scene: THREE.Scene, width: number, height: number, filterHeight: number, depth: number, glassWidth: number, filterPlanks: THREE.Mesh[][], filterLocation: FilterLocation){
    currFilterLocation = filterLocation;
    for(let i = filterPlanks[0].length; i >= 0; i--){
        scene.remove(filterPlanks[0][i]);
        filterPlanks[0].splice(i,1);
    }
    switch(filterLocation){
        case(FilterLocation.leftBack):{
            let x = -width/scale/2+filterWidth/scale/2+groovewidth/scale+glassWidth
            let y = -(height-filterHeight)/scale/2;
            let z = -depth/scale/2+filterDepth/scale/2+glassWidth
            //front plank
            filterPlanks[0].push(makePlank(scene, x, y, z+filterDepth/scale/2, filterWidth/scale, filterHeight/scale, filterGlassThickness/scale, 0xa0a0a0))
            //right plank
            filterPlanks[0].push(makePlank(scene, x+filterWidth/scale/2-filterGlassThickness/scale/2, y, z, filterGlassThickness/scale, filterHeight/scale, filterDepth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //left compartiment plank
            filterPlanks[0].push(makePlank(scene, x-filterWidth/scale/2+leftCompWidth/scale, y-innerGlassHeightDiff/scale, z, filterGlassThickness/scale, filterHeight/scale-2*innerGlassHeightDiff/scale, filterDepth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //right compartiment plank
            filterPlanks[0].push(makePlank(scene, x+filterWidth/scale/2-rightCompWidth/scale, y, z, filterGlassThickness/scale, filterHeight/scale-2*innerGlassHeightDiff/scale, filterDepth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //maybe the hole on the side of the filter
            let tube = makeCylinder(scene, x+filterWidth/scale/2+tubeLength/scale/2,height/scale/2-tubeHeightDiff/scale,z, tubeRadius/scale, tubeLength/scale, 0xa0a0a0)
            tube.rotateZ(PI*90/180)
            filterPlanks[0].push(tube);
            break;
        }
        case(FilterLocation.leftMid):{
            let x = -width/scale/2+filterDepth/scale/2+glassWidth
            let y = -(height-filterHeight)/scale/2;
            let z = 0
            //front plank
            filterPlanks[0].push(makePlank(scene, x+groovewidth/scale/2, y, z+filterWidth/scale/2, filterDepth/scale-groovewidth/scale, filterHeight/scale, filterGlassThickness/scale, 0xa0a0a0))
            //right plank
            filterPlanks[0].push(makePlank(scene, x+filterDepth/scale/2-filterGlassThickness/scale/2, y, z, filterGlassThickness/scale, filterHeight/scale, filterWidth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //back plank
            filterPlanks[0].push(makePlank(scene, x, y, z-filterWidth/scale/2, filterDepth/scale, filterHeight/scale, filterGlassThickness/scale, 0xa0a0a0))
            //left compartiment plank
            filterPlanks[0].push(makePlank(scene, x-filterGlassThickness/scale/2, y-innerGlassHeightDiff/scale, z-filterWidth/scale/2+leftCompWidth/scale, filterDepth/scale-filterGlassThickness/scale, filterHeight/scale-2*innerGlassHeightDiff/scale, filterGlassThickness/scale, 0xa0a0a0))
            //right compartiment plank
            filterPlanks[0].push(makePlank(scene, x-filterGlassThickness/scale/2, y, z+filterWidth/scale/2-rightCompWidth/scale, filterDepth/scale-filterGlassThickness/scale, filterHeight/scale-2*innerGlassHeightDiff/scale,filterGlassThickness/scale , 0xa0a0a0))
            //maybe the hole on the side of the filter
            let tube = makeCylinder(scene, x,height/scale/2-tubeHeightDiff/scale,z-filterWidth/scale/2-tubeLength/scale/2, tubeRadius/scale, tubeLength/scale, 0xa0a0a0)
            tube.rotateZ(PI*90/180)
            tube.rotateX(PI*90/180)
            filterPlanks[0].push(tube);
            break;
        }
        case(FilterLocation.midBack):{
            let x = 0
            let y = -(height-filterHeight)/scale/2;
            let z = -depth/scale/2+filterDepth/scale/2+glassWidth-filterGlassThickness/scale/2
            //front plank
            filterPlanks[0].push(makePlank(scene, x, y, z+filterDepth/scale/2, filterWidth/scale, filterHeight/scale, filterGlassThickness/scale, 0xa0a0a0))
            //right plank
            filterPlanks[0].push(makePlank(scene, x+filterWidth/scale/2-filterGlassThickness/scale/2, y, z, filterGlassThickness/scale, filterHeight/scale, filterDepth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //left plank
            filterPlanks[0].push(makePlank(scene, x-filterWidth/scale/2-filterGlassThickness/scale/2, y, z+filterGlassThickness/scale/2+groovewidth/scale/2, filterGlassThickness/scale, filterHeight/scale, filterDepth/scale-groovewidth/scale, 0xa0a0a0))
            //left compartiment plank
            filterPlanks[0].push(makePlank(scene, x-filterWidth/scale/2+leftCompWidth/scale, y-innerGlassHeightDiff/scale, z, filterGlassThickness/scale, filterHeight/scale-2*innerGlassHeightDiff/scale, filterDepth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //right compartiment plank
            filterPlanks[0].push(makePlank(scene, x+filterWidth/scale/2-rightCompWidth/scale, y, z, filterGlassThickness/scale, filterHeight/scale-2*innerGlassHeightDiff/scale, filterDepth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //maybe the hole on the side of the filter
            let tube = makeCylinder(scene, x+filterWidth/scale/2+tubeLength/scale/2,height/scale/2-tubeHeightDiff/scale,z, tubeRadius/scale, tubeLength/scale, 0xa0a0a0)
            tube.rotateZ(PI*90/180)
            filterPlanks[0].push(tube);
            break;
        }
        case(FilterLocation.midMid):{
            let x = 0
            let y = -(height-filterHeight)/scale/2;
            let z = 0
            //front plank
            filterPlanks[0].push(makePlank(scene, x, y, z+filterDepth/scale/2, filterWidth/scale, filterHeight/scale, filterGlassThickness/scale, 0xa0a0a0))
            //back plank
            filterPlanks[0].push(makePlank(scene, x-groovewidth/scale/2-filterGlassThickness/scale/2, y, z-filterDepth/scale/2, filterWidth/scale+groovewidth/scale+filterGlassThickness/scale, filterHeight/scale, filterGlassThickness/scale, 0xa0a0a0))
            //right plank
            filterPlanks[0].push(makePlank(scene, x+filterWidth/scale/2-filterGlassThickness/scale/2, y, z, filterGlassThickness/scale, filterHeight/scale, filterDepth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //left plank
            filterPlanks[0].push(makePlank(scene, x-filterWidth/scale/2-filterGlassThickness/scale/2-groovewidth/scale, y, z+filterGlassThickness/scale/2, filterGlassThickness/scale, filterHeight/scale, filterDepth/scale, 0xa0a0a0))
            //left compartiment plank
            filterPlanks[0].push(makePlank(scene, x-filterWidth/scale/2+leftCompWidth/scale, y-innerGlassHeightDiff/scale, z, filterGlassThickness/scale, filterHeight/scale-2*innerGlassHeightDiff/scale, filterDepth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //right compartiment plank
            filterPlanks[0].push(makePlank(scene, x+filterWidth/scale/2-rightCompWidth/scale, y, z, filterGlassThickness/scale, filterHeight/scale-2*innerGlassHeightDiff/scale, filterDepth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //maybe the hole on the side of the filter
            let tube = makeCylinder(scene, x+filterWidth/scale/2+tubeLength/scale/2,height/scale/2-tubeHeightDiff/scale,z, tubeRadius/scale, tubeLength/scale, 0xa0a0a0)
            tube.rotateZ(PI*90/180)
            filterPlanks[0].push(tube);
            break;
        }
        case(FilterLocation.rightBack):{
            let x = -(-width/scale/2+filterWidth/scale/2+glassWidth)
            let y = -(height-filterHeight)/scale/2;
            let z = -depth/scale/2+filterDepth/scale/2+glassWidth-filterGlassThickness/scale/2
            //front plank
            filterPlanks[0].push(makePlank(scene, x, y, z+filterDepth/scale/2, filterWidth/scale, filterHeight/scale, filterGlassThickness/scale, 0xa0a0a0))
            //left plank
            filterPlanks[0].push(makePlank(scene, x-filterWidth/scale/2-filterGlassThickness/scale/2, y, z+filterGlassThickness/scale/2+groovewidth/scale/2, filterGlassThickness/scale, filterHeight/scale, filterDepth/scale-groovewidth/scale, 0xa0a0a0))
            //left compartiment plank
            filterPlanks[0].push(makePlank(scene, x-filterWidth/scale/2+leftCompWidth/scale, y-innerGlassHeightDiff/scale, z, filterGlassThickness/scale, filterHeight/scale-2*innerGlassHeightDiff/scale, filterDepth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //right compartiment plank
            filterPlanks[0].push(makePlank(scene, x+filterWidth/scale/2-rightCompWidth/scale, y, z, filterGlassThickness/scale, filterHeight/scale-2*innerGlassHeightDiff/scale, filterDepth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //maybe the hole on the side of the filter
            let tube = makeCylinder(scene, x+filterWidth/scale/2-rightCompWidth/scale/2,height/scale/2-tubeHeightDiff/scale,z+filterDepth/scale/2+tubeLength/scale/2, tubeRadius/scale, tubeLength/scale, 0xa0a0a0)
            tube.rotateZ(PI*90/180)
            tube.rotateX(PI*90/180)
            filterPlanks[0].push(tube);
            break;
        }
        case(FilterLocation.rightMid):{
            let x = width/scale/2-filterDepth/scale/2-glassWidth
            let y = -(height-filterHeight)/scale/2;
            let z = 0
            //front plank
            filterPlanks[0].push(makePlank(scene, x-groovewidth/scale/2, y, z+filterWidth/scale/2, filterDepth/scale-groovewidth/scale, filterHeight/scale, filterGlassThickness/scale, 0xa0a0a0))
            //left plank
            filterPlanks[0].push(makePlank(scene, x-filterDepth/scale/2+filterGlassThickness/scale/2, y, z, filterGlassThickness/scale, filterHeight/scale, filterWidth/scale-filterGlassThickness/scale, 0xa0a0a0))
            //back plank
            filterPlanks[0].push(makePlank(scene, x, y, z-filterWidth/scale/2, filterDepth/scale, filterHeight/scale, filterGlassThickness/scale, 0xa0a0a0))
            //left compartiment plank
            filterPlanks[0].push(makePlank(scene, x+filterGlassThickness/scale/2, y-innerGlassHeightDiff/scale, z-filterWidth/scale/2+leftCompWidth/scale, filterDepth/scale-filterGlassThickness/scale, filterHeight/scale-2*innerGlassHeightDiff/scale, filterGlassThickness/scale, 0xa0a0a0))
            //right compartiment plank
            filterPlanks[0].push(makePlank(scene, x+filterGlassThickness/scale/2, y, z+filterWidth/scale/2-rightCompWidth/scale, filterDepth/scale-filterGlassThickness/scale, filterHeight/scale-2*innerGlassHeightDiff/scale,filterGlassThickness/scale , 0xa0a0a0))
            //maybe the hole on the side of the filter
            let tube = makeCylinder(scene, x,height/scale/2-tubeHeightDiff/scale,z-filterWidth/scale/2-tubeLength/scale/2, tubeRadius/scale, tubeLength/scale, 0xa0a0a0)
            tube.rotateZ(PI*90/180)
            tube.rotateX(PI*90/180)
            filterPlanks[0].push(tube);
            break;
        }
    }
}

export function changeBiologicalWidth(scene: THREE.Scene, widthDiff: number, filterPlanks: THREE.Mesh[][]){
    if(filterPlanks[0].length === 0){
        return
    }
    if(currFilterLocation !== FilterLocation.midBack && currFilterLocation !== FilterLocation.midMid){
        for(let i = 0; i < filterPlanks[0].length; i++){
            if(currFilterLocation === FilterLocation.leftBack || currFilterLocation === FilterLocation.leftMid){
                filterPlanks[0][i].position.x -= widthDiff/scale/2;
            }else{
                filterPlanks[0][i].position.x += widthDiff/scale/2;
            }
        }
    }
    
}

export function changeBiologicalHeight(scene: THREE.Scene, currHeight: number, newHeight: number, filterPlanks: THREE.Mesh[][]){
    if(filterPlanks[0].length === 0){
        return
    }
    for(let i = 0; i < filterPlanks[0].length-2; i++){
        filterPlanks[0][i].geometry.scale(1,newHeight/currHeight,1)
    }
    filterPlanks[0][filterPlanks[0].length-2].geometry.scale(1,(newHeight/scale-2*innerGlassHeightDiff/scale)/(currHeight/scale-2*innerGlassHeightDiff/scale),1);
    filterPlanks[0][filterPlanks[0].length-1].geometry.scale(1,(newHeight/scale-2*innerGlassHeightDiff/scale)/(currHeight/scale-2*innerGlassHeightDiff/scale),1);
}

export function changeBiologicalDepth(scene: THREE.Scene, depthDiff: number, filterPlanks: THREE.Mesh[][]){
    if(filterPlanks[0].length === 0){
        return
    }
    if(currFilterLocation !== FilterLocation.leftMid && currFilterLocation !== FilterLocation.midMid && currFilterLocation !== FilterLocation.rightMid){
        for(let i = 0; i < filterPlanks[0].length; i++){
            filterPlanks[0][i].position.z -= depthDiff/scale/2;
        }
    }
}