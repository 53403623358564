import CaretRight from "../../../images/caret-right.svg";
import React from "react";
import { GradientSubTitle, Text } from "../../atoms/StyledComponents";
import StepStatusIcon from "../../atoms/StepStatusIcon";
import { StepStatus, Steps } from "../../../@types/configurator";

type Props = {
  stepkey: string;
  status: StepStatus;
  title: string;
  stepnumber: number;
  symbol: JSX.Element;
  disabled: boolean;
  stepId: Steps;
};

function ConfiguratorStepButton({
  stepkey,
  status,
  title,
  stepnumber,
  symbol,
  disabled,
  stepId
}: Props) {
  return (
    <div
      className={
        "configurator-step-button-container " +
        (disabled ? "configurator-step-button-container-disabled" : "")
      }
      id={stepkey}
    >
      <div>
        <img
          src={CaretRight}
          className="configurator-step-button-arrow"
          alt="Caret right"
        />
      </div>
      <div className="configurator-step-button-content">
        <div className="configurator-step-button-title-status">
          <GradientSubTitle>{title}</GradientSubTitle>
          <StepStatusIcon stepId={stepId} />
        </div>
        <Text
          className="configurator-step-button-steptext"
          color="var(--neutral-300)"
        >
          Stap {stepnumber}
        </Text>
      </div>
      <div className="configurator-step-button-symbol">{symbol}</div>
    </div>
  );
}

export default ConfiguratorStepButton;
