import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  makeLights,
  animate,
  changeHeight,
  changeDepth,
  changeWidth,
  openCloseDoors,
} from "./aquariumComponents/aquarium";
import { changeAquariumType, drawTankGlass } from "./aquariumComponents/tank";
import { Aquarium, AquariumDimensions, AquariumType } from "../@types/aquarium";
import aquariumSlice, {
  resetAquarium as resetAquariumRedux,
  setAquarium,
} from "../redux/aquariumSlice";
import { useDispatch, useSelector } from "react-redux";
import { ArrowCounterClockwise, Door } from "@phosphor-icons/react";
import { Text } from "./atoms/StyledComponents";
import { RootState, checkNewAquariumDrawing } from "../redux/store";
import {
  defaultAquarium,
  defaultDrawingAquarium,
} from "../utils/defaultAquarium";
import { saveAsImage } from "../utils/configurator";
import { setAquariumSnapshot, setOpenStep } from "../redux/configuratorSlice";
import { AllTextObject } from "../@types/language";
var strDownloadMime = "image/octet-stream";
let renderedLoop = false;

export type AquariumChangeRef = {
  changeWidth: (aquariumDimensions: AquariumDimensions) => void;
  changeHeight: (aquariumDimensions: AquariumDimensions) => void;
  changeDepth: (aquariumDimensions: AquariumDimensions) => void;
  changeAquariumType: (type: AquariumType) => void;
};

function AquariumDrawing(
  {
    allText,
    showButtons,
    drawingWidth,
    lowHeight,
  }: {
    allText: AllTextObject;
    showButtons: boolean;
    drawingWidth?: number;
    lowHeight?: boolean;
  },
  ref: Ref<AquariumChangeRef>
) {
  const showOverviewScreen = useSelector(
    (state: RootState) => state.configurator.showOverviewScreen
  );
  const glaswidth = 0.05;
  const mobileVersion = window.innerWidth < 1024;
  const drawingWidthPercentage = drawingWidth ? drawingWidth : 0.6;
  let headerheight: number = 48;
  const [aquariumState, setAquariumState] = useState(defaultAquarium);
  const resetAquarium = () => {
    dispatch(setAquarium(defaultAquarium));
    setAquariumState(defaultAquarium);
    console.log("reset");
    checkNewAquariumDrawing(false);
    dispatch(setOpenStep(null));
  };
  const dispatch = useDispatch();
  let currWidth = aquariumState.aquariumDimensions.width;
  let currHeight = aquariumState.aquariumDimensions.height;
  let currDepth = aquariumState.aquariumDimensions.depth;

  function drawAquarium() {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#cccccc");
    const camera = new THREE.PerspectiveCamera(
      75,
      (mobileVersion
        ? window.innerWidth
        : window.innerWidth * drawingWidthPercentage) /
        (lowHeight
          ? window.innerHeight * 0.3
          : mobileVersion
          ? window.innerHeight * 0.3
          : window.innerHeight - headerheight),
      0.1,
      1000
    );

    const drawing = document.getElementById("drawing") as HTMLCanvasElement;
    const renderer = new THREE.WebGLRenderer({
      canvas: drawing,
      antialias: true,
      preserveDrawingBuffer: true,
    });
    renderer.setSize(
      mobileVersion
        ? window.innerWidth
        : window.innerWidth * drawingWidthPercentage,
      lowHeight
        ? window.innerHeight * 0.3
        : mobileVersion
        ? window.innerHeight * 0.3
        : drawingWidth
        ? drawingWidth * window.innerWidth
        : window.innerHeight - headerheight
    );
    const controls = new OrbitControls(camera, renderer.domElement);
    // const loadingManager = new THREE.LoadingManager();
    // loadingManager.onStart = function (url, item, total) {
    //   console.log(console.log("loading: ", url));
    // };
    // loadingManager.onProgress = function (url, item, total) {
    //   console.log(console.log("loading: ", url));
    // };
    // loadingManager.onLoad = function () {
    //   console.log(console.log("loading done"));
    // };

    makeLights(scene);
    drawTankGlass(scene, currWidth, currHeight, currDepth);

    renderer.setAnimationLoop(() => {
      animate(renderer, scene, camera, controls);
    });
    if (renderedLoop === false && showOverviewScreen) {
      checkNewAquariumDrawing(true);
      openCloseDoors(true);
      camera.position.z = 10;
      camera.position.y = 2;
      animate(renderer, scene, camera, controls);
      dispatch(setAquariumSnapshot(saveAsImage(renderer, strDownloadMime)));
      renderedLoop = true;
    } else if (renderedLoop === true && !showOverviewScreen) {
      checkNewAquariumDrawing(true);
      renderedLoop = false;
    }
    camera.position.z = 5;
    camera.position.y = 2;
    animate(renderer, scene, camera, controls);
  }

  useImperativeHandle(ref, () => ({
    changeHeight,
    changeDepth,
    changeWidth,
    changeAquariumType,
  }));

  useEffect(() => {
    drawAquarium();
  });

  return (
    <div id="drawing-container">
      <canvas id="drawing"></canvas>
      {showButtons && (
        <>
          <div
            id="reset-aquarium-button"
            onClick={() => {
              resetAquarium();
            }}
          >
            <ArrowCounterClockwise
              size={20}
              color="var(--neutral-100)"
              weight="bold"
            />
            <Text>{allText.tid_con_11}</Text>
          </div>
          <div
            id="toggle-doors-button"
            onClick={() => {
              openCloseDoors(false);
            }}
          >
            <Door size={20} color="var(--neutral-100)" weight="bold" />
            <Text>{allText.tid_con_12}</Text>
          </div>
        </>
      )}
    </div>
  );
}

export default forwardRef(AquariumDrawing);
