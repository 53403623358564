import { AG_GRID_LOCALE_NL } from "@ag-grid-community/locale";
import { CaretLeft } from "@phosphor-icons/react";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { OfferteData } from "../../@types/user";
import { getIsPaidIcon } from "../../utils/dashboard";
import ReminderButton from "../atoms/ReminderButton";
import GridDetails from "./GridDetails";

type Props = {offerteData: OfferteData[]};

function OffertesMenu({offerteData}: Props) {
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      resizable: false,
      suppressHeaderFilterButton: false,
      suppressMovable: true,
    };
  }, []);
  const [showOfferteDetails, setShowOfferteDetails] =
    useState<OfferteData | null>(null);

  const expandCollapseButton = () => {
    return <CaretLeft size={18} weight="bold" className="grid-arrow" />;
  };
  const renderCountryFlag = (countryCode: any) => {
    return (
      <ReactCountryFlag
        countryCode={countryCode}
        svg
        style={{
          width: "1.5em",
          height: "1.5em",
        }}
      />
    );
  };

  const [rowData, setRowData] = useState<OfferteData[]>(offerteData);

  const colDefs: ColDef<any>[] = [
    {
      field: "offerteNumber",
      headerName: "#",
      flex: 2,
    },
    {
      field: "countryFlag",
      headerName: "",
      flex: 1,
      cellRenderer: (p: any) => renderCountryFlag(p.data.country),
      suppressHeaderFilterButton: true,
    },
    { field: "city", headerName: "Woonplaats", flex: 3 },
    { field: "firstname", headerName: "Voornaam", flex: 3 },
    { field: "lastname", headerName: "Achternaam", flex: 4 },
    { field: "email", headerName: "Email", flex: 5 },
    {
      field: "price",
      headerName: "Prijs",
      valueFormatter: (p) => "€" + p.value.toLocaleString(),
      flex: 3,
    },
    {
      field: "isPaid",
      headerName: "Besteld?",
      flex: 2,
      cellRenderer: (p: any) => getIsPaidIcon(p.data.isPaid),
    },
    { field: "date", headerName: "Datum", flex: 3 },
    {
      field: "reminderSend",
      headerName: "Reminder",
      cellRenderer: (p: any) => (
        <ReminderButton reminderDate={p.data.reminderSend} />
      ),
      flex: 3,
    },
    {
      field: "expandButton",
      headerName: "",
      cellRenderer: expandCollapseButton,
      suppressHeaderFilterButton: true,
    },
  ];
  return (
    <div className="ag-theme-quartz master-grid-container master-grid">
      {showOfferteDetails && (
        <GridDetails
          offerte={showOfferteDetails}
          setShowOfferteDetails={setShowOfferteDetails}
        />
      )}
      <AgGridReact<OfferteData>
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 20, 50]}
        rowSelection="single"
        onRowClicked={(props) =>
          props.data
            ? setShowOfferteDetails(props.data)
            : setShowOfferteDetails(null)
        }
        suppressMenuHide={true}
        localeText={AG_GRID_LOCALE_NL}
        domLayout="autoHeight"
        suppressHorizontalScroll={false}
      />
    </div>
  );
}

export default OffertesMenu;
