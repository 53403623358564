import React from "react";
import CaretRight from "../../images/caret-double-right.svg";
import CaretRightOcean from "../../images/caret-double-right-ocean.svg";
import { GradientSubTitle, SmallText } from "../atoms/StyledComponents";

type Props = {
  action: any;
  text: string;
  subtext?: string;
  color: string;
  disabled: boolean;
};

function OverviewActionButton({
  action,
  text,
  subtext,
  color,
  disabled,
}: Props) {
  return (
    <a
      className={
        "configurator-overview-button-container overview-actionbutton-container " +
        (color === "var(--ocean-gradient-500)"
          ? "configurator-overview-button-container-ocean "
          : "") +
        (disabled ? "configurator-overview-button-container-disabled" : "")
      }
      id="go-to-overview-button"
      onClick={() => action}
      style={{ backgroundImage: color }}
    >
      <div>
        <img
          src={
            color === "var(--ocean-gradient-500)" ? CaretRightOcean : CaretRight
          }
          className="configurator-overview-button-arrow"
          alt="Caret right"
        />
      </div>
      <div className="configurator-overview-button-content">
        <div className="configurator-overview-button-title-status">
          <GradientSubTitle
            color="var(--neutral-gradient-100)"
            size="var(--font-sm)"
          >
            {text}
          </GradientSubTitle>
        </div>
        <SmallText
          className="configurator-overview-button-text"
          color="var(--neutral-100)"
        >
          {subtext}
        </SmallText>
      </div>
      {/* <div className="configurator-overview-button-symbol">
        <ClipboardText size={64} color="var(--green-600)" />
      </div> */}
    </a>
  );
}

export default OverviewActionButton;
