import React, { useEffect, useState } from "react";
import ConfiguratorStep from "../../templates/ConfiguratorStep";
import { ImageOption, Steps } from "../../../@types/configurator";
import { Cube } from "@phosphor-icons/react";
import StepItem from "../../templates/StepItem";
import BiotoopButton from "../../molecules/BiotoopButton";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  AquariumType,
  CoverPanelsColor,
  FullGlassCoverPanels,
  FurnitureType,
  TurtleIsland,
  WaterType,
} from "../../../@types/aquarium";
import {
  setFullGlassCoverPanels,
  setWaterType as setWaterTypeRedux,
} from "../../../redux/aquariumSlice";
import { setAquariumType as setAquariumTypeRedux } from "../../../redux/aquariumSlice";
import { aquariumTypeOptions } from "../../../data/aquariumTypes";
import { DecorTexture } from "../../../@types/textures";
import ConfiguratorImageOption from "../../molecules/ConfiguratorImageOption";
import { setOpenTexturesModal } from "../../../redux/configuratorSlice";
import { decorTexturePlaceholder, decorTextures } from "../../../data/textures";
import ImageSelectionButton from "../../molecules/ImageSelectionButton";
import IconToggle from "../../molecules/IconToggle";
import { setCoverPanels as setCoverPanelsRedux } from "../../../redux/aquariumSlice";
import {
  fullGlassCoverOptions,
  turtleIslandOptions,
} from "../../../data/aquarium";
import { setTurtleIslandLocation as setTurtleIslandLocationRedux } from "../../../redux/aquariumSlice";
import { setLightCover as setLightCoverRedux } from "../../../redux/aquariumSlice";
import { setCoverPanelsColor as setCoverPanelsColorRedux } from "../../../redux/aquariumSlice";
import { setGrindList as setGrindListRedux } from "../../../redux/aquariumSlice";
import CoverPanelsColorMenu from "../../molecules/CoverPanelsColorMenu";
import { AllTextObject } from "../../../@types/language";

type Props = {
  allText: AllTextObject;
  setOpenStep: React.Dispatch<React.SetStateAction<Steps | null>>;
};

function AquariumTypeStep({ allText, setOpenStep }: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const stepItemInfoOverview = useSelector(
    (state: RootState) => state.configurator.stepItemInfoOverview
  );
  const dispatch = useDispatch();
  const [waterType, setWaterType] = useState<WaterType | null>(
    aquarium.waterType
  );
  const [aquariumType, setAquariumType] = useState<AquariumType | null>(
    aquarium.aquariumType
  );
  const [aquariumTexture, setAquariumTexture] = useState<DecorTexture | null>(
    aquarium.aquariumTexture
  );
  const getTextureObj = (textureID: DecorTexture): ImageOption => {
    const decorTexture = decorTextures(allText).find(
      (item) => item.id === textureID
    );
    return decorTexture ? decorTexture : decorTexturePlaceholder(allText);
  };
  const [selectedTexture, setSelectedTexture] = useState<ImageOption>(
    getTextureObj(
      aquarium.aquariumTexture
        ? aquarium.aquariumTexture
        : DecorTexture.placeholder
    )
  );
  const [coverPanels, setCoverPanels] = useState<boolean>(aquarium.coverPanels);
  const [fullGlassPanelOption, setFullGlassPanelOption] =
    useState<FullGlassCoverPanels | null>(aquarium.fullGlassCoverPanels);
  const [turtleIslandLocation, setTurtleIslandLocation] =
    useState<TurtleIsland | null>(aquarium.turtleIsland);
  const [lightCover, setLightCover] = useState<boolean>(aquarium.lightCover);
  const [coverPanelsColor, setCoverPanelsColor] = useState<CoverPanelsColor>(
    aquarium.coverPanelsColor
  );
  const [grindList, setGrindList] = useState<boolean>(aquarium.grindlist);

  // Reset aquarium
  useEffect(() => {
    setWaterType(aquarium.waterType);
    setAquariumType(aquarium.aquariumType);
    setAquariumTexture(aquarium.aquariumTexture);
    setCoverPanels(aquarium.coverPanels);
    setFullGlassPanelOption(aquarium.fullGlassCoverPanels);
    setTurtleIslandLocation(aquarium.turtleIsland);
    setLightCover(aquarium.lightCover);
  }, [aquarium]);

  useEffect(() => {
    dispatch(setWaterTypeRedux(waterType));
  }, [waterType]);

  useEffect(() => {
    dispatch(setAquariumTypeRedux(aquariumType));
  }, [aquariumType]);

  useEffect(() => {
    setSelectedTexture(
      aquariumTexture
        ? getTextureObj(aquariumTexture)
        : decorTexturePlaceholder(allText)
    );
  }, [aquariumTexture]);

  useEffect(() => {
    dispatch(setCoverPanelsRedux(coverPanels));
  }, [coverPanels]);

  useEffect(() => {
    dispatch(setFullGlassCoverPanels(fullGlassPanelOption));
  }, [fullGlassPanelOption]);

  useEffect(() => {
    dispatch(setTurtleIslandLocationRedux(turtleIslandLocation));
  }, [turtleIslandLocation]);

  useEffect(() => {
    if (lightCover === false && aquariumType === AquariumType.turtle) {
      dispatch(setCoverPanelsRedux(false));
    }
    dispatch(setLightCoverRedux(lightCover));
  }, [lightCover]);

  useEffect(() => {
    dispatch(setCoverPanelsColorRedux(coverPanelsColor));
  }, [coverPanelsColor]);
  useEffect(() => {
    dispatch(setGrindListRedux(grindList));
  }, [grindList]);

  const openTextureMenu = () => {
    dispatch(setOpenTexturesModal(true));
  };

  return (
    <ConfiguratorStep
      allText={allText}
      title={allText.tid_gen_04}
      step={1}
      stepId={Steps.aquariumType}
      symbol={
        <Cube
          className="configurator-step-header-symbol"
          size={80}
          color="var(--primary-300)"
        />
      }
      setOpenStep={setOpenStep}
    >
      <>
        <StepItem
          title={allText.tid_st2_01}
          helptext={allText.tid_st2_02}
          errortext={allText.tid_st2_03}
          hasIssue={false}
        >
          <BiotoopButton
            allText={allText}
            waterType={waterType}
            setWaterType={setWaterType}
          />
        </StepItem>
        {waterType && (
          <StepItem
            title={allText.tid_st2_04}
            helptext={allText.tid_st2_05}
            errortext={allText.tid_st2_06}
            hasIssue={stepItemInfoOverview.aquariumType.aquariumType.hasIssue}
          >
            <div className="optionlist-container">
              {aquariumTypeOptions(allText)
                .filter(
                  (option) =>
                    (aquarium.waterType === WaterType.salty &&
                      option.id !== AquariumType.turtle) ||
                    aquarium.waterType === WaterType.fresh
                )
                .map((option) => (
                  <ConfiguratorImageOption
                    name="type-aquarium-option"
                    option={option}
                    value={aquariumType}
                    setValue={setAquariumType}
                    key={"type-aquarium-option-" + option.id}
                  />
                ))}
            </div>
          </StepItem>
        )}

        {aquariumType === AquariumType.fullGlass && (
          <StepItem
            title={allText.tid_st2_11}
            helptext={allText.tid_st2_12}
            errortext={allText.tid_st2_13}
            hasIssue={false}
          >
            <div className="optionlist-container">
              {fullGlassCoverOptions(allText).map((option) => (
                <ConfiguratorImageOption
                  name="fullglass-panel-option"
                  option={option}
                  value={fullGlassPanelOption}
                  setValue={setFullGlassPanelOption}
                  key={"fullglass-panel-option-" + option.id}
                />
              ))}
            </div>
          </StepItem>
        )}
        {aquariumType === AquariumType.turtle && (
          <>
            <StepItem
              title={allText.tid_st2_16}
              helptext={allText.tid_st2_17}
              errortext={allText.tid_st2_18}
              hasIssue={stepItemInfoOverview.aquariumType.turtleIsland.hasIssue}
            >
              <div className="optionlist-container">
                {turtleIslandOptions(allText)
                  .filter(
                    (option) =>
                      option.id === TurtleIsland.noIsland ||
                      (aquarium.aquariumDimensions.width >= 80 &&
                        (option.id === TurtleIsland.left ||
                          option.id === TurtleIsland.right)) ||
                      (aquarium.aquariumDimensions.width >= 120 &&
                        option.id === TurtleIsland.twosided)
                  )
                  .map((option) => (
                    <ConfiguratorImageOption
                      name="turtle-island-option"
                      option={option}
                      value={turtleIslandLocation}
                      setValue={setTurtleIslandLocation}
                      key={"turtle-island-option-" + option.id}
                    />
                  ))}
              </div>
            </StepItem>
            <StepItem
              title={allText.tid_st2_19}
              helptext={allText.tid_st2_20}
              errortext={allText.tid_st2_21}
              hasIssue={false}
              extraTitle={allText.tid_st2_22}
            >
              <IconToggle
                checked={lightCover}
                setValue={setLightCover}
                text={allText.tid_st2_23}
              />
            </StepItem>
          </>
        )}
        {aquariumType !== null && (
          <StepItem
            title={allText.tid_st2_24}
            helptext={allText.tid_st2_25}
            errortext={allText.tid_st2_26}
            hasIssue={false}
          >
            <IconToggle
              checked={grindList}
              setValue={setGrindList}
              text={allText.tid_st2_27}
              disabled={
                aquarium.furniture.furnitureType === FurnitureType.aluminium ||
                aquarium.furniture.furnitureType === FurnitureType.decor ||
                aquarium.furniture.furnitureType === FurnitureType.marine
              }
            />
          </StepItem>
        )}
        {(aquariumType === AquariumType.lightCover ||
          (aquariumType === AquariumType.turtle && lightCover) ||
          grindList) && (
          <StepItem
            title={allText.tid_con_03}
            helptext={allText.tid_con_04}
            errortext={allText.tid_con_05}
            hasIssue={stepItemInfoOverview.aquariumType.aquariumTexture.hasIssue}
          >
            <div className="optionlist-container">
              <div
                className="optionlist-button-container"
                onClick={() => openTextureMenu()}
              >
                <ImageSelectionButton
                  name="aquarium-texture-option"
                  option={selectedTexture}
                  value={aquariumTexture}
                  setValue={setAquariumTexture}
                  key={"aquarium-texture-option-" + selectedTexture.id}
                />
              </div>
            </div>
          </StepItem>
        )}
        {(aquariumType === AquariumType.lightCover ||
          (aquariumType === AquariumType.turtle && lightCover)) && (
          <StepItem
            title={allText.tid_st2_28}
            helptext={allText.tid_st2_29}
            errortext={allText.tid_st2_30}
            hasIssue={false}
          >
            <>
              <IconToggle
                checked={coverPanels}
                setValue={setCoverPanels}
                text={allText.tid_st2_31}
              />
              {coverPanels && (
                <>
                  <h4 className="window-paint-menu-title">
                    {allText.tid_st2_32}
                  </h4>
                  <CoverPanelsColorMenu
                    allText={allText}
                    checked={coverPanelsColor}
                    setValue={setCoverPanelsColor}
                  />
                </>
              )}
            </>
          </StepItem>
        )}
      </>
    </ConfiguratorStep>
  );
}

export default AquariumTypeStep;
