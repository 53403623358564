import {
  PayloadAction,
  createAction,
  createSlice,
  current,
} from "@reduxjs/toolkit";
import {
  Aquarium,
  AquariumDimensions,
  AquariumType,
  CoverPanelsColor,
  DoorType,
  ElectraRuimte,
  ExtraLEDFreshWaterOption,
  ExtraLEDSaltWaterOption,
  FilterLocation,
  FilterOptions,
  FullGlassCoverPanels,
  FurnitureDimensions,
  FurnitureType,
  PvcPipeworkPackage,
  SidesInfo,
  SiliconColor,
  TurtleIsland,
  WaterType,
  WindowPaintOption,
} from "../@types/aquarium";
import { defaultAquarium } from "../utils/defaultAquarium";
import { DecorTexture } from "../@types/textures";
import { ProductData } from "../@types/product";

const initialState: Aquarium = defaultAquarium;
export const resetAquarium = createAction("REVERT_ALL");

const AquariumSlice = createSlice({
  name: "aquarium",
  initialState,
  reducers: {
    setAquarium: (state, action: PayloadAction<Aquarium>) => {
      return action.payload;
    },
    setAquariumDimensions: (
      state,
      action: PayloadAction<AquariumDimensions>
    ) => {
      state.aquariumDimensions = action.payload;
    },
    setSharpenGlass: (state, action: PayloadAction<boolean>) => {
      state.sharpenGlass = action.payload;
    },
    setWaterType: (state, action: PayloadAction<WaterType | null>) => {
      state.waterType = action.payload;
    },
    setAquariumType: (state, action: PayloadAction<AquariumType | null>) => {
      state.aquariumType = action.payload;
    },
    setAquariumTexture: (state, action: PayloadAction<DecorTexture | null>) => {
      state.aquariumTexture = action.payload;
    },
    setCoverPanels: (state, action: PayloadAction<boolean>) => {
      state.coverPanels = action.payload;
    },
    setFullGlassCoverPanels: (
      state,
      action: PayloadAction<FullGlassCoverPanels | null>
    ) => {
      state.fullGlassCoverPanels = action.payload;
    },
    setTurtleIslandLocation: (
      state,
      action: PayloadAction<TurtleIsland | null>
    ) => {
      state.turtleIsland = action.payload;
    },
    setLightCover: (state, action: PayloadAction<boolean>) => {
      state.lightCover = action.payload;
    },
    setGrindList: (state, action: PayloadAction<boolean>) => {
      state.grindlist = action.payload;
    },
    setWindowPaintColor: (state, action: PayloadAction<WindowPaintOption>) => {
      state.windowPaintColor = action.payload;
    },
    setAquariumSides: (state, action: PayloadAction<SidesInfo>) => {
      state.aquariumSides = action.payload;
    },
    setFilterType: (state, action: PayloadAction<FilterOptions | null>) => {
      state.filter.filterType = action.payload;
    },
    setExternalFilterDryroom: (state, action: PayloadAction<boolean>) => {
      state.filter.externalDryroom = action.payload;
    },
    setFilterLocation: (
      state,
      action: PayloadAction<FilterLocation | null>
    ) => {
      state.filter.filterLocation = action.payload;
    },
    setBioCoverPanels: (state, action: PayloadAction<boolean>) => {
      state.filter.bioCoverPanels = action.payload;
    },
    setOsmoseCompartiment: (state, action: PayloadAction<boolean>) => {
      state.filter.osmoseCompartiment = action.payload;
    },
    setBoreholeLeft: (state, action: PayloadAction<boolean>) => {
      state.filter.boreholeLeft = action.payload;
    },
    setBoreholeRight: (state, action: PayloadAction<boolean>) => {
      state.filter.boreholeRight = action.payload;
    },
    setFurnitureType: (state, action: PayloadAction<FurnitureType | null>) => {
      state.furniture.furnitureType = action.payload;
    },
    setFurnitureDimensions: (
      state,
      action: PayloadAction<FurnitureDimensions>
    ) => {
      state.furniture.dimensions = action.payload;
    },
    setBetonPlex: (state, action: PayloadAction<boolean>) => {
      state.furniture.betonPlex = action.payload;
    },
    setSeeThrough: (state, action: PayloadAction<boolean>) => {
      state.furniture.seeThrough = action.payload;
    },
    setBackWall34: (state, action: PayloadAction<boolean>) => {
      state.furniture.backWall34 = action.payload;
    },
    setElectraRuimte: (state, action: PayloadAction<ElectraRuimte | null>) => {
      state.furniture.electraRuimte = action.payload;
    },
    setRemovableSideLeft: (state, action: PayloadAction<boolean>) => {
      state.furniture.removableSideLeft = action.payload;
    },
    setRemovableSideRight: (state, action: PayloadAction<boolean>) => {
      state.furniture.removableSideRight = action.payload;
    },
    setDoorType: (state, action: PayloadAction<DoorType | null>) => {
      state.furniture.doorType = action.payload;
    },
    setWaterproofPlate: (state, action: PayloadAction<boolean>) => {
      state.extra.waterproofPlate = action.payload;
    },
    setExtraAquaswitch: (state, action: PayloadAction<boolean>) => {
      state.furniture.aquaswitch = action.payload;
    },
    setExtraSiliconColor: (state, action: PayloadAction<SiliconColor>) => {
      state.extra.siliconeColor = action.payload;
    },
    setTechnicsBoosterPump: (state, action: PayloadAction<ProductData[]>) => {
      if (
        current(state.technics.boosterPump).toString() !==
        action.payload.toString()
      ) {
        state.technics.boosterPump = action.payload;
      }
    },
    setTechnicsLed: (state, action: PayloadAction<ProductData[]>) => {
      if (
        current(state.technics.led).toString() !== action.payload.toString()
      ) {
        state.technics.led = action.payload;
      }
    },
    setTechnicsHeating: (state, action: PayloadAction<ProductData[]>) => {
      if (
        current(state.technics.heating).toString() !== action.payload.toString()
      ) {
        state.technics.heating = action.payload;
      }
    },
    setTechnicsFilter: (state, action: PayloadAction<ProductData[]>) => {
      if (
        current(state.technics.filter).toString() !== action.payload.toString()
      ) {
        state.technics.filter = action.payload;
      }
    },
    setTechnicsCirculationPump: (
      state,
      action: PayloadAction<ProductData[]>
    ) => {
      if (
        current(state.technics.circulationPump).toString() !==
        action.payload.toString()
      ) {
        state.technics.circulationPump = action.payload;
      }
    },
    setTechnicsProteinSkimmer: (
      state,
      action: PayloadAction<ProductData[]>
    ) => {
      if (
        current(state.technics.proteinSkimmer).toString() !==
        action.payload.toString()
      ) {
        state.technics.proteinSkimmer = action.payload;
      }
    },
    setTechnicsAutoRefillSystem: (
      state,
      action: PayloadAction<ProductData[]>
    ) => {
      if (
        current(state.technics.automaticRefillSystem).toString() !==
        action.payload.toString()
      ) {
        state.technics.automaticRefillSystem = action.payload;
      }
    },
    setTechnicsCo2System: (state, action: PayloadAction<ProductData[]>) => {
      if (
        current(state.technics.co2System).toString() !==
        action.payload.toString()
      ) {
        state.technics.co2System = action.payload;
      }
    },
    setTechnicsInterior: (state, action: PayloadAction<ProductData[]>) => {
      if (
        current(state.technics.interior).toString() !==
        action.payload.toString()
      ) {
        state.technics.interior = action.payload;
      }
    },
    setPvcPipeworkPackage: (
      state,
      action: PayloadAction<PvcPipeworkPackage | null>
    ) => {
      state.extra.pvcPipeworkPackage = action.payload;
    },
    setCoverPanelsColor: (state, action: PayloadAction<CoverPanelsColor>) => {
      state.coverPanelsColor = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(resetAquarium, () => initialState),
});

export const {
  setAquarium,
  setAquariumDimensions,
  setSharpenGlass,
  setWaterType,
  setAquariumType,
  setAquariumTexture,
  setCoverPanels,
  setFullGlassCoverPanels,
  setTurtleIslandLocation,
  setLightCover,
  setGrindList,
  setWindowPaintColor,
  setAquariumSides,
  setFilterType,
  setExternalFilterDryroom,
  setFilterLocation,
  setBioCoverPanels,
  setOsmoseCompartiment,
  setBoreholeLeft,
  setBoreholeRight,
  setFurnitureType,
  setFurnitureDimensions,
  setBetonPlex,
  setSeeThrough,
  setBackWall34,
  setElectraRuimte,
  setRemovableSideLeft,
  setRemovableSideRight,
  setDoorType,
  setWaterproofPlate,
  setExtraAquaswitch,
  setExtraSiliconColor,
  setTechnicsBoosterPump,
  setTechnicsLed,
  setTechnicsHeating,
  setTechnicsFilter,
  setTechnicsCirculationPump,
  setTechnicsProteinSkimmer,
  setTechnicsAutoRefillSystem,
  setTechnicsCo2System,
  setTechnicsInterior,
  setPvcPipeworkPackage,
  setCoverPanelsColor,
} = AquariumSlice.actions;

export default AquariumSlice.reducer;
