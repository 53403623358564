import dutchFlag from "../images/flags/dutch.png";
import frenchFlag from "../images/flags/french.png";
import englishFlag from "../images/flags/english.png";
import germanFlag from "../images/flags/german.png";
import { LanguageOption } from "../@types/language";

export const languageOptions: Array<LanguageOption> = [
  {
    name: "dutch",
    image: dutchFlag,
  },
  {
    name: "french",
    image: frenchFlag,
  },
  {
    name: "english",
    image: englishFlag,
  },
  {
    name: "german",
    image: germanFlag,
  },
];
