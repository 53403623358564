import * as THREE from "three";
import { DoorType, ElectraRuimte, FurnitureType } from "../../@types/aquarium";
import {
  changeMarineLowerClosetHeight,
  changeMarineDepth,
  changeMarineHeight,
  changeMarineWidth,
  makeMarineConformCloset,
  changeMarineUpperClosetHeight,
  makeFilterMarineCompartiment,
  getMarineLowerClosetHeight,
  makeMarineAquariumSeethrough,
  marineConformChangeWaterProofPlateHeight,
  changeMarineConformTexture,
} from "./marineConform";
import {
  changeDoorDepth,
  changeDoorHeight,
  changeDoorTexture,
  changeDoorWidth,
  changeLowerClosetDoorHeight,
  changeUpperClosetDoorHeight,
  doorsChangeWaterProofPlateHeight,
  getDoorAngle,
  makeLowerDoors,
  turnDoors,
} from "./doors";
import {
  changeElectraSwitchVisibility,
  changeSkeletonBetonPlex,
  changeSkeletonDepth,
  changeSkeletonHeight,
  changeSkeletonLowerClosetHeight,
  changeSkeletonRearWall,
  changeSkeletonWidth,
  changeUpperSkeletonClosetHeight,
  getSkeletonFilterHeight,
  makeElectraRoom,
  makeSkeletonCloset,
  skeletonChangeWaterProofPlateHeight,
} from "./skeleton";
import {
  changeDecorBetonPlex,
  changeDecorDepth,
  changeDecorElectraRuimte,
  changeDecorElectraSwitchVisibility,
  changeDecorHeight,
  changeDecorLowerClosetHeight,
  changeDecorRearWall,
  changeDecorTexture,
  changeDecorUpperClosetHeight,
  changeDecorWidth,
  decorFinishChangeWaterProofPlateHeight,
  getDecorFilterHeight,
  makeDecorFinishCloset,
  makeDecorSeethrough,
} from "./decorFinish";
import {
  changeLowerClosetSkeletonDoorHeight,
  changeSkeletonDoorDepth,
  changeSkeletonDoorHeight,
  changeSkeletonDoorsTexture,
  changeSkeletonDoorWidth,
  changeUpperClosetSkeletonDoorHeight,
  getSkeletonDoorAngle,
  makeLowerSkeletonDoors,
  skeletonDoorsChangeWaterProofPlateHeight,
  turnSkeletonDoors,
} from "./skeletonDoors";
import {
  aluminiumFinishChangeWaterProofPlateHeight,
  changeAluminiumBetonPlex,
  changeAluminiumDepth,
  changeAluminiumElectraRuimte,
  changeAluminiumElectraSwitchVisibility,
  changeAluminiumHeight,
  changeAluminiumLowerClosetHeight,
  changeAluminiumRearWall,
  changeAluminiumTexture,
  changeAluminiumUpperClosetHeight,
  changeAluminiumWidth,
  getAluminiumFilterHeight,
  makeAluminiumCloset,
  makeAluminiumSeethrough,
} from "./aluminiumFinish";
import {
  aluminiumDoorsChangeWaterProofPlateHeight,
  changeAluminiumDoorDepth,
  changeAluminiumDoorHeight,
  changeAluminiumDoorsTexture,
  changeAluminiumDoorWidth,
  changeAluminiumLowerDoorHeight,
  changeAluminiumUpperDoorHeight,
  getAluminiumDoorAngle,
  makeAluminiumDoors,
  turnAluminiumDoors,
} from "./aluminiumDoors";
import { Door } from "@phosphor-icons/react";
import {
  aluminiumSlidingDoorsChangeWaterProofPlateHeight,
  changeAluminiumLowerSlidingDoorHeight,
  changeAluminiumSlidingDoorDepth,
  changeAluminiumSlidingDoorHeight,
  changeAluminiumSlidingDoorsTexture,
  changeAluminiumSlidingDoorWidth,
  changeAluminiumUpperSlidningDoorHeight,
  getSlideWidth,
  makeAluminiumSlidingDoors,
  slideDoors,
} from "./aluminiumSlidingDoor";
import { DecorTexture } from "../../@types/textures";
import { aluminiumRemovablePanelChangeWaterProofPlateHeight, changeAluminiumLowerRemovablePanelHeight, changeAluminiumRemovablePanelDepth, changeAluminiumRemovablePanelHeight, changeAluminiumRemovablePanelTexture, changeAluminiumRemovablePanelWidth, changeAluminiumUpperRemovablePanelHeight, getAluminiumRemovablePanelAngle, makeAluminiumRemovablePanel, turnAluminiumRemovablePanel } from "./aluminiumRemovablePanel";

const PI = 3.14159265359;

let closetPlanks: THREE.Mesh[][] = []; //Every list is for anothor part of the closet, for example, the first list is for the closet under, the 2nd for the doors,...
let currClosetType = FurnitureType.none;
let currDoorType = DoorType.pushDoors;
let currAngle = 0;
let waterproofPlateHeight = 0;
let currentTexture: String = ""

export function setClosetType(
  scene: THREE.Scene,
  width: number,
  height: number,
  closetHeight: number,
  depth: number,
  closetType: FurnitureType
) {
  if(currClosetType === closetType){
    return
  }
  currClosetType = closetType;
  for (let i = closetPlanks.length - 1; i >= 0; i--) {
    if(closetPlanks[i]){
      for (let j = closetPlanks[i].length-1; j >= 0; j--) {
        scene.remove(closetPlanks[i][j]);
        closetPlanks[i].splice(j, 1);
      }
      closetPlanks.splice(i, 1);
    }
    
  }
  switch (closetType) {
    case FurnitureType.aluminium: {
      if (currDoorType === DoorType.pushDoors) {
        makeAluminiumDoors(
          scene,
          width,
          height,
          closetHeight,
          depth,
          waterproofPlateHeight,
          closetPlanks, currentTexture
        );
        turnAluminiumDoors(-getAluminiumDoorAngle(), closetPlanks);
        turnAluminiumDoors(currAngle, closetPlanks);
      } else if (currDoorType === DoorType.slidingDoors) {
        makeAluminiumSlidingDoors(
          scene,
          width,
          height,
          closetHeight,
          depth,
          waterproofPlateHeight,
          closetPlanks, currentTexture
        );
        slideDoors(-getSlideWidth(), closetPlanks);
        slideDoors(currAngle, closetPlanks);
      } else if(currDoorType === DoorType.removablePanels){
        makeAluminiumRemovablePanel(
          scene,
          width,
          height,
          closetHeight,
          depth,
          waterproofPlateHeight,
          closetPlanks, currentTexture)
          turnAluminiumRemovablePanel(-getAluminiumRemovablePanelAngle(), closetPlanks);
          turnAluminiumRemovablePanel(currAngle, closetPlanks);
      }
      makeAluminiumCloset(
        scene,
        width,
        height,
        closetHeight,
        depth,
        waterproofPlateHeight,
        closetPlanks, currentTexture
      );
      break;
    }
    case FurnitureType.frameOnly: {
      makeSkeletonCloset(
        scene,
        width,
        height,
        closetHeight,
        depth,
        waterproofPlateHeight,
        closetPlanks
      );
      changeSkeletonRearWall(scene, false, closetPlanks);
      break;
    }
    case FurnitureType.decor: {
      makeLowerSkeletonDoors(
        scene,
        width,
        height,
        closetHeight,
        depth,
        waterproofPlateHeight,
        closetPlanks, currentTexture
      );
      turnSkeletonDoors(-getSkeletonDoorAngle(), closetPlanks);
      turnSkeletonDoors(currAngle, closetPlanks);
      makeDecorFinishCloset(
        scene,
        width,
        height,
        closetHeight,
        depth,
        waterproofPlateHeight,
        closetPlanks, currentTexture
      );

      break;
    }
    case FurnitureType.marine: {
      makeLowerDoors(
        scene,
        width,
        height,
        closetHeight,
        depth,
        waterproofPlateHeight,
        closetPlanks, currentTexture
      );
      turnDoors(-getDoorAngle(), closetPlanks);
      turnDoors(currAngle, closetPlanks);
      makeMarineConformCloset(
        scene,
        width,
        height,
        closetHeight,
        depth,
        waterproofPlateHeight,
        closetPlanks, currentTexture
      );

      break;
    }
  }
}

//can only change if furniture type aluminium is
export function changeDoorType(
  scene: THREE.Scene,
  width: number,
  height: number,
  lowerClosetHeight: number,
  upperClosetHeight: number,
  depth: number,
  doorType: DoorType
) {
  if(doorType === currDoorType){
    return
  }
  for (let i = 0; i < 2; i++) {
    for (let j = closetPlanks[i].length - 1; j >= 0; j--) {
      scene.remove(closetPlanks[i][j]);
      closetPlanks[i].splice(j, 1);
    }
  }

  switch (doorType) {
    case DoorType.removablePanels: {
      makeAluminiumRemovablePanel(scene, width, height, lowerClosetHeight, depth, waterproofPlateHeight, closetPlanks, currentTexture)
      if (upperClosetHeight > 0) {
        changeAluminiumUpperRemovablePanelHeight(scene, upperClosetHeight, closetPlanks);
      }
      turnAluminiumRemovablePanel(-getAluminiumRemovablePanelAngle(), closetPlanks);
      turnAluminiumRemovablePanel(currAngle, closetPlanks);
      break;
    }
    case DoorType.pushDoors: {
      makeAluminiumDoors(
        scene,
        width,
        height,
        lowerClosetHeight,
        depth,
        waterproofPlateHeight,
        closetPlanks, currentTexture
      );
      if (upperClosetHeight > 0) {
        changeAluminiumUpperDoorHeight(scene, upperClosetHeight, closetPlanks);
      }

      turnAluminiumDoors(-getAluminiumDoorAngle(), closetPlanks);
      turnAluminiumDoors(currAngle, closetPlanks);
      break;
    }
    case DoorType.slidingDoors: {
      makeAluminiumSlidingDoors(
        scene,
        width,
        height,
        lowerClosetHeight,
        depth,
        waterproofPlateHeight,
        closetPlanks, currentTexture
      );
      changeAluminiumUpperSlidningDoorHeight(
        scene,
        upperClosetHeight,
        closetPlanks
      );
      slideDoors(-getSlideWidth(), closetPlanks);
      slideDoors(currAngle, closetPlanks);
      break;
    }
  }
  currDoorType = doorType;
}

export function setClosetWidth(scene: THREE.Scene, width: number) {
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      if (currDoorType === DoorType.pushDoors)
        changeAluminiumDoorWidth(scene, width, closetPlanks);
      else if (currDoorType === DoorType.slidingDoors)
        changeAluminiumSlidingDoorWidth(scene, width, closetPlanks);
      else if(currDoorType === DoorType.removablePanels)
        changeAluminiumRemovablePanelWidth(scene, width, closetPlanks)
      changeAluminiumWidth(scene, width, closetPlanks);
      break;
    }
    case FurnitureType.frameOnly: {
      changeSkeletonWidth(scene, width, closetPlanks);
      break;
    }
    case FurnitureType.decor: {
      changeSkeletonDoorWidth(scene, width, closetPlanks);
      changeDecorWidth(scene, width, closetPlanks);

      break;
    }
    case FurnitureType.marine: {
      changeMarineWidth(scene, width, closetPlanks);
      changeDoorWidth(scene, width, closetPlanks);
      break;
    }
  }
}

export function setClosetHeight(scene: THREE.Scene, height: number) {
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      if (currDoorType === DoorType.pushDoors)
        changeAluminiumDoorHeight(scene, height, closetPlanks);
      else if (currDoorType === DoorType.slidingDoors)
        changeAluminiumSlidingDoorHeight(scene, height, closetPlanks);
      else if (currDoorType === DoorType.removablePanels)
        changeAluminiumRemovablePanelHeight(scene, height, closetPlanks);
      changeAluminiumHeight(scene, height, closetPlanks);
      break;
    }
    case FurnitureType.frameOnly: {
      changeSkeletonHeight(scene, height, closetPlanks);
      break;
    }
    case FurnitureType.decor: {
      changeSkeletonDoorHeight(height, closetPlanks);
      changeDecorHeight(scene, height, closetPlanks);

      break;
    }
    case FurnitureType.marine: {
      changeMarineHeight(height, closetPlanks);
      changeDoorHeight(height, closetPlanks);
      break;
    }
  }
}

export function setLowerClosetHeight(scene: THREE.Scene, closetHeight: number) {
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      if (currDoorType === DoorType.pushDoors)
        changeAluminiumLowerDoorHeight(scene, closetHeight, closetPlanks);
      else if (currDoorType === DoorType.slidingDoors)
        changeAluminiumLowerSlidingDoorHeight(
          scene,
          closetHeight,
          closetPlanks
        );
      else if(currDoorType === DoorType.removablePanels)
        changeAluminiumLowerRemovablePanelHeight(scene, closetHeight, closetPlanks)
      changeAluminiumLowerClosetHeight(scene, closetHeight, closetPlanks);
      break;
    }
    case FurnitureType.frameOnly: {
      changeSkeletonLowerClosetHeight(scene, closetHeight, closetPlanks);
      break;
    }
    case FurnitureType.decor: {
      changeLowerClosetSkeletonDoorHeight(closetHeight, closetPlanks);
      changeDecorLowerClosetHeight(scene, closetHeight, closetPlanks);
      break;
    }
    case FurnitureType.marine: {
      changeMarineLowerClosetHeight(closetHeight, closetPlanks);
      changeLowerClosetDoorHeight(closetHeight, closetPlanks);
      break;
    }
  }
}

export function setUpperClosetHeight(scene: THREE.Scene, closetHeight: number) {
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      if (currDoorType === DoorType.pushDoors)
        changeAluminiumUpperDoorHeight(scene, closetHeight, closetPlanks);
      else if (currDoorType === DoorType.slidingDoors)
        changeAluminiumUpperSlidningDoorHeight(
          scene,
          closetHeight,
          closetPlanks
        );
      else if(currDoorType === DoorType.removablePanels)
        changeAluminiumUpperRemovablePanelHeight(scene, closetHeight, closetPlanks)
      changeAluminiumUpperClosetHeight(scene, closetHeight, closetPlanks);
      break;
    }
    case FurnitureType.frameOnly: {
      changeUpperSkeletonClosetHeight(scene, closetHeight, closetPlanks)
      break;
    }
    case FurnitureType.decor: {
      changeUpperClosetSkeletonDoorHeight(scene, closetHeight, closetPlanks);
      changeDecorUpperClosetHeight(scene, closetHeight, closetPlanks);
      break;
    }
    case FurnitureType.marine: {
      changeUpperClosetDoorHeight(scene, closetHeight, closetPlanks);
      changeMarineUpperClosetHeight(scene, closetHeight, closetPlanks);
      break;
    }
  }
}

export function setClosetDepth(scene: THREE.Scene, depth: number) {
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      if (currDoorType === DoorType.pushDoors)
        changeAluminiumDoorDepth(scene, depth, closetPlanks);
      else if (currDoorType === DoorType.slidingDoors)
        changeAluminiumSlidingDoorDepth(scene, depth, closetPlanks);
      else if(currDoorType === DoorType.removablePanels)
        changeAluminiumRemovablePanelDepth(scene, depth, closetPlanks)
      changeAluminiumDepth(scene, depth, closetPlanks);
      break;
    }
    case FurnitureType.frameOnly: {
      changeSkeletonDepth(scene, depth, closetPlanks);
      break;
    }
    case FurnitureType.decor: {
      changeDecorDepth(scene, depth, closetPlanks);
      changeSkeletonDoorDepth(depth, closetPlanks);
      break;
    }
    case FurnitureType.marine: {
      changeMarineDepth(depth, closetPlanks);
      changeDoorDepth(depth, closetPlanks);
      break;
    }
  }
}

export function rotateClosetDoors(angle: number) {
  currAngle += angle;
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      if (currDoorType === DoorType.pushDoors)
        turnAluminiumDoors(angle, closetPlanks);
      else if (currDoorType === DoorType.slidingDoors)
        slideDoors(angle, closetPlanks);
      else if(currDoorType === DoorType.removablePanels)
        turnAluminiumRemovablePanel(angle, closetPlanks)
      break;
    }
    case FurnitureType.frameOnly: {
      break;
    }
    case FurnitureType.decor: {
      turnSkeletonDoors(angle, closetPlanks);
      break;
    }
    case FurnitureType.marine: {
      turnDoors(angle, closetPlanks);
      break;
    }
    case FurnitureType.none: {
      break;
    }
  }
}

export function makeFilterCompartiment(scene: THREE.Scene, filter: boolean) {
  if (currClosetType === FurnitureType.marine) {
    makeFilterMarineCompartiment(scene, filter, closetPlanks);
  }
}

export function getLowerFunritureHeight() {
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      return 0;
    }
    case FurnitureType.frameOnly: {
      return 0;
    }
    case FurnitureType.decor: {
      return 0;
    }
    case FurnitureType.marine: {
      return getMarineLowerClosetHeight();
    }
    case FurnitureType.none: {
      return 0;
    }
  }
}

export function setAquariumSeeThrough(scene: THREE.Scene, seeThrough: boolean) {
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      makeAluminiumSeethrough(scene, seeThrough, closetPlanks);
      break;
    }
    case FurnitureType.frameOnly: {
      return 0;
    }
    case FurnitureType.decor: {
      makeDecorSeethrough(scene, seeThrough, closetPlanks);
      break;
    }
    case FurnitureType.marine: {
      makeMarineAquariumSeethrough(scene, seeThrough, closetPlanks);
      break;
    }
  }
}

export function getFilterHeight(): number {
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      return getAluminiumFilterHeight();
    }
    case FurnitureType.frameOnly: {
      return getSkeletonFilterHeight();
    }
    case FurnitureType.decor: {
      return getDecorFilterHeight();
    }
    case FurnitureType.marine: {
      return 0;
    }
    case FurnitureType.none: {
      return -50;
    }
  }
}

export function changeBetonPlex(
  scene: THREE.Scene,
  newBetonPlexVisible: boolean
) {
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      changeAluminiumBetonPlex(scene, newBetonPlexVisible, closetPlanks);
      break;
    }
    case FurnitureType.frameOnly: {
      changeSkeletonBetonPlex(scene, newBetonPlexVisible, closetPlanks);
      break;
    }
    case FurnitureType.decor: {
      changeDecorBetonPlex(scene, newBetonPlexVisible, closetPlanks);
      break;
    }
    case FurnitureType.marine: {
      break;
    }
    case FurnitureType.none: {
      break;
    }
  }
}

export function changeRearWall(
  scene: THREE.Scene,
  newBetonPlexVisible: boolean
) {
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      changeAluminiumRearWall(scene, newBetonPlexVisible, closetPlanks);
      break;
    }
    case FurnitureType.frameOnly: {
      changeSkeletonRearWall(scene, newBetonPlexVisible, closetPlanks);
      break;
    }
    case FurnitureType.decor: {
      changeDecorRearWall(scene, newBetonPlexVisible, closetPlanks);
      break;
    }
    case FurnitureType.marine: {
      break;
    }
    case FurnitureType.none: {
      break;
    }
  }
}

export function changeElectraRuimte(
  scene: THREE.Scene,
  electraRuimte: ElectraRuimte
) {
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      changeAluminiumElectraRuimte(scene, electraRuimte, closetPlanks);
      break;
    }
    case FurnitureType.frameOnly: {
      makeElectraRoom(scene, electraRuimte, closetPlanks);
      break;
    }
    case FurnitureType.decor: {
      changeDecorElectraRuimte(scene, electraRuimte, closetPlanks);
    }
  }
}

export function changeWaterProofPlateHeight(newWaterproofPlateHeight: number) {
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      if (currDoorType === DoorType.pushDoors)
        aluminiumDoorsChangeWaterProofPlateHeight(
          newWaterproofPlateHeight,
          closetPlanks
        );
      else if (currDoorType === DoorType.slidingDoors)
        aluminiumSlidingDoorsChangeWaterProofPlateHeight(
          newWaterproofPlateHeight,
          closetPlanks
        );
      else if(currDoorType === DoorType.removablePanels)
        aluminiumRemovablePanelChangeWaterProofPlateHeight(newWaterproofPlateHeight, closetPlanks)
      aluminiumFinishChangeWaterProofPlateHeight(
        newWaterproofPlateHeight,
        closetPlanks
      );
      break;
    }
    case FurnitureType.frameOnly: {
      skeletonChangeWaterProofPlateHeight(
        newWaterproofPlateHeight,
        closetPlanks
      );
      break;
    }
    case FurnitureType.decor: {
      decorFinishChangeWaterProofPlateHeight(
        newWaterproofPlateHeight,
        closetPlanks
      );
      skeletonDoorsChangeWaterProofPlateHeight(
        newWaterproofPlateHeight,
        closetPlanks
      );
      break;
    }
    case FurnitureType.marine: {
      marineConformChangeWaterProofPlateHeight(
        newWaterproofPlateHeight,
        closetPlanks
      );
      doorsChangeWaterProofPlateHeight(newWaterproofPlateHeight, closetPlanks);
      break;
    }
  }

  waterproofPlateHeight = newWaterproofPlateHeight;
}

export function changeClosetTexture(texture: String) {
  currentTexture = texture
  switch (currClosetType) {
    case FurnitureType.aluminium: {
      changeAluminiumTexture(texture, closetPlanks);
      if (currDoorType === DoorType.pushDoors) {
        changeAluminiumDoorsTexture(texture, closetPlanks);
      } else if (currDoorType === DoorType.slidingDoors) {
        changeAluminiumSlidingDoorsTexture(texture, closetPlanks);
      } else if(currDoorType === DoorType.removablePanels){
        changeAluminiumRemovablePanelTexture(texture, closetPlanks)
      }
      break;
    }
    case FurnitureType.decor: {
      changeDecorTexture(texture, closetPlanks);
      changeSkeletonDoorsTexture(texture, closetPlanks);
      break;
    }
    case FurnitureType.marine: {
      changeMarineConformTexture(texture, closetPlanks);
      changeDoorTexture(texture, closetPlanks);
      break;
    }
  }
}

export function changeFurnitureElectraSwitchVisibility(scene: THREE.Scene, visibility: boolean){
  if(currClosetType === FurnitureType.aluminium)
    changeAluminiumElectraSwitchVisibility(scene, visibility, closetPlanks)
  else if(currClosetType === FurnitureType.decor){
    changeDecorElectraSwitchVisibility(scene, visibility, closetPlanks)
  }else{
    changeElectraSwitchVisibility(scene, visibility, closetPlanks)
  }
}