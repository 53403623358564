import React from "react";
import Header from "../components/organisms/Header";
import { AllTextObject } from "../@types/language";
import "../styles/orderMessage.css";
import landing_animation from "../images/landing-coral-gif.gif";
import {
  GradientSubTitle,
  SmallerText,
  Text,
} from "../components/atoms/StyledComponents";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Socials from "../components/molecules/Socials";
import OrderProcess from "../components/molecules/OrderProcess";
import { Aquarium, FurnitureType } from "../@types/aquarium";

type Props = { allText: AllTextObject };

function OrderSuccesScreen({ allText }: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const user = useSelector((state: RootState) => state.user);
  const isMobile = window.innerWidth < 1024;
  return (
    <div className="order-message-screen-container">
      <Header allText={allText} />
      <main className="landing-banner-container overview-container order-message-screen">
        <div className="order-message-container">
          <div className="order-message-animation-container landing-banner-animation-container">
            <figure>
              <img src={landing_animation} alt="coral animation" />
            </figure>
          </div>
          <div className="order-message-screen-content-container">
            <div className="order-message-screen-content-column">
              <GradientSubTitle color="var(--primary-gradient-200)">
                {allText.tid_var_05}
                {user.firstname ? `, ${user.firstname}!` : "!"}
              </GradientSubTitle>
              <Text>{allText.tid_var_06}</Text>
              <div>
                <SmallerText weight={700}>{allText.tid_ovw_59}</SmallerText>
                <SmallerText>
                  {aquarium.furniture.furnitureType !== FurnitureType.none
                    ? allText.tid_ovw_61
                    : allText.tid_ovw_60}
                </SmallerText>
              </div>
              <Socials />
            </div>
            {!isMobile && (
              <div className="order-message-screen-content-column">
                <OrderProcess step={1} allText={allText} />
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default OrderSuccesScreen;
