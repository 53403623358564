import React, { useEffect, useState } from "react";
import { AllTextObject } from "../@types/language";
import Header from "../components/organisms/Header";
import AdminMenu from "../components/organisms/AdminMenu";
import DashboardInsight from "../components/molecules/DashboardInsight";
import OffertesMenu from "../components/molecules/OffertesMenu";
import { OfferteData } from "../@types/user";
import { testRowData } from "../data/dummyData";

type Props = { allText: AllTextObject };

function AdminLandingScreen({ allText }: Props) {
  const [offerteData, setOfferteData] = useState<OfferteData[]>(testRowData);

  useEffect(() => {
    //TODO DAAN: get offerte data from database here (only the offertes from the last 7 days)
    //Het json array dat ontvangen moet worden ziet eruit zoals het OfferteData type
    //in de backend filteren op de datum van de offerte en enkel deze doorsturen
  }, []);

  return (
    <>
      <Header allText={allText} />
      <main>
        <AdminMenu />
        <section className="dashboard-page">
          <div className="admin-landing-insights-container">
            <DashboardInsight
              number={17}
              title="offertes verstuurd"
              subtitle="in de laatste 7 dagen"
            />
            <DashboardInsight
              number={8}
              title="bestellingen"
              subtitle="in de laatste 7 dagen"
            />
          </div>
          <OffertesMenu offerteData={offerteData} />
        </section>
      </main>
    </>
  );
}

export default AdminLandingScreen;
