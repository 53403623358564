import React from "react";
import { SmallText } from "../atoms/StyledComponents";

type Props = {
  title: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  placeholder?: string;
  column?: boolean;
  width?: string;
  onBlur?: any;
  showError?: boolean;
  errorMessage?: string;
  hidden?: boolean;
  color?: string;
  required?: boolean;
};

function TextInput({
  title,
  value,
  placeholder,
  setValue,
  column,
  width,
  onBlur,
  showError,
  errorMessage,
  hidden,
  color,
  required,
}: Props) {
  return (
    <div
      className="text-input-container"
      style={{
        flexDirection: column ? "column" : "row",
        width: width ? width : "100%",
      }}
    >
      <label
        htmlFor={title + "-input"}
        className="text-input-label"
        style={{ color: color ? color : "var(--primary-700)" }}
      >
        {title + (required ? "*" : "")}
      </label>
      <div className="text-input-container">
        <input
          type={hidden ? "password" : "text"}
          id={title + "-input"}
          value={value}
          placeholder={placeholder ? placeholder : title}
          onChange={(e) => setValue(e.target.value)}
          className="text-input"
          onBlur={onBlur}
          required={required ? required : false}
        />
        {value && showError && (
          <SmallText color="var(--red-500)">{errorMessage}</SmallText>
        )}
      </div>
    </div>
  );
}

export default TextInput;
