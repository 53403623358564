import { ArrowLeft } from "@phosphor-icons/react";
import React, { useState } from "react";
import { SubTitle, Text } from "../atoms/StyledComponents";
import { StepStatus, Steps } from "../../@types/configurator";
import StepStatusIcon from "../atoms/StepStatusIcon";
import ConfiguratorNavigator from "../organisms/configurator/ConfiguratorNavigator";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getIndividualStepInfo } from "../../utils/stepConfigurator";
import { AllTextObject } from "../../@types/language";

type Props = {
  allText: AllTextObject;
  children: JSX.Element;
  title: string;
  step: number;
  stepId: Steps;
  symbol: JSX.Element;
  setOpenStep: React.Dispatch<React.SetStateAction<Steps | null>>;
};

function ConfiguratorStep({
  allText,
  children,
  title,
  step,
  stepId,
  symbol,
  setOpenStep,
}: Props) {
  return (
    <div className="configurator-step-container">
      <div className="configurator-step-header">
        <ArrowLeft
          className="configurator-step-header-arrow"
          size={36}
          weight="bold"
          onClick={() => setOpenStep(null)}
          color="var(--primary-700)"
        />
        <div className="configurator-step-header-content">
          <div className="configurator-step-header-title-status">
            <SubTitle color="var(--primary-700)">{title}</SubTitle>
            <StepStatusIcon stepId={stepId} />
          </div>
          <Text color="var(--primary-300)">
            {allText.tid_gen_26} {step}
          </Text>
        </div>
        {symbol}
      </div>
      <div className="configurator-step-body">{children}</div>
      <ConfiguratorNavigator
        allText={allText}
        currentStep={step}
        setOpenStep={setOpenStep}
      />
    </div>
  );
}

export default ConfiguratorStep;
