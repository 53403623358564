import * as THREE from 'three';
import { makeGeometryMaterials, makeOpaqueGeometryMaterials, makePlank } from './helper';
import { makeSkeletonCloset } from './skeleton';
import { Angle } from '@phosphor-icons/react/dist/ssr';
import { changeWaterProofPlateHeight } from './closetOptions';
import { DecorTexture } from '../../@types/textures';

const scale = 25;
const PI = 3.14159265359;

const closetThickness = 0.3;// thicness of aluminium
const doorLeftRightSideDiff = 5;// how much the doors are from the sides
const doorUpperBottomDiff = 9;// how much the doors are from the bottom and the top
const outerPlankOverlap = 4;// how far the plates are over the aquarium
const widthDistribution = [139,241,321,361]//when there should be more doors, so first 2 doors, if the width is more than the first one, 4 doors...

let currWidth: number;
let currHeight: number;
let currDepth: number;
let currLowerClosetHeight: number;
let currUpperClosetHeight = 0;
let currAngle = 0;// how much the doors are currently turned
let currWaterproofPlateHeight = 0
let currTexture: THREE.Texture[] = []

export function makeAluminiumRemovablePanel(scene: THREE.Scene, width: number, height: number, closetHeight: number, depth: number, waterproofPlateHeight: number, closetPlanks: THREE.Mesh[][], texture: String){
    closetPlanks.push([])
    closetPlanks.push([])

    closetPlanks[0][0] = makePlank(scene,0, -height/scale/2-closetHeight/scale/2+outerPlankOverlap/scale/2-waterproofPlateHeight/scale, depth/scale/2+closetThickness/scale/2, width/scale-0.01, closetHeight/scale+outerPlankOverlap/scale, closetThickness/scale, 0x3d3d3d)
    
    currWidth = width;
    currHeight = height;
    currDepth = depth;
    currLowerClosetHeight = closetHeight;
    currWaterproofPlateHeight = waterproofPlateHeight
    //everything is now on an angle of 0 degrees and here we make sure the angle is right
    let tempAngle = currAngle
    currAngle = 0
    if(currUpperClosetHeight > 0)
        makeUpperAluminiumRemovablePanel(scene, width, height, currUpperClosetHeight, depth, closetPlanks)
    
    turnAluminiumRemovablePanel(tempAngle, closetPlanks);
    changeAluminiumRemovablePanelTexture(texture, closetPlanks)
}

// makes upper doors
export function makeUpperAluminiumRemovablePanel(scene: THREE.Scene, width: number, height: number, closetHeight: number, depth: number, closetPlanks: THREE.Mesh[][]){
    
    if(closetHeight === 0){
        for(let i = 0; i < closetPlanks[1].length; i++){
            scene.remove(closetPlanks[1][i])
        }
        currUpperClosetHeight = closetHeight
        return
    }
    if(closetPlanks.length < 2){
        closetPlanks.push([])
        closetPlanks.push([])
    }
    
    closetPlanks[1][0] = makePlank(scene, 0, height/scale/2+closetHeight/scale/2-outerPlankOverlap/scale/2, depth/scale/2+closetThickness/scale/2, width/scale-0.01, closetHeight/scale+outerPlankOverlap/scale, closetThickness/scale, 0x3d3d3d)
    currWidth = width;
    currHeight = height;
    currDepth = depth;
    currUpperClosetHeight = closetHeight;
    //everything is now on an angle of 0 degrees and here we make sure the angle is right
    let tempAngle = currAngle
    currAngle = 0
    turnAluminiumRemovablePanel(tempAngle, closetPlanks);
    changeAluminiumRemovablePanelTexture("", closetPlanks)
}

//if the width of the aquarium is changed, the doors should be changed as well
export function changeAluminiumRemovablePanelWidth(scene: THREE.Scene, newWidth: number, closetPlanks: THREE.Mesh[][]){
    closetPlanks[0][0].geometry.scale((newWidth/scale-0.01)/(currWidth/scale-0.01),1,1)
    if(currUpperClosetHeight > 0){
        closetPlanks[1][0].geometry.scale((newWidth/scale-0.01)/(currWidth/scale-0.01),1,1)
    }
    currWidth = newWidth
    //everything is now on an angle of 0 degrees and here we make sure the angle is right
    let tempAngle = currAngle
    currAngle = 0
    turnAluminiumRemovablePanel(tempAngle, closetPlanks);
    changeAluminiumRemovablePanelTexture("", closetPlanks)
}

// if height of aquarium is changed
export function changeAluminiumRemovablePanelHeight(scene: THREE.Scene, newHeight: number, closetPlanks: THREE.Mesh[][]){
    if(newHeight === currHeight){
        return
    }
    // lift the upper doors up
    for(let j = 0; j < closetPlanks[0].length ; j++){
        closetPlanks[0][j].position.y -= (newHeight-currHeight)/scale/2
    }

    // put the lower doors down
    for(let j = 0; j < closetPlanks[1].length ; j++){
        closetPlanks[1][j].position.y += (newHeight-currHeight)/scale/2
    }

    currHeight = newHeight;
    changeAluminiumRemovablePanelTexture("", closetPlanks)
}

// if depth of aquarium is changed
export function changeAluminiumRemovablePanelDepth(scene: THREE.Scene, newDepth: number, closetPlanks: THREE.Mesh[][]){
    if(currDepth === newDepth){
        return
    }
    // put everything further to the front
    for(let i = 0; i < 2; i++){
        for(let j = 0; j < closetPlanks[i].length; j++){
            closetPlanks[i][j].position.z += (newDepth-currDepth)/scale/2
        }
    }

    currDepth = newDepth
    changeAluminiumRemovablePanelTexture("", closetPlanks)
}

// if the height of the lower furniture is changed
export function changeAluminiumLowerRemovablePanelHeight(scene: THREE.Scene, newClosetHeight: number, closetPlanks: THREE.Mesh[][]){
    if(currLowerClosetHeight === newClosetHeight){
        return;
    }
    scene.remove(closetPlanks[0][0])
    makeAluminiumRemovablePanel(scene, currWidth, currHeight, newClosetHeight, currDepth, currWaterproofPlateHeight, closetPlanks, "")
    changeAluminiumRemovablePanelTexture("", closetPlanks)
}

// if height of upper furniture is changed
export function changeAluminiumUpperRemovablePanelHeight(scene: THREE.Scene, newClosetHeight: number, closetPlanks: THREE.Mesh[][]){
    scene.remove(closetPlanks[1][0])
    if(newClosetHeight > 0){
        makeUpperAluminiumRemovablePanel(scene, currWidth, currHeight, newClosetHeight, currDepth, closetPlanks)
    }
    changeAluminiumRemovablePanelTexture("", closetPlanks)
    currUpperClosetHeight = newClosetHeight
}

// turn the doors
// angle is the angle the door has to turn in current configuration
export function turnAluminiumRemovablePanel(angle: number, closetPlanks: THREE.Mesh[][]){
    if(closetPlanks.length === 0){
        return;
    }
    // make sure currAngle isn't bigger than 2*PI radians (=360°)
    currAngle=(currAngle+angle)%(PI);
    let degreeAngle = currAngle*180/PI
    let opacity = (1-degreeAngle/80)
    console.log(currAngle)
    if(currTexture.length > 0){
        closetPlanks[0][0].material = makeOpaqueGeometryMaterials(currTexture[0], currWidth/scale-0.01, closetThickness/scale, currLowerClosetHeight/scale+outerPlankOverlap/scale, opacity)
        if(currUpperClosetHeight > 0 && closetPlanks[1].length > 0){
            closetPlanks[1][0].material =makeOpaqueGeometryMaterials(currTexture[1], currWidth/scale-0.01, closetThickness/scale, currUpperClosetHeight/scale+outerPlankOverlap/scale, opacity)
        }
    }else{
        closetPlanks[0][0].material = new THREE.MeshPhongMaterial( {color: 0x3d3d3d, shininess: 50, emissive:0x3d3d3d, transparent:true, opacity:opacity} )
        if(currUpperClosetHeight > 0 && closetPlanks[1].length > 0){
            closetPlanks[1][0].material = new THREE.MeshPhongMaterial( {color: 0x3d3d3d, shininess: 50, emissive:0x3d3d3d, transparent:true, opacity:opacity} )
        }
    }
    
}

// returns the angle the doors are in now, tho close them or something like that
export function getAluminiumRemovablePanelAngle(){
    return currAngle;
}

export function aluminiumRemovablePanelChangeWaterProofPlateHeight(newWaterProofPlateHeight: number, closetPlanks: THREE.Mesh[][]){
    for(let i = 0; i < closetPlanks[0].length; i++){
        closetPlanks[0][i].position.y += (currWaterproofPlateHeight-newWaterProofPlateHeight)/scale
    }
    currWaterproofPlateHeight = newWaterProofPlateHeight
}

export function changeAluminiumRemovablePanelTexture(texture: String, closetPlanks: THREE.Mesh[][]){
    if(texture !== ""){
        currTexture = [new THREE.TextureLoader().load(String(texture)),new THREE.TextureLoader().load(String(texture))]
    }

    if(currTexture.length !== 0){
        let degreeAngle = currAngle*180/PI
        let opacity = 1-degreeAngle/80
        closetPlanks[0][0].material = makeOpaqueGeometryMaterials(currTexture[0], currWidth/scale-0.01, closetThickness/scale, currLowerClosetHeight/scale+outerPlankOverlap/scale, opacity)
        if(currUpperClosetHeight > 0){
                closetPlanks[1][0].material = makeOpaqueGeometryMaterials(currTexture[1], currWidth/scale-0.01, closetThickness/scale, currUpperClosetHeight/scale+outerPlankOverlap/scale, opacity)
        }
    }
}